import React, { useRef, useState, useEffect } from 'react'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import noImg from 'assets/img/layers/no-image.png'
const apiService = ApiCaller()

const DocumentsComponent = () => {
  const [logoFile, setLogoFile] = useState(null)
  const [stampFile, setStampFile] = useState(null)
  const [previewLogo, setLogoPreview] = useState(null)
  const [previewStamp, setStampPreview] = useState(null)

  const [isLoading, setIsLoading] = useState(false)

  const fileLogoRef = useRef(null) // Create a ref for the file input
  const fileStampRef = useRef(null) // Create a ref for the file input

  const uploadFile = async (file, fileFor) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.UPLOAD_IMAGE
      const data = new FormData()
      data.append('file', file)
      const response = await apiService.apiCall('post', apiUrl, data)
      if (response?.data?.value) {
        if (fileFor === 'logo') {
          setLogoFile(response?.data?.data[0])
        } else {
          setStampFile(response?.data?.data[0])
        }
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleLogoFileChange = (e) => {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      setLogoPreview(URL.createObjectURL(selectedFile)) // Create a preview URL
      uploadFile(selectedFile, 'logo')
    }
  }

  const handleStampFileChange = (e) => {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      setStampPreview(URL.createObjectURL(selectedFile)) // Create a preview URL
      uploadFile(selectedFile, 'stamp')
    }
  }

  return (
    <div className="container mx-auto mt-8 rounded-lg bg-white p-6 shadow-md">
      <h2 className="mb-6 text-2xl font-semibold">Documents</h2>
      <div className="mt-7 grid grid-cols-2 gap-3">
        <div className="col-span-2 lg:col-span-1">
          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-logo"
          >
            Appointment Letter
          </label>
          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex space-x-4">
                <img
                  src={previewLogo || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileLogoRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleLogoFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            KYC Documents (Aadhaar, PAN, etc)
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            EPFO (Employees’ Provident Fund Organisation) Details
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            ESIC (Employees' State Insurance Corporation) Documents
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            Policy Copy
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            Passport Size Photograph
          </label>

          <div className="flex items-center space-x-4">
            <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div>
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            Police FIR related to the incident
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            Spot Panchnama
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            Final Police Investigation Report
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            Hospitalization and Treatment Papers
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            Contact Details of the Claimant/Nominee or Family
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            Statement from employer with brief details of the incident
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            Residence Address of the Claimant/Nominee
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            Duly filled and signed Claim Form
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-logo"
          >
            Detailed Incident Report by the Supervisor with a description and
            location of the incident (on company letterhead, stamped by the
            company)
          </label>
          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex space-x-4">
                <img
                  src={previewLogo || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileLogoRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleLogoFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            All Hospitalization and Treatment Papers, including Discharge
            Summary, Medical Investigation Reports, X-rays, and Films (if
            applicable)
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            Attested copies of the Attendance Register for 6 months prior to the
            date of the accident until the date of resuming duty
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            Attested copies of the Wage Register for 6 months prior to the month
            of the accident
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            Declaration of month-wise total wages paid, and the total number of
            workers employed, from policy inception until the month of the
            accident (format attached)
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            PAN Card of the company
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            Confirmation if the employee was covered under ESIC at the time of
            the incident
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            Original or Attested Copy of the Death Certificate (in case of death
            claim)
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            Attested Copy of the Post-Mortem Report (in case of death claim)
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            Unnatural Death Report
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            Panchanama Report
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            Final Police Investigation Report
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>

          <label
            className="mb-2 mt-4 block text-sm font-bold"
            htmlFor="file-upload-stamp"
          >
            All relevant Hospitalization and Treatment Documents (if applicable)
          </label>

          <div className="flex items-center space-x-4">
            {/* <div className="mb-4 mt-4">
              <div className="flex items-center space-x-4">
                <img
                  src={previewStamp || noImg}
                  alt="Logo Preview"
                  className="rounded-lg border border-gray-300"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
            </div> */}
            <input
              type="file"
              id="file-upload-logo"
              ref={fileStampRef} // Attach ref to the input
              className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*" // Only accept image files
              onChange={handleStampFileChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocumentsComponent
