import React, { useState, useEffect } from 'react'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import Card from 'components/card'
import { useForm, Controller } from 'react-hook-form'
import { useToaster } from 'common/Toaster'
import CrawfordConstants from 'common/config/crawfordConstants'
import { useSpinner } from 'common/SpinnerLoader'
import { useParams, useNavigate } from 'react-router-dom'
import InputField from 'components/fields/InputField'
import Select from 'react-select'
import SwitchWithText from 'components/switch-with-text'
const apiService = ApiCaller()
const { TOAST } = CrawfordConstants

/**
 * Renders a form to add or edit a district.
 *
 * @return {JSX.Element} The rendered form component.
 */
const AddCustomerCompany = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      segment: '',
      CompanyName: '',
      ShortName: '',
      GSTINByState: '',
    },
  })
  const [hasGST, setGSTStatus] = useState(true)
  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [GSTINByState, setGSTINByState] = useState([])

  const changeGSTStatus = () => {
    setGSTStatus(!hasGST)
  }

  // Fetch data from API
  const fetchOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.SEGMENT_LIST
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: {},
        fields: [],
        page: inputValue ? 1 : 0,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  // Handle input change and trigger API call
  const handleInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchOptions(inputValue)
    }
  }

  const handleSelectionChange = (City) => {
    if (!City) {
      fetchOptions() // Call the function to perform API request
      setOptions([])
    }
  }

  // Fetch data from API
  const getGSTState = async () => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.COMPANY_GST_LIST
      const payload = {
        GSTINByState: [],
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        setGSTINByState(response.data.data || [])
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleGSTChange = (index, value) => {
    const updatedGSTIN = [...GSTINByState]
    updatedGSTIN[index].GST = value
    setGSTINByState(updatedGSTIN)
  }

  /**
   * Handles the form submission to create or edit a customer company.
   *
   * @async
   * @function onFormSubmit
   * @param {Object} formData - The form data object containing company details.
   * @param {Object} formData.segment - The selected customer segment object.
   * @param {string} formData.segment.value - The value of the selected customer segment.
   * @param {string} formData.CompanyName - The name of the company.
   * @param {string} formData.ShortName - The short name of the company.
   * @returns {Promise<void>} A promise that resolves when the company has been created or edited.
   */
  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.CUSTOMER_COMPANY_CREATE
      const payload = {
        _id: id ? id : undefined,
        customerSegment: formData.segment.value,
        name: formData.CompanyName,
        shortName: formData.ShortName,
        GSTINByState: GSTINByState,
        hasGST: hasGST,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        addToast({
          title: `Company "${formData.CompanyName}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        navigate(`/customers/company-list`)
      } else {
        addToast({
          title: `Company creation failed.`,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  /**
   * Fetches the details of a specific company and sets the form values.
   *
   * @async
   * @function getCompanyDetails
   * @returns {Promise<void>} A promise that resolves when the company details are fetched and the form values are set.
   */
  const getCompanyDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.CUSTOMER_COMPANY_DETAIL.replace(':id', id)
      const payload = {
        _id: id,
        checkRoles: false,
        accessOf: ['CustomerCompanyEdit'],
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data === 'Access Denied') {
        addToast({
          title: 'Access Denied',
          type: 'error',
        })
        navigate(`/customers/company-list`)
      } else if (response?.data?.value) {
        const companyData = response?.data?.data
        setValue('CompanyName', companyData?.name)
        setValue('ShortName', companyData?.shortName)
        setGSTStatus(companyData?.hasGST)
        setValue(
          'segment',
          {
            label: companyData?.customerSegment?.name,
            value: companyData?.customerSegment?._id,
          } || {}
        )
        setGSTINByState(companyData?.GSTINByState)
      } else {
        addToast({
          title: 'Some Error Occured',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error verifying token:', error)
    } finally {
      hideSpinner()
    }
  }

  const handleCancel = () => {
    navigate(`/customers/company-list`)
  }

  useEffect(() => {
    getGSTState()
    if (id) {
      getCompanyDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="mt-7 grid grid-cols-2 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <p className="text-xl font-bold text-navy-700">
              {id ? 'Edit' : 'Add'} Customer Company
            </p>
          </div>
        </div>
        <div className="mt-7 grid grid-cols-2 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-4 block text-sm font-bold">
              Segement <span className="text-red-500">*</span>
            </label>
            <Controller
              name="segment"
              control={control}
              rules={{ required: 'Segment is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={options}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handleInputChange}
                  onFocus={() => fetchOptions()}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(segment) => {
                    field.onChange(segment)
                    handleSelectionChange(segment) // Call a function to handle API call
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on error
                      minHeight: '44px', // Increase height
                      borderRadius: '0.75rem', // Border radius
                      marginBottom: '4px',
                      boxShadow: state.isFocused
                        ? `0 0 0 1px ${
                            error ? 'red' : 'rgba(218, 222, 236, 1)'
                          }` // Red shadow on focus if error
                        : '',
                      '&:hover': {
                        borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on hover if error
                      },
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      fontSize: '14px',
                      color: error ? 'red' : '#999', // Red placeholder color on error, default gray otherwise
                    }),
                  }}
                />
              )}
            />
            {errors.segment && (
              <div className="error-message text-right text-red-500">
                {errors.segment.message}
              </div>
            )}

            <InputField
              extra="mb-2 w-full"
              label="Short Name"
              id="ShortName"
              type="text"
              placeholder="Enter short name"
              registrationProps={register('ShortName', {
                required: 'Short name is required',
              })}
              isFieldRequired={true}
              state={errors.ShortName && 'error'}
            />
            <div className="error-message text-right">
              {errors.ShortName?.message}
            </div>
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Customer Company Name"
              id="CompanyName"
              type="text"
              placeholder="Enter customer company name"
              registrationProps={register('CompanyName', {
                required: 'Customer company name is required',
              })}
              isFieldRequired={true}
              state={errors.CompanyName && 'error'}
            />
            <div className="error-message text-right">
              {errors.CompanyName?.message}
            </div>

            <div className="col-span-2 lg:col-span-1">
              <label className="mt-4 block text-sm font-bold">
                HAS GST <span className="text-red-500">*</span>
              </label>
              <div className="mt-2 flex w-full justify-start">
                <SwitchWithText
                  textLeft="NO"
                  textRight="YES"
                  checked={hasGST}
                  onChange={changeGSTStatus}
                />
              </div>
            </div>
          </div>
        </div>
        {hasGST && (
          <div className="mt-7 grid grid-cols-1 gap-3">
            <div className="col-span-2 lg:col-span-1">
              <h4 className="text-md mb-4 font-bold text-gray-700">
                GST Details
              </h4>
            </div>
            <table className="w-full table-auto border-collapse rounded-2xl border border-blue-300">
              <thead>
                <tr className="bg-blue-100">
                  <th className="border border-blue-300 px-2 py-2 text-left">
                    State
                  </th>
                  <th
                    className="border border-blue-300 px-2 py-2 text-left"
                    style={{ width: '200px' }}
                  >
                    GST #
                  </th>
                </tr>
              </thead>
              <tbody>
                {GSTINByState.map((GSTIN, index) => (
                  <tr key={index}>
                    <td className="border border-gray-300 px-2 py-2">
                      {GSTIN.state?.name || 'No State Name'}
                    </td>
                    <td className="border border-gray-300 px-2 py-2">
                      <input
                        type="text"
                        className="form-control w-full rounded border border-gray-300 p-2"
                        value={GSTIN.GST || ''}
                        onChange={(e) => handleGSTChange(index, e.target.value)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {/* Button aligned to the right */}
        <div className="mt-4 flex w-full justify-end">
          <button
            className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
          hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base`}
            type="submit"
          >
            <span>{id ? 'Update' : 'Save'}</span>
          </button>

          <button
            className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
          hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base`}
            type="button"
            onClick={handleCancel}
          >
            <span>Cancel</span>
          </button>
        </div>
      </form>
    </Card>
  )
}

export default AddCustomerCompany
