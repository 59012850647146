import { policyDocumentColumnsDataOrders } from './variables/ColumnsDataOrders'
import PolicyDocumentTable from './components/List'
import Card from 'components/card'
import apiConfig from 'common/config/apiConfig'
import { useState, useEffect } from 'react'
import { useSpinner } from 'common/SpinnerLoader'
import { useToaster } from 'common/Toaster'
import ApiCaller from 'common/services/apiServices'
import CrawfordConstants from 'common/config/crawfordConstants'
/**
 * Renders the Policy Document List component with the fetched Policy Document data.
 *
 * @return {JSX.Element} The rendered Policy Document List component
 */
const PolicyDocument = () => {
  const [allPolicyDocumentData, setAllPolicyDocumentData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const itemsPerPage = 10
  const [isPolicyDocumentLoading, setAllPolicyLoading] = useState(false)
  const apiService = ApiCaller()
  const { TOAST } = CrawfordConstants

  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()
  const fetchData = async () => {
    try {
      setAllPolicyLoading(true)
      const apiUrl = apiConfig.POLICY_DOCUMENT_LIST
      const payload = {
        page: currentPage,
        keyword: searchValue,
        fields: ['insuredOffice', 'status'],
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        setAllPolicyDocumentData(response?.data?.data?.results)
        setTotalCount(response?.data?.data?.total || 0)
      } else {
        setAllPolicyDocumentData([])
        setTotalCount(0)
      }
    } catch (error) {
      console.error('Error during API call:', error)
    } finally {
      setAllPolicyLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchValue])

  const togglePolicyDocumentStatus = async (id, newStatus) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.POLICY_DOCUMENT_STATUS
      const payload = {
        status: newStatus,
        _id: id ? id : undefined,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        fetchData()
        addToast({
          title: 'Status change successfully.',
          type: 'success',
        })
      } else {
        addToast({ title: 'Status change failed.', type: 'error' })
      }
    } catch (error) {
      addToast({ title: TOAST.MESSAGES.ERROR.swwError, type: 'error' })
    } finally {
      hideSpinner()
    }
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const setGlobalFilter = (value) => {
    try {
      setCurrentPage(1)
      setSearchValue(value)
    } catch (err) {
      console.log('err', err)
    } finally {
      setAllPolicyLoading(false)
    }
  }

  return (
    <>
      <Card extra={'w-full h-full bg-white mt-3'}>
        <PolicyDocumentTable
          tableData={allPolicyDocumentData}
          columnsData={policyDocumentColumnsDataOrders}
          totalCount={totalCount}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          isPolicyDocumentLoading={isPolicyDocumentLoading}
          setAllPolicyLoading={setAllPolicyLoading}
          setGlobalFilter={setGlobalFilter}
          togglePolicyDocumentStatus={togglePolicyDocumentStatus}
        />
      </Card>
    </>
  )
}

export default PolicyDocument
