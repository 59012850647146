import React, { useEffect, useState } from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
const googleKey = process.env.REACT_APP_GOOGLE_KEY
const MapComponent = ({ lat, lng }) => {
  const [markerPosition, setMarkerPosition] = useState({ lat, lng })

  // Update marker position when lat or lng props change
  useEffect(() => {
    setMarkerPosition({ lat, lng })
  }, [lat, lng])

  // Called when the marker is dragged
  const handleMarkerDragEnd = (event) => {
    const newLat = event.latLng.lat()
    const newLng = event.latLng.lng()
    setMarkerPosition({ lat: newLat, lng: newLng })
  }

  return (
    <LoadScript googleMapsApiKey={googleKey}>
      <GoogleMap
        mapContainerStyle={{
          height: '400px',
          width: '100%',
        }}
        zoom={17}
        center={markerPosition}
        options={{
          scrollwheel: false,
        }}
      >
        <Marker
          position={markerPosition}
          draggable={true}
          onDragEnd={handleMarkerDragEnd}
        />
      </GoogleMap>
    </LoadScript>
  )
}

export default MapComponent
