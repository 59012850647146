import React, { useEffect, useState, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import Card from 'components/card'
import { useSpinner } from 'common/SpinnerLoader'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import { useToaster } from 'common/Toaster'
import SwitchWithText from 'components/switch-with-text'
import InputField from 'components/fields/InputField'
import CrawfordConstants from 'common/config/crawfordConstants'
import { MdEdit, MdCloudDownload } from 'react-icons/md'
import TooltipHorizon from 'components/tooltip'
import Select from 'react-select'
import moment from 'moment'
import { getUserInfo, readFileBasedOnId } from 'common/commonFunction'
import noImg from 'assets/img/layers/no-image.png'

const AddPolicyDocument = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const apiService = ApiCaller()
  const { TOAST } = CrawfordConstants
  const policyDocumentRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [previewFile, setFilePreview] = useState(null)
  const { showSpinner, hideSpinner } = useSpinner()
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      policyName: '',
      policyNumber: '',
      department: '',
      policyType: '',
      insurerCompany: '',
      insurerOffice: '',
      from: '',
      to: '',
    },
  })
  const [isPolicyDocumentActive, setPolicyDocumentStatus] = useState(true)
  const [options, setOptions] = useState([])
  const [departmentOptions, setDepartmentOptions] = useState([])
  const [policyTypeOptions, setPolicyTypeOptions] = useState([])
  const [insurerCompanyOptions, setInsurerCompanyOptions] = useState([])
  const [insurerOfficeOptions, setInsurerOfficeOptions] = useState([])

  const { addToast } = useToaster()
  const [isPopUpOpen, setIsPopUpOpen] = useState(false)
  const [documentList, setDocumentList] = useState([])
  const [parentForm, setParentForm] = useState(false)
  const [policyFromDate, setPolicyFromDate] = useState()
  const [policyToDate, setPolicyToDate] = useState()
  const [policyDocFile, setPolicyDocFile] = useState()

  const [selectedInsuredOfficeValue, setSelectedInsuredOfficeValue] =
    useState(null)

  const changePolicyDocumentStatus = () => {
    setPolicyDocumentStatus(!isPolicyDocumentActive)
  }

  const uploadFile = async (file) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.UPLOAD_IMAGE
      const data = new FormData()
      data.append('file', file)
      const response = await apiService.apiCall('post', apiUrl, data)
      if (response?.data?.value) {
        setPolicyDocFile(response?.data?.data[0])
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handlePolicyDocumentFileChange = (e) => {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      setFilePreview(URL.createObjectURL(selectedFile)) // Create a preview URL
      uploadFile(selectedFile)
    }
  }

  /**
   * Fetches customer options from the API based on the input value.
   * Updates the options state with the fetched results.
   * @async
   * @function fetchOptions
   * @param {string} inputValue - The keyword used to search for customer options.
   * @returns {Promise<void>} - A Promise that resolves when the API call is complete.
   */
  const fetchOptions = async (inputValue) => {
    try {
      setIsLoading(true) // Set loading state to true during fetch
      const apiUrl = apiConfig.CUSTOMER_LIST // API endpoint for customer list
      const payload = {
        keyword: inputValue ? inputValue : '', // Use inputValue as keyword if provided
        filter: {}, // Additional filters (empty in this case)
        page: inputValue ? 1 : 0, // Set page to 1 if searching, otherwise 0
      }
      const response = await apiService.apiCall('post', apiUrl, payload) // Make API call
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name, // Option label
          value: item._id, // Option value
          fullObject: item, // Full customer object for future reference
        }))
        setOptions(newOptions) // Update options with API response
      }
    } catch (error) {
      console.error('Error fetching options:', error) // Log errors if API call fails
    } finally {
      setIsLoading(false) // Set loading state to false after fetch
    }
  }

  /**
   * Handles changes to the input field, triggering the fetchOptions function.
   * @function handleInputChange
   * @param {string} inputValue - The current value of the input field.
   */
  const handleInputChange = (inputValue) => {
    setParentForm(false)
    if (inputValue.length > 0) {
      fetchOptions(inputValue) // Trigger API call if input is non-empty
    }
  }

  /**
   * Handles selection change for a insuredOffice. If the selection is cleared, fetches default options.
   * @function handleSelectionChange
   * @param {Object|null} insuredOffice - The selected insuredOffice object or null if selection is cleared.
   */
  const handleSelectionChange = (insuredOffice) => {
    setParentForm(false)
    if (!insuredOffice) {
      fetchOptions() // Fetch default options if selection is cleared
      setOptions([]) // Clear current options
      setSelectedInsuredOfficeValue(null)
    } else {
      setSelectedInsuredOfficeValue(insuredOffice)
    }
  }

  /**
   * Fetches policy type options from the API based on the input value and selected department.
   * If a department is not selected, it displays a warning toast notification.
   * @async
   * @function fetchPolicyTypeOptions
   * @param {string} inputValue - The keyword used to search for policy type options.
   * @returns {Promise<void>} - A Promise that resolves when the API call is complete.
   */
  const fetchPolicyTypeOptions = async (inputValue) => {
    if (getValues('department')?.value) {
      // Check if department is selected
      try {
        setIsLoading(true) // Set loading state to true during fetch
        const apiUrl = apiConfig.POLICY_TYPE_LIST // API endpoint for policy type list
        const payload = {
          keyword: inputValue ? inputValue : '', // Use inputValue as keyword if provided
          filter: { department: getValues('department')?.value }, // Filter by department
          page: inputValue ? 1 : 0, // Set page to 1 if searching, otherwise 0
        }
        const response = await apiService.apiCall('post', apiUrl, payload) // Make API call
        if (response?.data?.value) {
          const newOptions = response?.data?.data?.results.map((item) => ({
            label: item.name, // Option label
            value: item._id, // Option value
          }))
          setPolicyTypeOptions(newOptions) // Update policy type options with API response
        }
      } catch (error) {
        console.error('Error fetching options:', error) // Log errors if API call fails
      } finally {
        setIsLoading(false) // Set loading state to false after fetch
      }
    } else {
      addToast({
        title: 'Please select department.', // Display warning if no department selected
        type: 'warning',
      })
    }
  }

  /**
   * Handles changes to the policy type input field, triggering fetchPolicyTypeOptions if input is non-empty.
   * @function handlePolicyTypeInputChange
   * @param {string} inputValue - The current value of the input field.
   */
  const handlePolicyTypeInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchPolicyTypeOptions(inputValue) // Trigger API call if input is non-empty
    }
  }

  /**
   * Handles selection change for a policy type. If the selection is cleared, fetches default policy type options.
   * @function handlePolicyTypeSelectionChange
   * @param {Object|null} policyType - The selected policy type object or null if selection is cleared.
   */
  const handlePolicyTypeSelectionChange = (policyType) => {
    if (!policyType) {
      fetchPolicyTypeOptions() // Fetch default policy type options if selection is cleared
      setPolicyTypeOptions([]) // Clear current policy type options
    }
  }

  /**
   * Fetches insurer company options from the API based on the input value and selected policy type.
   * If a policy type is not selected, it displays a warning toast notification.
   * @async
   * @function fetchInsurerCompanyOptions
   * @param {string} inputValue - The keyword used to search for insurer company options.
   * @returns {Promise<void>} - A Promise that resolves when the API call is complete.
   */
  const fetchInsurerCompanyOptions = async (inputValue) => {
    if (getValues('policyType')?.value) {
      // Check if policy type is selected
      try {
        setIsLoading(true) // Set loading state to true during fetch
        const apiUrl = apiConfig.GET_COMPANY_BASED_ON_POLICY_TYPE // API endpoint for insurer companies
        const payload = {
          keyword: inputValue ? inputValue : '', // Use inputValue as keyword if provided
          filter: { policyType: getValues('policyType')?.value }, // Filter by selected policy type
          page: inputValue ? 1 : 0, // Set page to 1 if searching, otherwise 0
        }
        const response = await apiService.apiCall('post', apiUrl, payload) // Make API call
        if (response?.data?.value) {
          const newOptions = response?.data?.data?.results.map((item) => ({
            label: item.name, // Option label
            value: item._id, // Option value
          }))
          setInsurerCompanyOptions(newOptions) // Update insurer company options with API response
        }
      } catch (error) {
        console.error('Error fetching options:', error) // Log errors if API call fails
      } finally {
        setIsLoading(false) // Set loading state to false after fetch
      }
    } else {
      addToast({
        title: 'Please select policy type.', // Display warning if no policy type selected
        type: 'warning',
      })
    }
  }

  /**
   * Handles changes to the insurer company input field, triggering fetchInsurerCompanyOptions if input is non-empty.
   * @function handleInsurerCompanyInputChange
   * @param {string} inputValue - The current value of the input field.
   */
  const handleInsurerCompanyInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchInsurerCompanyOptions(inputValue) // Trigger API call if input is non-empty
    }
  }

  /**
   * Handles selection change for an insurer company. If the selection is cleared, fetches default insurer company options.
   * @function handleInsurerCompanySelectionChange
   * @param {Object|null} insurerCompany - The selected policy type object or null if selection is cleared.
   */
  const handleInsurerCompanySelectionChange = (insurerCompany) => {
    if (!insurerCompany) {
      fetchInsurerCompanyOptions() // Fetch default insurer company options if selection is cleared
      setInsurerCompanyOptions([]) // Clear current insurer company options
    }
  }

  /**
   * Fetches insurer office options from the API based on the input value and selected insurer company.
   * If an insurer company is not selected, displays a warning toast notification.
   * @async
   * @function fetchInsurerOfficeOptions
   * @param {string} inputValue - The keyword used to search for insurer office options.
   * @returns {Promise<void>} - A Promise that resolves when the API call is complete.
   */
  const fetchInsurerOfficeOptions = async (inputValue) => {
    if (getValues('insurerCompany')?.value) {
      // Check if insurer company is selected
      try {
        setIsLoading(true) // Set loading state to true during fetch
        const apiUrl = apiConfig.CUSTOMER_LIST // API endpoint for insurer offices
        const payload = {
          keyword: inputValue ? inputValue : '', // Use inputValue as keyword if provided
          filter: { customerCompany: getValues('insurerCompany')?.value }, // Filter by selected insurer company
          page: inputValue ? 1 : 0, // Set page to 1 if searching, otherwise 0
        }
        const response = await apiService.apiCall('post', apiUrl, payload) // Make API call
        if (response?.data?.value) {
          const newOptions = response?.data?.data?.results.map((item) => ({
            label: item.name, // Option label
            value: item._id, // Option value
          }))
          setInsurerOfficeOptions(newOptions) // Update insurer office options with API response
        }
      } catch (error) {
        console.error('Error fetching options:', error) // Log errors if API call fails
      } finally {
        setIsLoading(false) // Set loading state to false after fetch
      }
    } else {
      addToast({
        title: 'Please select insurer company.', // Display warning if no insurer company selected
        type: 'warning',
      })
    }
  }

  /**
   * Handles changes to the insurer office input field, triggering fetchInsurerOfficeOptions if input is non-empty.
   * @function handleInsurerOfficeInputChange
   * @param {string} inputValue - The current value of the input field.
   */
  const handleInsurerOfficeInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchInsurerOfficeOptions(inputValue) // Trigger API call if input is non-empty
    }
  }

  /**
   * Handles selection change for an insurer office. If the selection is cleared, fetches default insurer office options.
   * @function handleInsurerOfficeSelectionChange
   * @param {Object|null} insurerOffice - The selected insurer office object or null if selection is cleared.
   */
  const handleInsurerOfficeSelectionChange = (insurerOffice) => {
    if (!insurerOffice) {
      fetchInsurerOfficeOptions() // Fetch default insurer office options if selection is cleared
      setInsurerOfficeOptions([]) // Clear current insurer office options
    }
  }

  /**
   * Fetches department options based on the provided input value and updates the department options state.
   *
   * @async
   * @function fetchDepartmentOptions
   * @param {string} inputValue - The keyword to filter department options by.
   * @returns {Promise<void>} Returns nothing.
   */
  const fetchDepartmentOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.GET_DEPARTMENT
      const payload = {
        keyword: inputValue,
        filter: {},
        page: 1,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setDepartmentOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Handles input changes for the department search input.
   * Triggers a fetch of department options when the input length is greater than zero.
   *
   * @function handleDepartmentInputChange
   * @param {string} inputValue - The current value of the department search input.
   */
  const handleDepartmentInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchDepartmentOptions(inputValue)
    }
  }

  /**
   * Handles changes in the selected department.
   * If no department is selected, it triggers a fetch of all department options.
   *
   * @function handleDepartmentSelectionChange
   * @param {Object|null} department - The currently selected department object or null if no selection.
   */
  const handleDepartmentSelectionChange = (department) => {
    if (!department) {
      fetchDepartmentOptions() // Call the function to perform API request
      setDepartmentOptions([])
    }
  }

  const openPopup = () => {
    reset()
    setIsPopUpOpen(true)
  }

  const closePopup = () => {
    setIsPopUpOpen(false)
  }

  const onDocumentSubmit = (formData) => {
    const transformedData = {
      documentId: formData.documentId,
      name: formData.policyName,
      policyNo: formData.policyNumber,
      from: formData?.fromDate,
      to: formData?.toDate,
      department: formData?.department?.value,
      policyType: formData?.policyType?.value,
      insurerCompany: formData?.insurerCompany?.value,
      insurerOffice: formData?.insurerOffice?.value,
      documentImage: policyDocFile,
    }
    setDocumentList((prevDocumentList) => {
      const existingIndex = prevDocumentList.findIndex(
        (item) => item._id === transformedData.documentId
      )
      if (existingIndex !== -1) {
        // Update the existing entry
        const updatedList = [...prevDocumentList]
        updatedList[existingIndex] = {
          ...updatedList[existingIndex],
          ...transformedData,
        }
        return updatedList
      }
      // Add new entry if no match found
      return [...prevDocumentList, transformedData]
    })
    setIsPopUpOpen(false)
  }

  const onDocumentFormSubmit = async () => {
    setParentForm(true)
    if (selectedInsuredOfficeValue?.value) {
      const userInfo = getUserInfo()
      try {
        showSpinner()
        const apiUrl = apiConfig.POLICY_DOCUMENT_CREATE
        const payload = {
          _id: id ? id : undefined,
          status: isPolicyDocumentActive,
          insuredOffice: selectedInsuredOfficeValue?.value,
          insuredCompany:
            selectedInsuredOfficeValue?.fullObject?.customerCompany,
          listOfDocuments: documentList,
          users: {
            email: userInfo.email,
            name: userInfo.name,
          },
        }
        const response = await apiService.apiCall('post', apiUrl, payload)
        if (response?.data?.value) {
          addToast({
            title: `Policy document ${id ? 'edited' : 'created'} successfully.`,
            type: 'success',
          })
          navigate(`/insurance/policy-document-list`)
        } else {
          addToast({ title: 'Policy document creation failed.', type: 'error' })
        }
      } catch (error) {
        addToast({ title: TOAST.MESSAGES.ERROR.swwError, type: 'error' })
      } finally {
        hideSpinner()
      }
    }
  }

  /**
   * Sets the policy name based on the policy dates and policy number.
   *
   * @function setPolicyName
   * @param {string|null} policyFromDate - Formatted start date of the policy, or null if not available.
   * @param {string|null} policyToDate - Formatted end date of the policy, or null if not available.
   * @param {string|null} policyNumber - The policy number, or null if not available.
   *
   * @description Clears the policy name if any of the dates or policy number is missing.
   * Otherwise, it sets the policy name in the format "policyFromDate-policyToDate-policyNumber".
   * It also falls back on retrieving the policy number from `getValues` if it's not provided.
   */
  const setPolicyName = (policyFromDate, policyToDate, policyNumber) => {
    setValue('policyName', null)
    if (policyFromDate && policyToDate && getValues('policyNumber')) {
      const policyName = `${policyFromDate}-${policyToDate}-${policyNumber || getValues('policyNumber')}`
      setValue('policyName', policyName)
    }
  }

  /**
   * Handles the selection and formatting of the "From Date" for the policy.
   *
   * @function handleFromDateSelectionChange
   * @param {Date|string} date - The selected date (either a Date object or string).
   *
   * @description This function formats the selected date in 'DDMMMYY' format, sets it as
   * the policy start date, and updates the policy name by calling `setPolicyName`.
   *
   * @example
   * handleFromDateSelectionChange("2024-03-01");
   */
  const handleFromDateSelectionChange = (date) => {
    if (date) {
      const formattedFromDate = moment(date).format('DDMMMYY')
      setPolicyFromDate(formattedFromDate)
      setPolicyName(formattedFromDate, policyToDate, getValues('policyNumber'))
    }
  }

  /**
   * Handles the selection and formatting of the "To Date" for the policy.
   *
   * @function handleToDateSelectionChange
   * @param {Date|string} date - The selected date (either a Date object or string).
   *
   * @description This function formats the selected date in 'DDMMMYY' format, sets it as
   * the policy end date, and updates the policy name by calling `setPolicyName`.
   *
   * @example
   * handleToDateSelectionChange("2024-06-01");
   */
  const handleToDateSelectionChange = (date) => {
    if (date) {
      const formattedToDate = moment(date).format('DDMMMYY')
      setPolicyToDate(formattedToDate)
      setPolicyName(policyFromDate, formattedToDate, getValues('policyNumber'))
    }
  }

  const getPolicyDocumentDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.POLICY_DOCUMENT_DETAIL
      const payload = {
        _id: id,
        checkRoles: false,
        accessOf: ['PolicyDocEdit'],
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const policyDocumentDetail = response?.data?.data
        setSelectedInsuredOfficeValue(
          {
            label: policyDocumentDetail?.insuredOffice?.name,
            value: policyDocumentDetail?.insuredOffice?._id,
            fullObject: {
              customerCompany:
                policyDocumentDetail?.insuredOffice?.customerCompany,
            },
          } || {}
        )
        setDocumentList(policyDocumentDetail?.listOfDocuments)
      } else {
        addToast({ title: 'Some Error Occurred', type: 'error' })
      }
    } catch (error) {
      console.error('Error:', error)
    } finally {
      hideSpinner()
    }
  }

  useEffect(() => {
    if (id) getPolicyDocumentDetails()
  }, [id])

  /**
   * A function to handle the click event for editing a country.
   *
   * @param {Object} data - The data object containing the country details
   * @return {void}
   */
  const handleEditClick = async (data) => {
    setValue('documentId', data._id)
    setValue('policyName', data.name)
    setValue('policyNumber', data.policyNo)
    setValue(
      'department',
      {
        label: data?.department?.name,
        value: data?.department?._id,
      } || {}
    )
    setValue(
      'policyType',
      {
        label: data?.policyType?.name,
        value: data?.policyType?._id,
      } || {}
    )
    setValue(
      'insurerCompany',
      {
        label: data?.insurerCompany?.name,
        value: data?.insurerCompany?._id,
      } || {}
    )
    setValue(
      'insurerOffice',
      {
        label: data?.insurerOffice?.name,
        value: data?.insurerOffice?._id,
      } || {}
    )
    setValue('fromDate', moment(new Date(data?.from)).format('YYYY-MM-DD'))
    setValue('toDate', moment(new Date(data?.to)).format('YYYY-MM-DD'))
    setPolicyFromDate(moment(data?.from).format('DDMMMYY'))
    setPolicyToDate(moment(data?.to).format('DDMMMYY'))
    if (data?.documentImage) {
      const docURL = await readFileBasedOnId(data?.documentImage)
      setFilePreview(docURL)
    }
    setIsPopUpOpen(true)
  }

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
      <div className="flex justify-between mt-[25px] ">
        <div>
          <p className="text-xl font-bold text-navy-700 dark:text-white">
            {id ? 'Edit' : 'Add'} Policy Document
          </p>
        </div>

        <div className="col-span-2 lg:col-span-1">
          <div className="flex w-full justify-end">
            <SwitchWithText
              textLeft="OFF"
              textRight="ON"
              checked={isPolicyDocumentActive}
              onChange={changePolicyDocumentStatus}
            />
          </div>
        </div>
      </div>
      <div className="mt-7 grid grid-cols-2 gap-3">
        <div className="col-span-2 grid lg:col-span-1">
          <label className="mb-2 block text-sm font-bold text-navy-700">
            Insured Office
            <span className="text-red-500"> * </span>
          </label>
          <Select
            value={selectedInsuredOfficeValue} // Bind state to Select component
            options={options} // Pass options to the Select component
            placeholder="Search and select..."
            isClearable
            isLoading={isLoading}
            onInputChange={handleInputChange}
            onFocus={() => fetchOptions()}
            noOptionsMessage={() => 'Type to search...'}
            onChange={handleSelectionChange} // Handle change
            styles={{
              control: (base, state) => ({
                ...base,
                minHeight: '44px',
                borderRadius: '0.75rem',
                borderWidth: '1px',
                marginBottom: '2px',
                borderColor:
                  selectedInsuredOfficeValue === null && parentForm
                    ? 'red'
                    : state.isFocused
                      ? 'rgb(59, 130, 246)' // Tailwind blue-500 for focus
                      : 'rgba(218, 222, 236, 1)', // Default gray color

                // Blue outline (ring) on focus, red if error
                boxShadow: state.isFocused
                  ? `0 0 0 1px ${selectedInsuredOfficeValue === null && parentForm ? 'red' : 'rgb(59, 130, 246)'}`
                  : '',

                '&:hover': {
                  borderColor:
                    selectedInsuredOfficeValue === null && parentForm
                      ? 'red'
                      : 'rgb(59, 130, 246)', // Hover blue if no error
                },
              }),
              placeholder: (base) => ({
                ...base,
                fontWeight: 'normal',
                fontSize: '14px',
                color:
                  selectedInsuredOfficeValue === null && parentForm
                    ? 'red'
                    : '#999', // Red placeholder on error
              }),
              menu: (base) => ({
                ...base,
                width: '100%', // Width of the dropdown menu
                maxHeight: '250px', // Max height for the dropdown menu
                borderRadius: '8px',
                zIndex: 49, // Set a higher zIndex to ensure the menu appears above other elements
              }),
              menuList: (base) => ({
                ...base,
                maxHeight: '250px', // Max height of the list container for scrolling
                padding: 0, // Optional padding for the menu list
                zIndex: 49, // Set zIndex for the menu list as well if needed
              }),
              singleValue: (base) => ({
                ...base,
                color: '#333', // Color for selected text
              }),
              dropdownIndicator: (base, state) => ({
                ...base,
                color: state.isFocused
                  ? 'rgb(59, 130, 246)'
                  : 'rgba(156, 163, 175, 1)', // Blue dropdown indicator on focus
                '&:hover': {
                  color: 'rgb(59, 130, 246)',
                },
              }),
              indicatorSeparator: (base) => ({
                ...base,
                display: 'none', // Remove separator
              }),
            }}
          />
          {selectedInsuredOfficeValue === null && parentForm && (
            <div className="error-message text-right">
              Insured office is required
            </div>
          )}
        </div>
      </div>
      <div className="mt-7 grid grid-cols-2 gap-3">
        <div className="col-span-2 lg:col-span-1">
          <p className="text-xl font-bold text-navy-700">List of Documents</p>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <div className="flex w-full justify-end">
            <button
              type="button"
              onClick={openPopup}
              className="px-4 py-2 bg-brand-500 text-white rounded-lg text-sm hover:bg-brand-400 focus:outline-none focus:ring focus:ring-indigo-200"
            >
              Add New
            </button>
          </div>
        </div>
      </div>
      {isPopUpOpen && (
        <div
          className="relative z-[999999]"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          {/* <!-- Overlay background --> */}
          <div
            className="fixed inset-0 bg-gray-500/75 transition-opacity"
            aria-hidden="true"
          ></div>
          {/* <!-- Modal container --> */}
          <div className="fixed inset-0 z-[999999] w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              {/* <!-- Modal content --> */}
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                {/* <!-- Title section --> */}
                <div className="bg-brand-500 px-2 pb-4 sm:p-2 sm:pb-2">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-2 text-center sm:ml-2 sm:mt-0 sm:text-left">
                      <h2
                        className="text-lg font-semibold text-white"
                        id="modal-title"
                      >
                        {getValues('documentId') ? 'Edit' : 'Create'} Policy
                        Document
                      </h2>
                    </div>
                  </div>
                </div>
                <form onSubmit={handleSubmit(onDocumentSubmit)}>
                  {/* <!-- Body section --> */}
                  <div className="mt-2 px-4 pb-4 sm:px-6 sm:pb-4 overflow-y-auto">
                    <div className="grid grid-cols-2 gap-3">
                      <input
                        type="hidden"
                        id="hiddenField"
                        {...register('documentId')}
                      />
                      <div className="col-span-2 lg:col-span-1">
                        <InputField
                          extra="w-full"
                          label="Policy Name"
                          id="policyName"
                          type="text"
                          disabled
                          placeholder="Enter Policy Name"
                          registrationProps={register('policyName', {
                            required: 'Policy name is required',
                          })}
                          isFieldRequired={true}
                          state={errors.policyName && 'error'}
                        />
                        <div className="error-message text-right">
                          {errors.policyName?.message}
                        </div>
                      </div>
                      <div className="col-span-2 lg:col-span-1">
                        <InputField
                          extra="w-full"
                          label="Policy Number"
                          id="policyNumber"
                          type="text"
                          placeholder="Enter Policy Number"
                          registrationProps={register('policyNumber', {
                            required: 'Policy number is required',
                          })}
                          isFieldRequired={true}
                          state={errors.policyNumber && 'error'}
                          onKeyUp={() => {
                            setPolicyName(policyFromDate, policyToDate)
                          }}
                        />
                        <div className="error-message text-right">
                          {errors.policyNumber?.message}
                        </div>
                      </div>
                      <div className="col-span-2 lg:col-span-1">
                        <label className="mb-2 block text-sm font-bold text-navy-700">
                          Department
                          <span className="text-red-500"> * </span>
                        </label>
                        <Controller
                          name="department"
                          control={control}
                          rules={{ required: 'Department is required' }}
                          render={({ field, fieldState: { error } }) => (
                            <Select
                              {...field}
                              id="department"
                              {...register('department')}
                              options={departmentOptions}
                              placeholder="Search and select..."
                              classNamePrefix="select"
                              isClearable
                              isLoading={isLoading}
                              onFocus={() => fetchDepartmentOptions()}
                              onInputChange={handleDepartmentInputChange}
                              noOptionsMessage={() => 'Type to search...'}
                              onChange={(department) => {
                                field.onChange(department)
                                handleDepartmentSelectionChange(department)
                                if (!department) {
                                  setValue('policyType', null)
                                  setValue('insurerCompany', null)
                                  setValue('insurerOffice', null)
                                  setPolicyTypeOptions([])
                                  setInsurerCompanyOptions([])
                                  setInsurerOfficeOptions([])
                                }
                              }}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  minHeight: '44px',
                                  borderRadius: '0.75rem',
                                  borderWidth: '1px',
                                  marginBottom: '2px',
                                  borderColor: error
                                    ? 'red'
                                    : state.isFocused
                                      ? 'rgb(59, 130, 246)' // Tailwind blue-500 for focus
                                      : 'rgba(218, 222, 236, 1)', // Default gray color

                                  // Blue outline (ring) on focus, red if error
                                  boxShadow: state.isFocused
                                    ? `0 0 0 1px ${
                                        error ? 'red' : 'rgb(59, 130, 246)'
                                      }`
                                    : '',

                                  '&:hover': {
                                    borderColor: error
                                      ? 'red'
                                      : 'rgb(59, 130, 246)', // Hover blue if no error
                                  },
                                }),
                                placeholder: (base) => ({
                                  ...base,
                                  fontWeight: 'normal',
                                  fontSize: '14px',
                                  color: error ? 'red' : '#999', // Red placeholder on error
                                }),
                                menu: (base) => ({
                                  ...base,
                                  width: '100%', // Width of the dropdown menu
                                  maxHeight: '250px', // Max height for the dropdown menu
                                  borderRadius: '8px',
                                  zIndex: 49, // Set a higher zIndex to ensure the menu appears above other elements
                                }),
                                menuList: (base) => ({
                                  ...base,
                                  maxHeight: '250px', // Max height of the list container for scrolling
                                  padding: 0, // Optional padding for the menu list
                                  zIndex: 49, // Set zIndex for the menu list as well if needed
                                }),
                                singleValue: (base) => ({
                                  ...base,
                                  color: '#333', // Color for selected text
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  color: state.isFocused
                                    ? 'rgb(59, 130, 246)'
                                    : 'rgba(156, 163, 175, 1)', // Blue dropdown indicator on focus
                                  '&:hover': {
                                    color: 'rgb(59, 130, 246)',
                                  },
                                }),
                                indicatorSeparator: (base) => ({
                                  ...base,
                                  display: 'none', // Remove separator
                                }),
                              }}
                            />
                          )}
                        />
                        {errors.department && (
                          <div className="error-message text-right text-red-500">
                            {errors.department.message}
                          </div>
                        )}
                      </div>
                      <div className="col-span-2 lg:col-span-1">
                        <label className="mb-2 block text-sm font-bold text-navy-700">
                          Policy Type
                          <span className="text-red-500"> * </span>
                        </label>
                        <Controller
                          name="policyType"
                          control={control}
                          rules={{ required: 'Policy type is required' }}
                          render={({ field, fieldState: { error } }) => (
                            <Select
                              {...field}
                              {...register('policyType')}
                              options={policyTypeOptions}
                              placeholder="Search and select..."
                              classNamePrefix="select"
                              isClearable
                              isLoading={isLoading}
                              onInputChange={handlePolicyTypeInputChange}
                              onFocus={() => fetchPolicyTypeOptions()}
                              noOptionsMessage={() => 'Type to search...'}
                              onChange={(policyType) => {
                                field.onChange(policyType)
                                handlePolicyTypeSelectionChange(policyType)
                                if (!policyType) {
                                  setValue('insurerCompany', null)
                                  setValue('insurerOffice', null)
                                  setInsurerCompanyOptions([])
                                  setInsurerOfficeOptions([])
                                }
                              }}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  minHeight: '44px',
                                  borderRadius: '0.75rem',
                                  borderWidth: '1px',
                                  marginBottom: '2px',
                                  borderColor: error
                                    ? 'red'
                                    : state.isFocused
                                      ? 'rgb(59, 130, 246)' // Tailwind blue-500 for focus
                                      : 'rgba(218, 222, 236, 1)', // Default gray color

                                  // Blue outline (ring) on focus, red if error
                                  boxShadow: state.isFocused
                                    ? `0 0 0 1px ${
                                        error ? 'red' : 'rgb(59, 130, 246)'
                                      }`
                                    : '',

                                  '&:hover': {
                                    borderColor: error
                                      ? 'red'
                                      : 'rgb(59, 130, 246)', // Hover blue if no error
                                  },
                                }),
                                placeholder: (base) => ({
                                  ...base,
                                  fontWeight: 'normal',
                                  fontSize: '14px',
                                  color: error ? 'red' : '#999', // Red placeholder on error
                                }),
                                menu: (base) => ({
                                  ...base,
                                  width: '100%', // Width of the dropdown menu
                                  maxHeight: '250px', // Max height for the dropdown menu
                                  borderRadius: '8px',
                                  zIndex: 49, // Set a higher zIndex to ensure the menu appears above other elements
                                }),
                                menuList: (base) => ({
                                  ...base,
                                  maxHeight: '250px', // Max height of the list container for scrolling
                                  padding: 0, // Optional padding for the menu list
                                  zIndex: 49, // Set zIndex for the menu list as well if needed
                                }),
                                singleValue: (base) => ({
                                  ...base,
                                  color: '#333', // Color for selected text
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  color: state.isFocused
                                    ? 'rgb(59, 130, 246)'
                                    : 'rgba(156, 163, 175, 1)', // Blue dropdown indicator on focus
                                  '&:hover': {
                                    color: 'rgb(59, 130, 246)',
                                  },
                                }),
                                indicatorSeparator: (base) => ({
                                  ...base,
                                  display: 'none', // Remove separator
                                }),
                              }}
                            />
                          )}
                        />
                        {errors.policyType && (
                          <div className="error-message text-right text-red-500">
                            {errors.policyType.message}
                          </div>
                        )}
                      </div>
                      <div className="col-span-2 lg:col-span-1">
                        <label className="mb-2 block text-sm font-bold text-navy-700">
                          Insurer Company
                          <span className="text-red-500"> * </span>
                        </label>
                        <Controller
                          name="insurerCompany"
                          control={control}
                          rules={{ required: 'Insurer company is required' }}
                          render={({ field, fieldState: { error } }) => (
                            <Select
                              {...field}
                              {...register('insurerCompany')}
                              options={insurerCompanyOptions}
                              placeholder="Search and select..."
                              classNamePrefix="select"
                              isClearable
                              isLoading={isLoading}
                              onInputChange={handleInsurerCompanyInputChange}
                              onFocus={() => fetchInsurerCompanyOptions()}
                              noOptionsMessage={() => 'Type to search...'}
                              onChange={(insurerCompany) => {
                                field.onChange(insurerCompany)
                                handleInsurerCompanySelectionChange(
                                  insurerCompany
                                )
                                if (!insurerCompany) {
                                  setValue('insurerOffice', null)
                                  setInsurerOfficeOptions([])
                                }
                              }}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  minHeight: '44px',
                                  borderRadius: '0.75rem',
                                  borderWidth: '1px',
                                  marginBottom: '2px',
                                  borderColor: error
                                    ? 'red'
                                    : state.isFocused
                                      ? 'rgb(59, 130, 246)' // Tailwind blue-500 for focus
                                      : 'rgba(218, 222, 236, 1)', // Default gray color

                                  // Blue outline (ring) on focus, red if error
                                  boxShadow: state.isFocused
                                    ? `0 0 0 1px ${
                                        error ? 'red' : 'rgb(59, 130, 246)'
                                      }`
                                    : '',

                                  '&:hover': {
                                    borderColor: error
                                      ? 'red'
                                      : 'rgb(59, 130, 246)', // Hover blue if no error
                                  },
                                }),
                                placeholder: (base) => ({
                                  ...base,
                                  fontWeight: 'normal',
                                  fontSize: '14px',
                                  color: error ? 'red' : '#999', // Red placeholder on error
                                }),
                                menu: (base) => ({
                                  ...base,
                                  width: '100%', // Width of the dropdown menu
                                  maxHeight: '250px', // Max height for the dropdown menu
                                  borderRadius: '8px',
                                  zIndex: 49, // Set a higher zIndex to ensure the menu appears above other elements
                                }),
                                menuList: (base) => ({
                                  ...base,
                                  maxHeight: '250px', // Max height of the list container for scrolling
                                  padding: 0, // Optional padding for the menu list
                                  zIndex: 49, // Set zIndex for the menu list as well if needed
                                }),
                                singleValue: (base) => ({
                                  ...base,
                                  color: '#333', // Color for selected text
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  color: state.isFocused
                                    ? 'rgb(59, 130, 246)'
                                    : 'rgba(156, 163, 175, 1)', // Blue dropdown indicator on focus
                                  '&:hover': {
                                    color: 'rgb(59, 130, 246)',
                                  },
                                }),
                                indicatorSeparator: (base) => ({
                                  ...base,
                                  display: 'none', // Remove separator
                                }),
                              }}
                            />
                          )}
                        />
                        {errors.insurerCompany && (
                          <div className="error-message text-right text-red-500">
                            {errors.insurerCompany.message}
                          </div>
                        )}
                      </div>
                      <div className="col-span-2 lg:col-span-1">
                        <label className="mb-2 block text-sm font-bold text-navy-700">
                          Insurer Office
                          <span className="text-red-500"> * </span>
                        </label>
                        <Controller
                          name="insurerOffice"
                          control={control}
                          rules={{ required: 'Insurer office is required' }}
                          render={({ field, fieldState: { error } }) => (
                            <Select
                              {...field}
                              options={insurerOfficeOptions}
                              {...register('insurerOffice')}
                              placeholder="Search and select..."
                              classNamePrefix="select"
                              isClearable
                              isLoading={isLoading}
                              onInputChange={handleInsurerOfficeInputChange}
                              onFocus={() => fetchInsurerOfficeOptions()}
                              noOptionsMessage={() => 'Type to search...'}
                              onChange={(insurerOffice) => {
                                field.onChange(insurerOffice)
                                handleInsurerOfficeSelectionChange(
                                  insurerOffice
                                )
                              }}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  minHeight: '44px',
                                  borderRadius: '0.75rem',
                                  borderWidth: '1px',
                                  marginBottom: '2px',
                                  borderColor: error
                                    ? 'red'
                                    : state.isFocused
                                      ? 'rgb(59, 130, 246)' // Tailwind blue-500 for focus
                                      : 'rgba(218, 222, 236, 1)', // Default gray color

                                  // Blue outline (ring) on focus, red if error
                                  boxShadow: state.isFocused
                                    ? `0 0 0 1px ${
                                        error ? 'red' : 'rgb(59, 130, 246)'
                                      }`
                                    : '',
                                  '&:hover': {
                                    borderColor: error
                                      ? 'red'
                                      : 'rgb(59, 130, 246)', // Hover blue if no error
                                  },
                                }),
                                placeholder: (base) => ({
                                  ...base,
                                  fontWeight: 'normal',
                                  fontSize: '14px',
                                  color: error ? 'red' : '#999', // Red placeholder on error
                                }),
                                menu: (base) => ({
                                  ...base,
                                  width: '100%', // Width of the dropdown menu
                                  maxHeight: '250px', // Max height for the dropdown menu
                                  borderRadius: '8px',
                                  zIndex: 49, // Set a higher zIndex to ensure the menu appears above other elements
                                }),
                                menuList: (base) => ({
                                  ...base,
                                  maxHeight: '250px', // Max height of the list container for scrolling
                                  padding: 0, // Optional padding for the menu list
                                  zIndex: 49, // Set zIndex for the menu list as well if needed
                                }),
                                singleValue: (base) => ({
                                  ...base,
                                  color: '#333', // Color for selected text
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  color: state.isFocused
                                    ? 'rgb(59, 130, 246)'
                                    : 'rgba(156, 163, 175, 1)', // Blue dropdown indicator on focus
                                  '&:hover': {
                                    color: 'rgb(59, 130, 246)',
                                  },
                                }),
                                indicatorSeparator: (base) => ({
                                  ...base,
                                  display: 'none', // Remove separator
                                }),
                              }}
                            />
                          )}
                        />
                        {errors.insurerOffice && (
                          <div className="error-message text-right text-red-500">
                            {errors.insurerOffice.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <h4 className="text-md mt-2 mb-2 font-bold text-gray-700">
                      Policy Period
                    </h4>
                    <div className="mt-2 grid grid-cols-2 gap-3">
                      <div className="col-span-2 lg:col-span-1 mb-2">
                        <label className="block text-sm font-bold mb-2 text-navy-700">
                          From <span className="text-red-500"> * </span>
                        </label>
                        <Controller
                          name="fromDate"
                          control={control}
                          defaultValue="" // Set a default value to avoid uncontrolled-to-controlled warning
                          rules={{ required: 'Policy start date is required' }}
                          render={({ field, fieldState: { error } }) => (
                            <div>
                              <input
                                {...field}
                                type="date"
                                className={`w-full border rounded-xl p-2 ${error ? 'border-red-500 text-red-500 placeholder:text-red-500' : 'border-gray-300'}`}
                                onChange={(event) => {
                                  const selectedDate = event.target.value
                                  field.onChange(selectedDate) // Update form state
                                  handleFromDateSelectionChange(selectedDate) // Trigger custom handler
                                }}
                              />
                              {error && (
                                <div className="error-message text-right text-red-500 mt-2">
                                  {error.message}
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </div>

                      <div className="col-span-2 lg:col-span-1 mb-2">
                        <label className="block text-sm font-bold mb-2 text-navy-700">
                          To <span className="text-red-500"> * </span>
                        </label>
                        <Controller
                          name="toDate"
                          control={control}
                          defaultValue="" // Set a default value to avoid uncontrolled-to-controlled warning
                          rules={{ required: 'Policy end date is required' }}
                          render={({ field, fieldState: { error } }) => (
                            <div>
                              <input
                                {...field}
                                type="date"
                                className={`w-full border rounded-xl p-2 ${error ? 'border-red-500 text-red-500 placeholder:text-red-500' : 'border-gray-300'}`}
                                onChange={(event) => {
                                  const selectedDate = event.target.value
                                  field.onChange(selectedDate) // Update form state
                                  handleToDateSelectionChange(selectedDate) // Trigger custom handler
                                }}
                              />
                              {error && (
                                <div className="error-message text-right text-red-500 mt-2">
                                  {error.message}
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </div>

                      <div className="col-span-2 lg:col-span-1 mb-2">
                        <label className="block text-sm font-bold mb-2 text-navy-700">
                          Upload Document
                        </label>
                        <div className="flex items-center space-x-4">
                          <div className="mb-4 mt-4">
                            <div className="flex space-x-4">
                              <img
                                src={previewFile || noImg}
                                alt="Policy Preview"
                                className="rounded-lg border border-gray-300"
                                style={{ maxWidth: '95px', maxHeight: '95px' }}
                              />
                            </div>
                          </div>
                          <input
                            type="file"
                            id="file-upload-logo"
                            ref={policyDocumentRef} // Attach ref to the input
                            className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
                            accept="image/*" // Only accept image files
                            onChange={handlePolicyDocumentFileChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Footer section --> */}
                  <div className="bg-gray-50 px-2 py-2 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      onClick={closePopup}
                      className="inline-flex w-full justify-center rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-400 sm:ml-3 sm:w-auto"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-brand-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-indigo-200 hover:bg-brand-300 focus:outline-none focus:ring focus:ring-indigo-200 sm:mt-0 sm:w-auto"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {documentList.length > 0 && (
        <div className="mt-2 min-h-[100px] w-full">
          <table className="w-full">
            <thead className="w-full">
              <tr className="items-center border-b border-gray-200 dark:!border-white/10">
                <th className="px-4 pb-[10px] text-left text-xs text-gray-600">
                  <div className="flex items-center">Policy Name</div>
                </th>
                <th className="px-4 pb-[10px] text-left text-xs text-gray-600">
                  <div className="flex items-center">Action</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {documentList.map((doc, rowIndex) => {
                return (
                  <tr
                    className="border-b border-gray-200 dark:!border-white/10"
                    key={rowIndex}
                  >
                    <td className="px-4">{doc.name}</td>
                    <td className="px-4">
                      <div className="justify-left flex">
                        <div className="w-max rounded-xl py-1 text-sm mr-2">
                          <TooltipHorizon
                            extra="border border-gray-200"
                            trigger={
                              <a
                                href={readFileBasedOnId(doc.documentImage)}
                                download="document"
                                className="flex cursor-pointer items-center rounded-full border p-1 font-bold  text-[#000] hover:font-medium hover:text-black dark:text-white"
                              >
                                <span>
                                  <MdCloudDownload className="text-xl" />
                                </span>
                              </a>
                            }
                            content="Download Document"
                            placement="top"
                          />
                        </div>
                        <div className="w-max rounded-xl py-1 text-sm">
                          <TooltipHorizon
                            extra="border border-gray-200"
                            trigger={
                              <p
                                className="flex cursor-pointer items-center rounded-full border p-1 font-bold  text-[#000] hover:font-medium hover:text-black dark:text-white"
                                onClick={() => handleEditClick(doc)}
                              >
                                <span>
                                  <MdEdit className="text-xl" />
                                </span>
                              </p>
                            }
                            content="Edit"
                            placement="top"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
      <div className="mt-4 flex w-full justify-end">
        <button
          className="mt-2 w-40 cursor-pointer rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 md:text-base"
          type="button"
          onClick={onDocumentFormSubmit}
        >
          <span>{id ? 'Update' : 'Save'}</span>
        </button>
        <button
          className="ml-2 mt-2 w-40 cursor-pointer rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200 hover:bg-gray-600 active:bg-gray-700 md:text-base"
          type="button"
          onClick={() => navigate(`/insurance/policy-document-list`)}
        >
          <span>Cancel</span>
        </button>
      </div>
    </Card>
  )
}

export default AddPolicyDocument
