import React, { useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
/**
 * React component for an input field.
 *
 * @param {Object} props - the properties passed to the component
 * @return {JSX.Element} the input field component
 */
function InputField(props) {
  const {
    label,
    id,
    extra,
    placeholder,
    variant,
    state,
    disabled,
    noBorder,
    onClick,
    onChange,
    onKeyUp,
    registrationProps,
    isFieldRequired,
    hasToggle,
    type,
    readOnly,
  } = props

  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const inputType = hasToggle
    ? isPasswordVisible
      ? 'text'
      : 'password'
    : type || 'text'

  const toggleIcon = () => {
    setIsPasswordVisible((prev) => !prev)
  }

  return (
    <div className={`relative ${extra}`}>
      <label
        htmlFor={id}
        className={`text-sm text-navy-700 dark:text-white ${
          variant === 'auth' ? 'ml-1.5 font-medium' : 'font-bold'
        }`}
      >
        {label} {isFieldRequired && <span className="text-red-500">*</span>}
      </label>
      <input
        onClick={onClick}
        onChange={onChange}
        onKeyUp={onKeyUp}
        disabled={disabled}
        type={inputType}
        step={props.step || 'any'}
        id={id}
        autoComplete="off"
        placeholder={placeholder}
        readOnly={readOnly}
        className={`mt-2 flex h-11 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 active:border-blue-500 ${
          (disabled === true || readOnly === true) && state !== 'error'
            ? '!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]'
            : disabled === true && state === 'error'
              ? 'border-red-500 text-red-500 placeholder:text-red-500 !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]'
              : state === 'error'
                ? 'border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400'
                : state === 'success'
                  ? 'border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400'
                  : 'border-gray-200 dark:!border-white/10 dark:text-white'
        } ${noBorder ? 'w-11/12 border-none' : ''}`}
        {...registrationProps}
      />

      {hasToggle && (
        <span
          className="absolute right-3 top-10 cursor-pointer"
          onClick={toggleIcon}
        >
          {isPasswordVisible ? (
            <FaEye className="h-6 w-6  text-black" />
          ) : (
            <FaEyeSlash className="h-6 w-6 text-black" />
          )}
        </span>
      )}
    </div>
  )
}

export default InputField
