import React, { useRef, useState, useEffect } from 'react'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import Card from 'components/card'
import { useForm, Controller } from 'react-hook-form'
import { useToaster } from 'common/Toaster'
import CrawfordConstants from 'common/config/crawfordConstants'
import { useSpinner } from 'common/SpinnerLoader'
import { useParams, useNavigate } from 'react-router-dom'
import InputField from 'components/fields/InputField'
import Select from 'react-select'
import noImg from 'assets/img/layers/no-image.png'
import SwitchWithText from 'components/switch-with-text'
import { readFileBasedOnId } from 'common/commonFunction'
const apiService = ApiCaller()
const { TOAST } = CrawfordConstants

/**
 * Renders a form to add or edit a district.
 *
 * @return {JSX.Element} The rendered form component.
 */
const AddCompany = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      CompanyName: '',
      ShortName: '',
      CompanyCode: '',
      Phone: '',
      Email: '',
      City: '',
      BankNameIndian: '',
      BankNameForeign: '',
      FinancialYear: '',
      ServiceTaxNumber: '',
      PAN: '',
      CIN: '',
      HSN: '',
      SurveyLicenceNo: '',
      Services: '',
      Website: '',
      Currency: '',
      GSTINByState: '',
    },
  })
  const [isCompanyActive, setCompanyStatus] = useState(false)
  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()
  const [options, setOptions] = useState([])
  const [currencyOptions, setCurrencyOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [bankOptions, setBankOptions] = useState([])
  const [logoFile, setLogoFile] = useState(null)
  const [stampFile, setStampFile] = useState(null)
  const [previewLogo, setLogoPreview] = useState(null)
  const [previewStamp, setStampPreview] = useState(null)
  const [GSTINByState, setGSTINByState] = useState([])

  const fileLogoRef = useRef(null) // Create a ref for the file input
  const fileStampRef = useRef(null) // Create a ref for the file input

  const financialYearOptions = [
    {
      label: 'January-December',
      value: 'January-December',
    },
    {
      label: 'April-March',
      value: 'April-March',
    },
  ]

  const changeCompanyStatus = () => {
    setCompanyStatus(!isCompanyActive)
  }

  // Fetch data from API
  const fetchOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.COMPANY_CITY_LIST
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: {},
        fields: [],
        page: inputValue ? 1 : 0,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  // Handle input change and trigger API call
  const handleInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchOptions(inputValue)
    }
  }

  const handleSelectionChange = (City) => {
    if (!City) {
      fetchOptions() // Call the function to perform API request
      setOptions([])
    }
  }

  // Fetch data from API
  const fetchCurrencyOptions = async (inputValue = '') => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.CURRENCY_LIST
      const payload = {
        keyword: inputValue,
        filter: {},
        fields: [],
        page: 1,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setCurrencyOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  // Handle input change and trigger API call
  const handleCurrencyInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchCurrencyOptions(inputValue)
    }
  }

  const handleCurrencySelectionChange = (City) => {
    if (!City) {
      fetchCurrencyOptions() // Call the function to perform API request
      setCurrencyOptions([])
    }
  }

  // Fetch data from API
  const fetchBankOptions = async (inputValue = '') => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.BANK_LIST
      const payload = {
        keyword: inputValue,
        filter: {},
        fields: [],
        page: 1,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setBankOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  // Handle input change and trigger API call
  const handleBankIndiaInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchBankOptions(inputValue)
    }
  }

  const handleBankIndiaSelectionChange = (BankNameIndia) => {
    if (!BankNameIndia) {
      fetchBankOptions() // Call the function to perform API request
    }
  }

  // Handle input change and trigger API call
  const handleBankForeignInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchBankOptions(inputValue)
    }
  }

  const handleBankForeignSelectionChange = (BankNameForeign) => {
    if (!BankNameForeign) {
      fetchBankOptions() // Call the function to perform API request
    }
  }

  // Fetch data from API
  const getGSTState = async () => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.COMPANY_GST_LIST
      const payload = {
        GSTINByState: [],
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        setGSTINByState(response.data.data || [])
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleGSTChange = (index, value) => {
    const updatedGSTIN = [...GSTINByState]
    updatedGSTIN[index].GST = value
    setGSTINByState(updatedGSTIN)
  }

  /**
   * Handles the form submission for creating a new district.
   *
   * @async
   * @function onFormSubmit
   * @param {Object} formData - The form data containing the district details.
   * @param {string} formData.country - The id of the country.
   * @param {string} formData.zone - The id of the zone.
   * @param {string} formData.state - The id of the state.
   * @param {string} formData.state - The id of the district.
   * @param {string} formData.name - The name of the city.
   * @param {string} formData.stdCode - The stdCode of the city.
   * @param {string} formData.timezone - The timezone of the city.
   * @returns {Promise<void>} A promise that resolves when the form submission is complete.
   */
  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.COMPANY_CREATE
      const payload = {
        _id: id ? id : undefined,
        name: formData.CompanyName,
        shortName: formData.ShortName,
        companyCode: formData.CompanyCode,
        logo: logoFile || undefined,
        stamp: stampFile || undefined,
        bank: formData.BankNameIndian.value,
        bank2: formData.BankNameForeign.value,
        financialYear: formData.FinancialYear.value,
        serviceTax: formData.ServiceTaxNumber,
        pan: formData.PAN,
        cin: formData.CIN,
        hsn: formData.HSN,
        tan: formData.SurveyLicenceNo,
        services: formData.Services,
        website: formData.Website,
        city: formData.City.value,
        phone: formData.Phone,
        email: formData.Email,
        GSTINByState: GSTINByState,
        currency: formData.Currency.value,
        status: isCompanyActive,
      }

      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        addToast({
          title: `Company "${formData.CompanyName}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        navigate(`/company-setup/company-list`)
      } else {
        addToast({
          title: `Company creation failed.`,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  /**
   * Fetches the details of a specific company and sets the form values.
   *
   * @async
   * @function getCompanyDetails
   * @returns {Promise<void>} A promise that resolves when the company details are fetched and the form values are set.
   */
  const getCompanyDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.COMPANY_DETAIL.replace(':id', id)
      const payload = {
        _id: id,
        checkRoles: false,
        accessOf: ['companyEdit'],
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data === 'Access Denied') {
        addToast({
          title: 'Access Denied',
          type: 'error',
        })
        navigate(`/company-setup/company-list`)
      } else if (response?.data?.value) {
        const companyData = response?.data?.data
        setValue('CompanyName', companyData?.name)
        setValue('ShortName', companyData?.shortName)
        setValue('CompanyCode', companyData?.companyCode)
        setValue('Phone', companyData?.phone)
        setValue('Email', companyData?.email)
        setValue('ServiceTaxNumber', companyData?.serviceTax)
        setValue('PAN', companyData?.pan)
        setValue('CIN', companyData?.cin)
        setValue('HSN', companyData?.hsn)
        setValue('SurveyLicenceNo', companyData?.tan)
        setValue('Services', companyData?.services)
        setValue('Website', companyData?.website)
        setCompanyStatus(companyData?.status)
        setValue(
          'City',
          {
            label: companyData?.city?.name,
            value: companyData?.city?._id,
          } || {}
        )
        setValue(
          'BankNameIndian',
          {
            label: companyData?.bank?.name,
            value: companyData?.bank?._id,
          } || {}
        )
        setValue(
          'BankNameForeign',
          {
            label: companyData?.bank2?.name,
            value: companyData?.bank2?._id,
          } || {}
        )
        setValue(
          'FinancialYear',
          {
            label: companyData?.financialYear,
            value: companyData?.financialYear,
          } || {}
        )
        setValue(
          'Currency',
          {
            label: companyData?.currency?.name,
            value: companyData?.currency?._id,
          } || {}
        )
        setLogoPreview(readFileBasedOnId(companyData?.logo))
        setStampPreview(readFileBasedOnId(companyData?.stamp))
        if (companyData?.GSTINByState.length) {
          setGSTINByState(companyData?.GSTINByState)
        }
      } else {
        addToast({
          title: 'Some Error Occured',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error verifying token:', error)
    } finally {
      hideSpinner()
    }
  }

  const handleCancel = () => {
    navigate(`/company-setup/company-list`)
  }

  useEffect(() => {
    fetchOptions()
    fetchCurrencyOptions()
    fetchBankOptions()
    getGSTState()
    if (id) {
      getCompanyDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const uploadFile = async (file, fileFor) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.UPLOAD_IMAGE
      const data = new FormData()
      data.append('file', file)
      const response = await apiService.apiCall('post', apiUrl, data)
      if (response?.data?.value) {
        if (fileFor === 'logo') {
          setLogoFile(response?.data?.data[0])
        } else {
          setStampFile(response?.data?.data[0])
        }
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleLogoFileChange = (e) => {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      setLogoPreview(URL.createObjectURL(selectedFile)) // Create a preview URL
      uploadFile(selectedFile, 'logo')
    }
  }

  const handleStampFileChange = (e) => {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      setStampPreview(URL.createObjectURL(selectedFile)) // Create a preview URL
      uploadFile(selectedFile, 'stamp')
    }
  }

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="mt-7 grid grid-cols-2 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <p className="text-xl font-bold text-navy-700">
              {id ? 'Edit' : 'Add'} Company
            </p>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <div className="flex w-full justify-end">
              <SwitchWithText
                textLeft="OFF"
                textRight="ON"
                checked={isCompanyActive}
                onChange={changeCompanyStatus}
              />
            </div>
          </div>
        </div>
        <div className="mt-7 grid grid-cols-2 gap-3">
          <div className="col-span-2 lg:col-span-2">
            <h4 className="text-md mb-4 font-bold text-gray-700">
              Company Info
            </h4>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="Company Name"
              id="CompanyName"
              type="text"
              placeholder="Enter company name"
              registrationProps={register('CompanyName', {
                required: 'Company name is required',
              })}
              isFieldRequired={true}
              state={errors.CompanyName && 'error'}
            />
            <div className="error-message text-right">
              {errors.CompanyName?.message}
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="Short Name"
              id="ShortName"
              type="text"
              placeholder="Enter short name"
              registrationProps={register('ShortName', {
                required: 'Short name is required',
              })}
              isFieldRequired={true}
              state={errors.ShortName && 'error'}
            />
            <div className="error-message text-right">
              {errors.ShortName?.message}
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="Company Code"
              id="CompanyCode"
              type="text"
              placeholder="Enter company code"
              registrationProps={register('CompanyCode', {
                required: 'Company code is required',
              })}
              isFieldRequired={true}
              state={errors.CompanyCode && 'error'}
            />
            <div className="error-message text-right">
              {errors.CompanyCode?.message}
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="Phone"
              id="Phone"
              type="text"
              placeholder="Enter phone"
              registrationProps={register('Phone', {
                required: 'Phone is required',
              })}
              isFieldRequired={true}
              state={errors.Phone && 'error'}
            />
            <div className="error-message text-right">
              {errors.Phone?.message}
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="Email"
              id="Email"
              type="text"
              placeholder="Enter email"
              registrationProps={register('Email', {
                required: 'Email is required',
              })}
              isFieldRequired={true}
              state={errors.Email && 'error'}
            />
            <div className="error-message text-right">
              {errors.Email?.message}
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 mt-1 block text-sm font-bold">City</label>
            <Controller
              name="City"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={options}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handleInputChange}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(City) => {
                    field.onChange(City)
                    handleSelectionChange(City) // Handle API call
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      minHeight: '44px',
                      borderRadius: '0.75rem',
                      borderWidth: '1px',
                      borderColor: state.isFocused
                        ? 'rgb(59, 130, 246)'
                        : 'rgba(218, 222, 236, 1)', // Blue border on focus, light gray otherwise
                      boxShadow: state.isFocused
                        ? '0 0 0 1px rgba(59, 130, 246, 1)'
                        : '', // Blue outline on focus
                      '&:hover': {
                        borderColor: 'rgb(59, 130, 246)', // Blue on hover
                      },
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      color: state.isFocused
                        ? 'rgb(59, 130, 246)'
                        : 'rgb(156, 163, 175)', // Blue indicator on focus, gray otherwise
                      '&:hover': {
                        color: 'rgb(59, 130, 246)', // Blue on hover
                      },
                    }),
                    indicatorSeparator: (base) => ({
                      ...base,
                      display: 'none', // Remove separator for cleaner design
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      color: '#999', // Placeholder color
                      fontSize: '14px',
                    }),
                    singleValue: (base) => ({
                      ...base,
                      color: '#333', // Color of selected text
                    }),
                  }}
                />
              )}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label
              className="mb-2 mt-4 block text-sm font-bold"
              htmlFor="file-upload-logo"
            >
              Upload Logo
            </label>
            <div className="flex items-center space-x-4">
              <div className="mb-4 mt-4">
                <div className="flex space-x-4">
                  <img
                    src={previewLogo || noImg}
                    alt="Logo Preview"
                    className="rounded-lg border border-gray-300"
                    style={{ maxWidth: '150px', maxHeight: '150px' }}
                  />
                </div>
              </div>
              <input
                type="file"
                id="file-upload-logo"
                ref={fileLogoRef} // Attach ref to the input
                className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
                accept="image/*" // Only accept image files
                onChange={handleLogoFileChange}
              />
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label
              className="mb-2 mt-4 block text-sm font-bold"
              htmlFor="file-upload-stamp"
            >
              Upload Stamp
            </label>
            <div className="flex items-center space-x-4">
              {/* Image Preview */}
              <div className="mb-4 mt-4">
                <div className="flex items-center space-x-4">
                  <img
                    src={previewStamp || noImg}
                    alt="Logo Preview"
                    className="rounded-lg border border-gray-300"
                    style={{ maxWidth: '150px', maxHeight: '150px' }}
                  />
                </div>
              </div>
              <input
                type="file"
                id="file-upload-logo"
                ref={fileStampRef} // Attach ref to the input
                className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
                accept="image/*" // Only accept image files
                onChange={handleStampFileChange}
              />
            </div>
          </div>
          <div className="col-span-2 lg:col-span-2">
            <h4 className="text-md mb-4 mt-4 font-bold text-gray-700">
              Bank Info
            </h4>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold">
              Bank Name Indian
            </label>
            <Controller
              name="BankNameIndian"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={bankOptions}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handleBankIndiaInputChange}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(BankNameIndian) => {
                    field.onChange(BankNameIndian)
                    handleBankIndiaSelectionChange(BankNameIndian) // Call a function to handle API call
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      minHeight: '44px',
                      borderRadius: '0.75rem',
                      borderWidth: '1px',
                      borderColor: state.isFocused
                        ? 'rgb(59, 130, 246)'
                        : 'rgba(218, 222, 236, 1)', // Blue border on focus, light gray otherwise
                      boxShadow: state.isFocused
                        ? '0 0 0 1px rgba(59, 130, 246, 1)'
                        : '', // Blue outline on focus
                      '&:hover': {
                        borderColor: 'rgb(59, 130, 246)', // Blue on hover
                      },
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      color: state.isFocused
                        ? 'rgb(59, 130, 246)'
                        : 'rgb(156, 163, 175)', // Blue indicator on focus, gray otherwise
                      '&:hover': {
                        color: 'rgb(59, 130, 246)', // Blue on hover
                      },
                    }),
                    indicatorSeparator: (base) => ({
                      ...base,
                      display: 'none', // Remove separator for cleaner design
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      color: '#999', // Placeholder color
                      fontSize: '14px',
                    }),
                    singleValue: (base) => ({
                      ...base,
                      color: '#333', // Color of selected text
                    }),
                  }}
                />
              )}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold">
              Bank Name Foreign
            </label>
            <Controller
              name="BankNameForeign"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={bankOptions}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handleBankForeignInputChange}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(BankNameForeign) => {
                    field.onChange(BankNameForeign)
                    handleBankForeignSelectionChange(BankNameForeign) // Call a function to handle API call
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      minHeight: '44px',
                      borderRadius: '0.75rem',
                      borderWidth: '1px',
                      borderColor: state.isFocused
                        ? 'rgb(59, 130, 246)'
                        : 'rgba(218, 222, 236, 1)', // Blue border on focus, light gray otherwise
                      boxShadow: state.isFocused
                        ? '0 0 0 1px rgba(59, 130, 246, 1)'
                        : '', // Blue outline on focus
                      '&:hover': {
                        borderColor: 'rgb(59, 130, 246)', // Blue on hover
                      },
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      color: state.isFocused
                        ? 'rgb(59, 130, 246)'
                        : 'rgb(156, 163, 175)', // Blue indicator on focus, gray otherwise
                      '&:hover': {
                        color: 'rgb(59, 130, 246)', // Blue on hover
                      },
                    }),
                    indicatorSeparator: (base) => ({
                      ...base,
                      display: 'none', // Remove separator for cleaner design
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      color: '#999', // Placeholder color
                      fontSize: '14px',
                    }),
                    singleValue: (base) => ({
                      ...base,
                      color: '#333', // Color of selected text
                    }),
                  }}
                />
              )}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 mt-2 block text-sm font-bold">
              Financial Year
            </label>
            <Controller
              name="FinancialYear"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={financialYearOptions}
                  placeholder="Select financial year"
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      minHeight: '44px',
                      borderRadius: '0.75rem',
                      borderWidth: '1px',
                      borderColor: state.isFocused
                        ? 'rgb(59, 130, 246)'
                        : 'rgba(218, 222, 236, 1)', // Blue border on focus, light gray otherwise
                      boxShadow: state.isFocused
                        ? '0 0 0 1px rgba(59, 130, 246, 1)'
                        : '', // Blue outline on focus
                      '&:hover': {
                        borderColor: 'rgb(59, 130, 246)', // Blue on hover
                      },
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      color: state.isFocused
                        ? 'rgb(59, 130, 246)'
                        : 'rgb(156, 163, 175)', // Blue indicator on focus, gray otherwise
                      '&:hover': {
                        color: 'rgb(59, 130, 246)', // Blue on hover
                      },
                    }),
                    indicatorSeparator: (base) => ({
                      ...base,
                      display: 'none', // Remove separator for cleaner design
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      color: '#999', // Placeholder color
                      fontSize: '14px',
                    }),
                    singleValue: (base) => ({
                      ...base,
                      color: '#333', // Color of selected text
                    }),
                  }}
                />
              )}
            />
          </div>
          <div className="col-span-2 lg:col-span-2">
            <h4 className="text-md mb-4 font-bold text-gray-700">Others</h4>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="Service Tax Number"
              id="ServiceTaxNumber"
              type="text"
              placeholder="Enter service tax number"
              registrationProps={register('ServiceTaxNumber')}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="PAN"
              id="PAN"
              type="text"
              placeholder="Enter PAN"
              registrationProps={register('PAN')}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="CIN"
              id="CIN"
              type="text"
              placeholder="Enter CIN"
              registrationProps={register('CIN')}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="HSN"
              id="HSN"
              type="text"
              placeholder="Enter HSN"
              registrationProps={register('HSN')}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="Survey Licence No"
              id="SurveyLicenceNo"
              type="text"
              placeholder="Enter survey licence no"
              registrationProps={register('SurveyLicenceNo')}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="Services"
              id="Services"
              type="text"
              placeholder="Enter services"
              registrationProps={register('Services')}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="Website"
              id="Website"
              type="text"
              placeholder="Enter website"
              registrationProps={register('Website')}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold">Currency</label>
            <Controller
              name="Currency"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={currencyOptions}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handleCurrencyInputChange}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(Currency) => {
                    field.onChange(Currency)
                    handleCurrencySelectionChange(Currency) // Call a function to handle API call
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      minHeight: '44px',
                      borderRadius: '0.75rem',
                      borderWidth: '1px',
                      borderColor: state.isFocused
                        ? 'rgb(59, 130, 246)'
                        : 'rgba(218, 222, 236, 1)', // Blue border on focus, light gray otherwise
                      boxShadow: state.isFocused
                        ? '0 0 0 1px rgba(59, 130, 246, 1)'
                        : '', // Blue outline on focus
                      '&:hover': {
                        borderColor: 'rgb(59, 130, 246)', // Blue on hover
                      },
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      color: state.isFocused
                        ? 'rgb(59, 130, 246)'
                        : 'rgb(156, 163, 175)', // Blue indicator on focus, gray otherwise
                      '&:hover': {
                        color: 'rgb(59, 130, 246)', // Blue on hover
                      },
                    }),
                    indicatorSeparator: (base) => ({
                      ...base,
                      display: 'none', // Remove separator for cleaner design
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      color: '#999', // Placeholder color
                      fontSize: '14px',
                    }),
                    singleValue: (base) => ({
                      ...base,
                      color: '#333', // Color of selected text
                    }),
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="mt-7 grid grid-cols-1 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <h4 className="text-md mb-4 font-bold text-gray-700">
              GST Details
            </h4>
          </div>
          <table className="w-full table-auto border-collapse rounded-2xl border border-blue-300">
            <thead>
              <tr className="bg-blue-100">
                <th className="border border-blue-300 px-2 py-2 text-left">
                  State
                </th>
                <th
                  className="border border-blue-300 px-2 py-2 text-left"
                  style={{ width: '200px' }}
                >
                  GST #
                </th>
              </tr>
            </thead>
            <tbody>
              {GSTINByState.map((GSTIN, index) => (
                <tr key={index}>
                  <td className="border border-gray-300 px-2 py-2">
                    {GSTIN.state?.name || 'No State Name'}
                  </td>
                  <td className="border border-gray-300 px-2 py-2">
                    <input
                      type="text"
                      className="form-control w-full rounded border border-gray-300 p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 active:border-blue-500"
                      value={GSTIN.GST || ''}
                      onChange={(e) => handleGSTChange(index, e.target.value)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* <div className="form-elements-sec">
            <div className="widget-title">
              <h3 className="mb-4 text-xl font-semibold">GST Details</h3>
            </div>
            
          </div> */}
        </div>

        {/* Button aligned to the right */}
        <div className="mt-4 flex w-full justify-end">
          <button
            className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
          hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base`}
            type="submit"
          >
            <span>{id ? 'Update' : 'Save'}</span>
          </button>

          <button
            className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
          hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base`}
            type="button"
            onClick={handleCancel}
          >
            <span>Cancel</span>
          </button>
        </div>
      </form>
    </Card>
  )
}

export default AddCompany
