import React, { useState } from 'react'
import './hierarchy.css'

const orgData = {
  id: 1,
  name: 'Bobby McBoss',
  title: 'Head of Doing Nothing',
  location: 'Couchland',
  role: 'CEO ',
  initials: 'BM',
  children: [
    {
      id: 2,
      name: 'Sally Scribbles',
      title: 'Drawer of Squiggly Lines',
      location: 'Artland',
      role: 'UI Designer Extraordinaire',
      initials: 'SS',
      children: [],
    },
    {
      id: 3,
      name: 'Captain Codebeard',
      title: 'Chief Bug Creator',
      location: 'Codeville',
      role: 'Tech Lead ',
      initials: 'CC',
      children: [
        {
          id: 4,
          name: 'Noodle Coder',
          title: 'Junior Syntax Wizard',
          location: 'Indentation City',
          role: 'Frontend Developer',
          initials: 'NC',
          children: [
            {
              id: 7,
              name: 'Bugsy Malone',
              title: 'Bug Finder & Creator',
              location: 'Debugistan',
              role: 'Quality Assurance ',
              initials: 'BM',
              children: [],
            },
            {
              id: 8,
              name: 'Crash Bandicode',
              title: 'Backend Destroyer',
              location: 'Errorville',
              role: 'Backend Developer ',
              initials: 'CB',
              children: [],
            },
          ],
        },
        {
          id: 5,
          name: 'Dev Oops',
          title: 'Keeper of Broken Servers',
          location: 'Cloudtopia',
          role: 'DevOps Engineer',
          initials: 'DO',
          children: [
            {
              id: 9,
              name: 'Patchy McFixit',
              title: 'Fixer of Things That Shouldn’t Break',
              location: 'Testopia',
              role: 'QA Engineer ',
              initials: 'PM',
              children: [],
            },
            {
              id: 10,
              name: 'Cloudy McFloat',
              title: 'King of the Cloud',
              location: 'Skytopia',
              role: 'Cloud Architect ',
              initials: 'CM',
              children: [],
            },
          ],
        },
      ],
    },
    {
      id: 6,
      name: 'Pixel Patty',
      title: 'Maker of Pretty Things',
      location: 'Photoshopville',
      role: 'Graphic Designer',
      initials: 'PP',
      children: [],
    },
  ],
}

const getInitials = (name) => {
  return name
    .split(' ')
    .map((word) => word[0])
    .join('')
    .toUpperCase()
}
const Card = ({ data, expandedNodes, toggleExpand }) => {
  return (
    <ul>
      <div className="flex justify-center ">
        {data.map((node) => (
          <li key={node.id}>
            <div className=" card">
              <div className="image w-[54px] h-[54px] pt-[20px]">
                {node.image ? (
                  <img
                    src={node.initials}
                    alt={node.name}
                    className="rounded-full w-[54px] h-[54px] mx-auto mb-2"
                  />
                ) : (
                  <div className="bg-gray-300 text-white rounded-full text-[30px] w-[75px] h-[75px] flex items-center justify-center mx-auto mb-2">
                    {node.initials || getInitials(node.name)}
                  </div>
                )}
                <div className="card-body">
                  <h3 className="font-semibold text-2xl ">{node.name}</h3>
                  <p className="text-blue-600 text-lg mt-1">{node.title}</p>
                  <p className="text-blue-600 text-lg mt-2">{node.location}</p>
                </div>
                <div className="card-footer ">
                  <p className="text-blue-600 text-xl font-semibold">
                    {node.role}
                  </p>
                  {node.children && node.children.length > 0 && (
                    <button
                      onClick={() => toggleExpand(node.id)}
                      className="absolute flex justify-center items-center bottom-0 left-1/2 transform -translate-x-1/2 translate-y-6 bg-blue-500 text-white rounded-full w-8 h-8 text-2xl"
                    >
                      {expandedNodes[node.id] ? (
                        '-'
                      ) : (
                        <>{node.children.length}</>
                      )}
                    </button>
                  )}
                </div>
              </div>
              <div className="card-body"></div>

              <p className="text-blue-600 text-xs font-semibold">{node.role}</p>
            </div>
            {node.children?.length > 0 && expandedNodes[node.id] && (
              <Card
                data={node.children}
                expandedNodes={expandedNodes}
                toggleExpand={toggleExpand}
              />
            )}
          </li>
        ))}
      </div>
    </ul>
  )
}
const Chart = () => {
  const [expandedNodes, setExpandedNodes] = useState({})
  const toggleExpand = (id) => {
    setExpandedNodes((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }
  return (
    <div className="org-tree m-10 min-h-screen overflow-x-auto  bg-white p-4">
      {orgData && (
        <Card
          data={[orgData]}
          expandedNodes={expandedNodes}
          toggleExpand={toggleExpand}
        />
      )}
    </div>
  )
}

export default Chart
