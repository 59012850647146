import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'

const apiService = ApiCaller()
const AssignInvestigator = () => {
  const [investigatorsNearestToHome, setInvestigatorsNearestToHome] = useState(
    []
  ) // State for fetched data
  const [investigatorsNearestToOffice, setInvestigatorsNearestToOffice] =
    useState([]) // State for fetched data
  const [assignment, setAssignment] = useState({})
  const [selectInvestigators, setSelectInvestigators] = useState([])
  const { id } = useParams()

  const fetchAssignmentById = async () => {
    try {
      const apiUrl = apiConfig.ASSIGNMENT_DETAIL // getOneAssignment API Call
      const payload = {
        _id: id,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.data) {
        setAssignment(response.data.data)
      }
    } catch (error) {
      console.error('Error fetching assignment:', error)
    }
  }

  const fetchNearestInvestigators = async (inputValue) => {
    try {
      const apiUrl = apiConfig.NEAREST_INVESTIGATOR_LIST // Get Nearest Investigator API Call
      const payload = {
        ...assignment,
        type: 'investigator',
        showAll: false,
        accessToken: process.env.REACT_APP_GOOGLE_KEY,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      console.log('Nearest', response.data.data)
      if (response?.data?.data) {
        setInvestigatorsNearestToHome(response.data.data.nearestToHome)
        setInvestigatorsNearestToOffice(response.data.data.nearestToOffice)
      }
    } catch (error) {
      console.error('Error fetching investigators:', error)
    }
  }

  // Run `fetchAssignmentById` on component mount
  useEffect(() => {
    fetchAssignmentById()
  }, [id])

  // Run `fetchNearestInvestigators` only when `assignment` is updated
  useEffect(() => {
    if (Object.keys(assignment).length) {
      fetchNearestInvestigators()
    }
  }, [assignment])

  const handleSearch = () => {
    // Add search functionality
    console.log('Search clicked')
  }

  const handleSelectInvestigator = (investigator) => {
    const investigatorId = investigator._id
    setSelectInvestigators((prevSelectedInvestigators) => {
      if (prevSelectedInvestigators.includes(investigatorId)) {
        // If already selected, remove from the array
        return prevSelectedInvestigators.filter(
          (selectedInvestigatorId) => selectedInvestigatorId !== investigatorId
        )
      } else {
        // If not selected, add to the array
        return [...prevSelectedInvestigators, investigatorId]
      }
    })
  }

  return (
    // className="min-h-screen"
    <div>
      {/* Assign Investigator Home Section */}
      <div className="container mx-auto mt-8 rounded-lg bg-white p-6 shadow-md">
        <h2 className="mb-6 text-2xl font-semibold">Investigator Home</h2>

        <div className="grid grid-cols-1 gap-4 p-4 sm:grid-cols-2 lg:grid-cols-5">
          {investigatorsNearestToHome.map((investigator, index) => (
            <div
              className={`rounded-lg border ${selectInvestigators.includes(investigator._id) ? 'border-blue-600' : 'border-gray-300'} bg-white shadow-md hover:cursor-pointer`}
              key={index}
              onClick={() => {
                handleSelectInvestigator(investigator)
              }}
            >
              {/* Profile Picture and Name */}
              <div className="flex items-center p-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-300">
                  <span className="text-lg font-semibold text-white">
                    {investigator.name.charAt(0).toUpperCase()}
                  </span>
                </div>
                <div className="ml-3">
                  <h3 className="text-lg font-semibold">{investigator.name}</h3>
                </div>
              </div>
              {/* Horizontal Line */}
              <hr className="border-t border-gray-300" />
              {/* Field of Expertise */}
              <div className="mb-4 mt-4 flex items-center px-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="h-5 w-5 text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 10h16M4 14h16M4 18h16"
                  />
                </svg>
                <span className="ml-2 text-sm text-gray-600">
                  {investigator.field ? investigator.field : 'Investigator'}
                </span>
              </div>

              {/* Distance */}
              <div className="mb-4 flex items-center px-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="h-5 w-5 text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 2c4.418 0 8 3.582 8 8 0 6-8 12-8 12S4 16 4 10c0-4.418 3.582-8 8-8z"
                  />
                </svg>
                <span className="ml-2 text-sm text-gray-600">
                  {investigator.distance} km
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Assign Investigator Office Section */}
      <div className="container mx-auto mt-8 rounded-lg bg-white p-6 shadow-md ">
        <h2 className="mb-6 text-2xl font-semibold">Investigator Office</h2>

        <div className="grid grid-cols-1 gap-4 p-4 sm:grid-cols-2 lg:grid-cols-5 ">
          {investigatorsNearestToOffice.map((investigator, index) => (
            <div
              className={`rounded-lg border ${selectInvestigators.includes(investigator._id) ? 'border-blue-600' : 'border-gray-300'} bg-white shadow-md hover:cursor-pointer`}
              key={index}
              onClick={() => handleSelectInvestigator(investigator)}
            >
              {/* Profile Picture and Name */}
              <div className="flex items-center p-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-300">
                  <span className="text-lg font-semibold text-white">
                    {investigator.name.charAt(0).toUpperCase()}
                  </span>
                </div>
                <div className="ml-3">
                  <h3 className="text-lg font-semibold">{investigator.name}</h3>
                </div>
              </div>
              {/* Horizontal Line */}
              <hr className="border-t border-gray-300" />
              {/* Field of Expertise */}
              <div className="mb-4 mt-4 flex items-center px-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="h-5 w-5 text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 10h16M4 14h16M4 18h16"
                  />
                </svg>
                <span className="ml-2 text-sm text-gray-600">
                  {investigator.field ? investigator.field : 'Investigator'}
                </span>
              </div>

              {/* Distance */}
              <div className="mb-4 flex items-center px-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="h-5 w-5 text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 2c4.418 0 8 3.582 8 8 0 6-8 12-8 12S4 16 4 10c0-4.418 3.582-8 8-8z"
                  />
                </svg>
                <span className="ml-2 text-sm text-gray-600">
                  {investigator.distance} km
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Button aligned to the right */}
      <div className="mt-4 flex w-full justify-end">
        <button
          className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
          hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base`}
          type="button"
          // onClick={handleCancel}
        >
          <span>Previous</span>
        </button>

        <button
          className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
          hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base`}
          type="submit"
        >
          <span>Save</span>
        </button>

        <button
          className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
          hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base`}
          type="button"
          // onClick={handleCancel}
        >
          <span>Next</span>
        </button>
      </div>
    </div>
  )
}

export default AssignInvestigator
