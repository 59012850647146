export const policyDocumentColumnsDataOrders = [
  {
    Header: 'S.No',
    accessor: 'index',
    disableSortBy: true,
  },
  {
    Header: 'Customer Office',
    accessor: 'insuredOffice.name',
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: true,
  },

  {
    Header: 'ACTIONS',
    accessor: 'COMPANY ACTIONS',
    disableSortBy: true,
  },
]
