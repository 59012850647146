export const typeOfOfficeColumnsDataOrders = [
  {
    Header: 'S.No',
    accessor: 'index',
    disableSortBy: true,
  },
  {
    Header: 'Office Type',
    accessor: 'name',
    disableSortBy: true,
  },
  {
    Header: 'Short Code',
    accessor: 'shortCode',
    disableSortBy: true,
  },
  {
    Header: 'ACTIONS',
    accessor: 'OFFICE ACTIONS',
    disableSortBy: true,
  },
]
