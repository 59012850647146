import React, { useMemo, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FiSearch } from 'react-icons/fi'
import { HiPlus } from 'react-icons/hi'
import { MdEdit } from 'react-icons/md'
import TooltipHorizon from 'components/tooltip'
import debounce from 'lodash.debounce'
import CrawfordConstants from 'common/config/crawfordConstants'
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import { BsArrowDown, BsArrowUp } from 'react-icons/bs'
import PaginationSkeleton from 'components/skeletons/PaginationSkeleton'
/**
 * Renders the search table for orders and handles pagination, filtering, and sorting.
 *
 * @param {Object} props - Component props for the search table.
 * @return {JSX.Element} JSX representation of the search table component.
 */
function SearchTableOrders(props) {
  const { NO_DATA } = CrawfordConstants
  let navigate = useNavigate()
  const {
    columnsData,
    tableData,
    totalCount,
    onPageChange,
    itemsPerPage,
    currentPage,
    isOfficeLoading,
    setGlobalFilter,
  } = props

  const columns = useMemo(() => columnsData, [columnsData])

  const [data, setData] = useState([])
  const [totalPageCount, setPageCount] = useState(0)
  const [pageIndex, setPageIndex] = useState(currentPage)

  useEffect(() => {
    if (tableData) {
      setData(tableData)
    }
  }, [tableData])

  useEffect(() => {
    if (totalCount) {
      setPageCount(totalCount)
    }
  }, [totalCount])

  // eslint-disable-next-line
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex, itemsPerPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  let count = 1
  const tableSkeletons = Array.from({ length: 4 })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state: { globalFilter },
    gotoPage,
  } = tableInstance

  const page = tableInstance.rows

  const setFilter = debounce(async (value) => {
    try {
      setGlobalFilter(value)
    } catch (err) {
      console.log('err', err)
    }
  }, 500)

  /**
   * A function to handle the click event for editing a country.
   *
   * @param {Object} data - The data object containing the country details
   * @return {void}
   */
  const handleEditClick = (data) => {
    navigate(`/customers/typeOfOffice-edit/${data._id}`)
  }

  /**
   * Updates the page index and navigates to the specified page.
   *
   * @param {number} page - The page number to navigate to.
   * @return {void} This function does not return anything.
   */
  const changePage = (page) => {
    setPageIndex(page)
    gotoPage(page)
    onPageChange(page + 1)
  }

  return (
    <>
      <div className="h-full w-full p-2 md:p-4">
        <div className="flex h-full w-full flex-col items-center justify-between !pt-0 md:flex-row md:py-2">
          {/* Search input */}
          <div className="mb-3 flex w-full items-center rounded-full bg-white p-[5px] shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mb-0 md:w-9/12">
            <div className="flex h-9 w-full flex-grow items-center rounded-full border-white bg-lightPrimary text-sm text-gray-600 dark:border dark:!bg-navy-900">
              <FiSearch className="mx-2 h-6 w-4 !text-gray-700 dark:!text-white" />
              <input
                type="text"
                placeholder="Search By Office type"
                className="placeholder:text-lightFourth block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white  sm:w-full"
                value={globalFilter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>
          </div>
          {/* Create Services button */}
          <button
            onClick={() => {
              navigate(`/customers/typeOfOffice-create`)
            }}
            className="flex items-center rounded-[20px] bg-brand-500 px-5 py-2 text-sm font-medium text-white transition duration-200 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 sm:px-3 sm:text-xs md:text-base lg:px-5 lg:text-base"
          >
            Add New{' '}
            <span className="ml-2 text-xl">
              <HiPlus />
            </span>
          </button>
        </div>

        {/* Table */}

        {isOfficeLoading ? (
          <div className="flex flex-col">
            {tableSkeletons.map((_item, index) => (
              <div className="flex animate-pulse border-b border-gray-200 py-4 dark:!border-white/10">
                <div className="w-1/5">
                  <div className="ml-4 h-2 w-1/6 rounded bg-gray-200 dark:bg-gray-800"></div>
                </div>
                <div className="w-4/5">
                  <div className="ml-4 h-2 w-1/3 rounded bg-gray-200 dark:bg-gray-800"></div>
                </div>
                <div className="w-2/5">
                  <div className="ml-8 h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                </div>
                <div className="w-2/5">
                  <div className="ml-8 h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                </div>
                <div className="w-1/5">
                  <div className="ml-8 h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="mt-2 min-h-[100px] w-full overflow-x-scroll xl:overflow-hidden">
            <table {...getTableProps()} className="w-full">
              <thead className="w-full">
                {headerGroups.map((headerGroup, indexRow) => (
                  <tr
                    className="items-center border-b border-gray-200 dark:!border-white/10"
                    {...headerGroup.getHeaderGroupProps()}
                    key={indexRow}
                  >
                    {headerGroup.headers.map((column, index) => {
                      return (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className="px-4 pb-[10px] text-left text-xs text-gray-600"
                          key={index}
                        >
                          <div className="flex items-center">
                            {column.render('Header')}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <BsArrowDown className="ml-2" />
                              ) : (
                                <BsArrowUp className="ml-2" />
                              )
                            ) : (
                              ''
                            )}
                          </div>
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, rowIndex) => {
                  prepareRow(row)
                  return (
                    <tr
                      className="border-b border-gray-200 dark:!border-white/10"
                      {...row.getRowProps()}
                      key={rowIndex}
                    >
                      {row.cells.map((cell, cellIndex) => {
                        let data = ''
                        if (
                          cell.column.Header === 'Office Type' ||
                          cell.column.Header === 'Short Code'
                        ) {
                          data = (
                            <div className="text-left font-medium text-navy-700 dark:text-white">
                              {cell.value}
                            </div>
                          )
                        } else if (cell.column.Header === 'S.No') {
                          data = (
                            <div className="text-left font-medium text-navy-700 dark:text-white">
                              {pageIndex * itemsPerPage + count++}
                            </div>
                          )
                        } else if (cell.column.Header === 'ACTIONS') {
                          data = (
                            <div className="justify-left flex">
                              <div className="w-max rounded-xl py-1 text-sm">
                                <TooltipHorizon
                                  extra="border border-gray-200"
                                  trigger={
                                    <p
                                      className="flex cursor-pointer items-center rounded-full border p-1 font-bold  text-[#000] hover:font-medium hover:text-black dark:text-white"
                                      onClick={() =>
                                        handleEditClick(row.original)
                                      }
                                    >
                                      <span>
                                        <MdEdit className="text-xl" />
                                      </span>
                                    </p>
                                  }
                                  content="Edit"
                                  placement="top"
                                />
                              </div>
                            </div>
                          )
                        }
                        return (
                          <td
                            className="px-4"
                            {...cell.getCellProps()}
                            key={cellIndex}
                          >
                            {data}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {/* Display message if no data */}
            {!isOfficeLoading && page.length === 0 && (
              <div className="text-md mt-3 flex w-full justify-center text-center text-gray-500 dark:text-gray-400">
                {NO_DATA}
              </div>
            )}
          </div>
        )}

        {/* Pagination */}
        {isOfficeLoading ? (
          <PaginationSkeleton />
        ) : (
          page.length > 0 && (
            <div className="mt-2 flex h-12 w-full items-center justify-between px-6">
              <div className="text-sm text-gray-600">
                {/* Display current range of entries */}
                Showing {pageIndex * itemsPerPage + 1} -{' '}
                {totalPageCount < pageIndex * itemsPerPage + data.length
                  ? totalPageCount
                  : pageIndex * itemsPerPage + data.length}{' '}
                of {totalPageCount}
              </div>

              <div className="flex items-center">
                {/* Render pagination buttons */}
                <div className="ml-3 flex">
                  {/* Previous button */}
                  <button
                    className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                      pageIndex === 0 ? 'cursor-not-allowed opacity-50' : ''
                    }`}
                    onClick={() => changePage(pageIndex - 1)}
                    disabled={pageIndex === 0}
                  >
                    {'<'}
                  </button>
                  {/* Next button */}
                  <button
                    className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                      pageIndex >= Math.ceil(totalPageCount / 10) - 1
                        ? 'cursor-not-allowed opacity-50'
                        : ''
                    }`}
                    onClick={() => changePage(pageIndex + 1)}
                    disabled={pageIndex >= Math.ceil(totalPageCount / 10) - 1}
                  >
                    {'>'}
                  </button>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </>
  )
}

export default SearchTableOrders
