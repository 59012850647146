import React from 'react'
import DepartmentList from './Department'
import { FaBuilding } from 'react-icons/fa'
import { IoEarthSharp } from 'react-icons/io5'
import { MdPolicy } from 'react-icons/md'
import { HiOutlineDocumentArrowUp } from 'react-icons/hi2'

import AddDepartment from './Department/components/AddDepartment'
import NOLList from './NatureOfLoss'
import COLList from './CauseOfLoss'
import AddCOL from 'views/Insurance/CauseOfLoss/components/AddCOL'
import AddNOL from 'views/Insurance/NatureOfLoss/components/AddNOL'
import PolicyTypeList from './PolicyType'
import PolicyDocumentList from './PolicyDocument'
import AddPolicyType from 'views/Insurance/PolicyType/components/AddPolicyType'
import { GrCurrency } from 'react-icons/gr'
import AddPolicyDocument from './PolicyDocument/components/AddPolicyDocument'

const InsuranceRoutes = [
  {
    name: 'Department',
    layout: '/afterLogin',
    path: '/insurance/department-list',
    icon: <FaBuilding className="text-inherit h-6 w-6" />,
    component: <DepartmentList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Insurance',
    breadcrum: [
      { label: 'Insurance', link: '', breadCrumDisabled: true },
      { label: 'List', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Department',
    layout: '/afterLogin',
    path: '/insurance/department-create',
    icon: <IoEarthSharp className="text-inherit h-6 w-6" />,
    component: <AddDepartment />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Insurance',
    breadcrum: [
      { label: 'Insurance', link: '', breadCrumDisabled: true },
      { label: 'Department', link: '/department/department-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Department',
    layout: '/afterLogin',
    path: '/insurance/department-edit/:id',
    icon: <IoEarthSharp className="text-inherit h-6 w-6" />,
    component: <AddDepartment />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Insurance',
    breadcrum: [
      { label: 'Insurance', link: '', breadCrumDisabled: true },
      { label: 'Department', link: '/insurance/department-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Nature of Loss',
    layout: '/afterLogin',
    path: '/insurance/natureloss-list',
    icon: <GrCurrency className="text-inherit h-6 w-6" />,
    component: <NOLList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Insurance',
    breadcrum: [
      { label: 'Insurance', link: '', breadCrumDisabled: true },
      { label: 'List', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Nature of Loss',
    layout: '/afterLogin',
    path: '/insurance/natureloss-create',
    icon: <GrCurrency className="text-inherit h-6 w-6" />,
    component: <AddNOL />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Insurance',
    breadcrum: [
      { label: 'Insurance', link: '', breadCrumDisabled: true },
      { label: 'Nature of Loss', link: '/insurance/natureloss-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Nature of Loss',
    layout: '/afterLogin',
    path: '/insurance/natureloss-edit/:id',
    icon: <GrCurrency className="text-inherit h-6 w-6" />,
    component: <AddNOL />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Insurance',
    breadcrum: [
      { label: 'Insurance', link: '', breadCrumDisabled: true },
      { label: 'Nature of Loss', link: '/insurance/natureloss-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Cause of Loss',
    layout: '/afterLogin',
    path: '/insurance/causeloss-list',
    icon: <GrCurrency className="text-inherit h-6 w-6" />,
    component: <COLList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Insurance',
    breadcrum: [
      { label: 'Insurance', link: '', breadCrumDisabled: true },
      { label: 'List', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Cause of Loss',
    layout: '/afterLogin',
    path: '/insurance/causeloss-create',
    icon: <GrCurrency className="text-inherit h-6 w-6" />,
    component: <AddCOL />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Insurance',
    breadcrum: [
      { label: 'Insurance', link: '', breadCrumDisabled: true },
      { label: 'Cause of Loss', link: '/insurance/causeloss-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Cause of Loss',
    layout: '/afterLogin',
    path: '/insurance/causeloss-edit/:id',
    icon: <MdPolicy className="text-inherit h-6 w-6" />,
    component: <AddCOL />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Insurance',
    breadcrum: [
      { label: 'Insurance', link: '', breadCrumDisabled: true },
      { label: 'Cause of Loss', link: '/insurance/causeloss-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Policy Type',
    layout: '/afterLogin',
    path: '/insurance/policy-type-list',
    icon: <MdPolicy className="text-inherit h-6 w-6" />,
    component: <PolicyTypeList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Insurance',
    breadcrum: [
      { label: 'Insurance', link: '', breadCrumDisabled: true },
      { label: 'List', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Policy Type',
    layout: '/afterLogin',
    path: '/insurance/policy-type-create',
    icon: <MdPolicy className="text-inherit h-6 w-6" />,
    component: <AddPolicyType />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Insurance',
    breadcrum: [
      { label: 'Insurance', link: '', breadCrumDisabled: true },
      { label: 'Policy Type', link: '/insurance/policy-type-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Policy Type',
    layout: '/afterLogin',
    path: '/insurance/policy-type-edit/:id',
    icon: <GrCurrency className="text-inherit h-6 w-6" />,
    component: <AddPolicyType />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Insurance',
    breadcrum: [
      { label: 'Insurance', link: '', breadCrumDisabled: true },
      { label: 'Policy Type', link: '/insurance/policy-type-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Policy Document',
    layout: '/afterLogin',
    path: '/insurance/policy-document-list',
    icon: <HiOutlineDocumentArrowUp className="text-inherit h-6 w-6" />,
    component: <PolicyDocumentList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Insurance',
    breadcrum: [
      { label: 'Insurance', link: '', breadCrumDisabled: true },
      { label: 'List', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Policy Document',
    layout: '/afterLogin',
    path: '/insurance/policy-document-create',
    icon: <HiOutlineDocumentArrowUp className="text-inherit h-6 w-6" />,
    component: <AddPolicyDocument />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Insurance',
    breadcrum: [
      { label: 'Insurance', link: '', breadCrumDisabled: true },
      { label: 'Policy Document', link: '/insurance/policy-type-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Policy Document',
    layout: '/afterLogin',
    path: '/insurance/policy-document-edit/:id',
    icon: <HiOutlineDocumentArrowUp className="text-inherit h-6 w-6" />,
    component: <AddPolicyDocument />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Insurance',
    breadcrum: [
      { label: 'Insurance', link: '', breadCrumDisabled: true },
      { label: 'Policy Document', link: '/insurance/policy-document-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
]

export default InsuranceRoutes
