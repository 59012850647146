import React, { useMemo, useState, useEffect } from 'react'
import { FiStar, FiTrash2, FiPlus, FiFolder, FiMail } from 'react-icons/fi'
import { MdOutlineAttachEmail } from 'react-icons/md'
import debounce from 'lodash.debounce'
import PaginationSkeleton from 'components/skeletons/PaginationSkeleton'
import SingleEmail from './SingleEmail'
import moment from 'moment'

function EmailInterface(props) {
  const {
    columnsData,
    tableData,
    totalCount,
    onPageChange,
    itemsPerPage,
    currentPage,
    isEmailLoading,
    setGlobalFilter,
  } = props

  const [data, setData] = useState([])
  const [totalPageCount, setPageCount] = useState(0)
  const [pageIndex, setPageIndex] = useState(currentPage)
  const [singleEmail, setSingleEmail] = useState(null) // null initially

  useEffect(() => {
    if (tableData) {
      setData(tableData.value)
    }
  }, [tableData])

  useEffect(() => {
    if (totalCount) {
      setPageCount(totalCount)
    }
  }, [totalCount])

  const tableSkeletons = Array.from({ length: 4 })

  const setFilter = debounce(async (value) => {
    try {
      setGlobalFilter(value)
    } catch (err) {
      console.log('err', err)
    }
  }, 500)

  const changePage = (page) => {
    setPageIndex(page)
    onPageChange(page + 1)
  }

  const openSingleEmail = (email) => {
    setSingleEmail(email) // Set the selected email
  }

  const goBackToEmailList = () => {
    setSingleEmail(null) // Clear selected email to go back to the list
  }

  return (
    <div className="flex w-full">
      {/* Sidebar */}
      <div className="w-1/5 rounded-3xl bg-white p-4 shadow-md">
        {/* <button className="mb-6 flex w-full items-center justify-center rounded-md bg-blue-500 p-2 text-white">
          + Compose
        </button> */}
        <div className="mb-4">
          <ul className="text-gray-700">
            <li
              className="flex cursor-pointer items-center gap-2 p-2 text-blue-500"
              onClick={goBackToEmailList}
            >
              <FiMail />
              Inbox
              <span className="ml-auto text-sm text-gray-400">
                {totalPageCount}
              </span>
            </li>
          </ul>
        </div>
      </div>

      {/* Main Content */}
      <div className="ml-6 w-3/4 rounded-3xl bg-white p-4">
        {/* Search Bar */}
        {/* <div className="mb-4 flex items-center rounded-md p-2">
          <input
            type="text"
            placeholder="Search mail"
            className="w-[400px] rounded-3xl bg-gray-50 p-2 text-sm outline-none"
            onChange={(e) => setFilter(e.target.value)}
          />
        </div> */}

        {/* Conditional rendering: Show either email list or single email */}
        {singleEmail ? (
          // Render the SingleEmail component if an email is selected
          <SingleEmail email={singleEmail} goBack={goBackToEmailList} />
        ) : (
          // Render the email list if no email is selected
          <>
            {isEmailLoading ? (
              <div className="flex flex-col">
                {tableSkeletons.map((_item, index) => (
                  <div
                    key={index}
                    className="flex animate-pulse border-b border-gray-200 py-4"
                  >
                    <div className="flex animate-pulse border-b border-gray-200 py-4 dark:!border-white/10">
                      <div className="w-1/5">
                        <div className="ml-4 h-2 w-1/6 rounded bg-gray-200 dark:bg-gray-800"></div>
                      </div>
                      <div className="w-4/5">
                        <div className="ml-4 h-2 w-1/3 rounded bg-gray-200 dark:bg-gray-800"></div>
                      </div>
                      <div className="w-2/5">
                        <div className="ml-8 h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                      </div>
                      <div className="w-2/5">
                        <div className="ml-8 h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                      </div>
                      <div className="w-1/5">
                        <div className="ml-8 h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="overflow-y-auto">
                {data &&
                  data.map((emailData, index) => (
                    <div
                      key={index}
                      className="flex cursor-pointer items-center border-b border-gray-200 p-2"
                      onClick={() => openSingleEmail(emailData)}
                    >
                      {/* <input type="checkbox" className="mr-2" /> */}
                      {/* <FiStar className="mr-2 text-gray-400 hover:text-yellow-500" /> */}
                      <div className="flex-grow">
                        <p>
                          <span className="mr-1 font-medium">Subject:</span>
                          {emailData?.subject}
                        </p>
                        <p className="font-medium">
                          {emailData?.from?.emailAddress?.address}
                        </p>
                        <p className="mr-2 text-sm text-gray-800">
                          {emailData?.bodyPreview}...
                        </p>
                      </div>
                      <div className="gap-3x flex items-center  text-gray-700">
                        {emailData.hasAttachments ? (
                          <MdOutlineAttachEmail className="mr-1 text-xl" />
                        ) : (
                          <></>
                        )}
                        {/* <FiTrash2 /> */}
                        {/* <span className="rounded-3xl bg-blue-500 p-1 text-white">
                          <FiPlus />
                        </span> */}
                        <span className="text-xs">
                          {moment(emailData?.sentDateTime).format('MM/DD/YY')}{' '}
                          <br />
                          {moment(emailData?.sentDateTime).format('HH:SS A')}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            )}

            {/* Footer */}
            {isEmailLoading ? (
              <PaginationSkeleton />
            ) : (
              data &&
              data.length > 0 && (
                <div className="mt-2 flex h-12 w-full items-center justify-between px-6">
                  <div className="text-sm text-gray-600">
                    Showing {pageIndex > 0 ? pageIndex * itemsPerPage + 1 : 1} -{' '}
                    {totalPageCount < pageIndex * itemsPerPage + data.length
                      ? totalPageCount
                      : pageIndex * itemsPerPage + data.length}{' '}
                    of {totalPageCount}
                  </div>

                  <div className="flex items-center">
                    <div className="ml-3 flex">
                      <button
                        className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white ${
                          pageIndex === 0 ? 'cursor-not-allowed opacity-50' : ''
                        }`}
                        onClick={() => changePage(pageIndex - 1)}
                        disabled={pageIndex === 0}
                      >
                        {'<'}
                      </button>
                      <button
                        className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white ${
                          pageIndex >= Math.ceil(totalPageCount / 10) - 1
                            ? 'cursor-not-allowed opacity-50'
                            : ''
                        }`}
                        onClick={() => changePage(pageIndex + 1)}
                        disabled={
                          pageIndex >= Math.ceil(totalPageCount / 10) - 1
                        }
                      >
                        {'>'}
                      </button>
                    </div>
                  </div>
                </div>
              )
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default EmailInterface
