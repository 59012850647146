import EmailTableList from './components/List'
import { useState, useEffect } from 'react'
import { getEmail } from 'common/commonFunction'
/**
 * Renders the Zone List component with the fetched zone data.
 *
 * @return {JSX.Element} The rendered Zone List component
 */
const EmailList = () => {
  const [allEmailData, setEmailData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const itemsPerPage = 10 // You can change this to your desired number
  const [isEmailLoading, setEmailLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setEmailLoading(true)
        const payload = {
          page: currentPage,
          keyword: searchValue,
        }
        const getEmailList = await getEmail(payload)

        if (getEmailList?.data?.value) {
          setEmailData(getEmailList?.data?.data?.emailList)
          setTotalCount(
            getEmailList?.data?.data?.emailList['@odata.count'] || 0
          )
        } else {
          setEmailData([])
          setTotalCount(0)
        }
      } catch (error) {
        console.error('Error during API call:', error)
      } finally {
        setEmailLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchValue]) // Fetch data whenever currentPage changes

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const setGlobalFilter = (value) => {
    try {
      setCurrentPage(1)
      setSearchValue(value)
    } catch (err) {
      console.log('err', err)
    } finally {
      setEmailLoading(false)
    }
  }

  return (
    <div extra={'w-full h-full mt-3'}>
      <EmailTableList
        tableData={allEmailData}
        totalCount={totalCount}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        onPageChange={handlePageChange}
        isEmailLoading={isEmailLoading}
        setEmailLoading={setEmailLoading}
        setGlobalFilter={setGlobalFilter}
      />
    </div>
  )
}

export default EmailList
