import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import Card from 'components/card'
import { useSpinner } from 'common/SpinnerLoader'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import { useToaster } from 'common/Toaster'
import SwitchWithText from 'components/switch-with-text'
import InputField from 'components/fields/InputField'
import CrawfordConstants from 'common/config/crawfordConstants'

const AddTOC = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const apiService = ApiCaller()
  const { TOAST } = CrawfordConstants

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
    },
  })
  const [isInsuredActive, setInsuredStatus] = useState(false)

  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()
  const changeInsuredStatus = () => {
    setInsuredStatus(!isInsuredActive)
  }

  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.TOC_CREATE
      const payload = {
        _id: id ? id : undefined,
        name: formData.name,
        isInsured: isInsuredActive,
      }

      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        addToast({
          title: `Type Of Claim "${formData.name}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        navigate(`/assignments/claims-list`)
      } else {
        addToast({ title: 'Type of Claim creation failed.', type: 'error' })
      }
    } catch (error) {
      addToast({ title: TOAST.MESSAGES.ERROR.swwError, type: 'error' })
    } finally {
      hideSpinner()
    }
  }

  const getTOCDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.TOC_DETAIL
      const response = await apiService.apiCall('post', apiUrl, { _id: id })
      if (response?.data?.value) {
        setValue('name', response?.data?.data?.name || '')
        setInsuredStatus(response?.data?.data?.isInsured)
      } else {
        addToast({ title: 'Some Error Occurred', type: 'error' })
      }
    } catch (error) {
      console.error('Error:', error)
    } finally {
      hideSpinner()
    }
  }

  useEffect(() => {
    if (id) getTOCDetails()
  }, [id])

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
      <div className="flex justify-between mt-[25px] ">
        <div>
          <p className="text-xl font-bold text-navy-700 dark:text-white">
            {id ? 'Edit' : 'Add'} Claims
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="mt-7 grid grid-cols-1 gap-3">
          <div className="col-span-1 ">
            <InputField
              extra="w-full"
              label="Name"
              id="name"
              type="text"
              placeholder="Enter type of Claim"
              registrationProps={register('name', {
                required: 'Name is required',
              })}
              isFieldRequired={true}
              state={errors.name && 'error'}
            />
            <div className="error-message text-right">
              {errors.name?.message}
            </div>
          </div>
        </div>

        <div className="mt-3">
          <label>
            Is Insured
            <span className="text-red-500"> * </span>
          </label>
          <div className="col-span-2 lg:col-span-1">
            <div className="flex w-full ">
              <SwitchWithText
                textLeft="OFF"
                textRight="ON"
                checked={isInsuredActive}
                onChange={changeInsuredStatus}
              />
            </div>
          </div>
        </div>
        <div className="mt-4 flex w-full justify-end">
          <button
            className="mt-2 w-40 cursor-pointer rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 md:text-base"
            type="submit"
          >
            <span>{id ? 'Update' : 'Save'}</span>
          </button>
          <button
            className="ml-2 mt-2 w-40 cursor-pointer rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200 hover:bg-gray-600 active:bg-gray-700 md:text-base"
            type="button"
            onClick={() => navigate(`/assignments/claims-list`)}
          >
            <span>Cancel</span>
          </button>
        </div>
      </form>
    </Card>
  )
}

export default AddTOC
