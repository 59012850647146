import { officerColumnsDataOrders } from './variables/ColumnDataOrders'
import OfficerListTable from './components/List'
import Card from 'components/card'
import { useState, useEffect } from 'react'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
const apiService = ApiCaller()
/**
 * Renders the Officer List component with the fetched officer data.
 *
 * @return {JSX.Element} The rendered Officer List component
 */
const OfficerList = () => {
  const [allAppData, setOfficerData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const itemsPerPage = 10 // You can change this to your desired number
  const [isOfficerLoading, setOfficerLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setOfficerLoading(true)
        const apiUrl = apiConfig.OFFICER_LIST
        const payload = {
          page: currentPage,
          keyword: searchValue,
          filter: {},
        }
        const response = await apiService.apiCall('post', apiUrl, payload)
        if (response?.data?.value) {
          setOfficerData(response?.data?.data?.results)
          setTotalCount(response?.data?.data?.total || 0)
        } else {
          setOfficerData([])
          setTotalCount(0)
        }
      } catch (error) {
        console.error('Error during API call:', error)
      } finally {
        setOfficerLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchValue]) // Fetch data whenever currentPage changes

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const setGlobalFilter = (value) => {
    try {
      setCurrentPage(1)
      setSearchValue(value)
    } catch (err) {
      console.log('err', err)
    } finally {
      setOfficerLoading(false)
    }
  }

  return (
    <>
      <Card extra={'w-full h-full bg-white mt-3'}>
        <OfficerListTable
          tableData={allAppData}
          columnsData={officerColumnsDataOrders}
          totalCount={totalCount}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          isOfficerLoading={isOfficerLoading}
          setOfficerLoading={setOfficerLoading}
          setGlobalFilter={setGlobalFilter}
        />
      </Card>
    </>
  )
}

export default OfficerList
