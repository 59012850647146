import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import Card from 'components/card'
import { useSpinner } from 'common/SpinnerLoader'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import { useToaster } from 'common/Toaster'
import SwitchWithText from 'components/switch-with-text'
import InputField from 'components/fields/InputField'
import CrawfordConstants from 'common/config/crawfordConstants'
import { getUserInfo } from 'common/commonFunction'
import Select from 'react-select'

const AddPolicyType = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const apiService = ApiCaller()
  const { TOAST } = CrawfordConstants
  const [departmentOptions, setDepartmentOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      PolicyType: '',
      department: '',
      status: true,
    },
  })
  const [isPolicyTypeActive, setPolicyTypeStatus] = useState(false)

  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()

  const changePolicyTypeStatus = () => {
    setPolicyTypeStatus(!isPolicyTypeActive)
  }

  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      const userInfo = getUserInfo()
      const apiUrl = apiConfig.POLICY_TYPE_CREATE
      const transformedPolicyType = formData.department.map((item) => ({
        _id: item.value,
        name: item.label,
      }))
      const payload = {
        _id: id ? id : undefined,
        name: formData.PolicyType,
        department: transformedPolicyType,
        status: id ? isPolicyTypeActive : undefined,
        users: {
          email: userInfo.email,
          name: userInfo.name,
        },
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        addToast({
          title: `Policy type "${formData.PolicyType}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        navigate(`/insurance/policy-type-list`)
      } else {
        addToast({ title: 'Policy type creation failed.', type: 'error' })
      }
    } catch (error) {
      addToast({ title: TOAST.MESSAGES.ERROR.swwError, type: 'error' })
    } finally {
      hideSpinner()
    }
  }

  const getPolicyTypeDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.POLICY_TYPE_DETAIL
      const payload = {
        _id: id,
        checkRoles: false,
        accessOf: ['PolicyTypeEdit'],
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        setValue('PolicyType', response?.data?.data?.name || '')
        if (response?.data?.data?.department.length > 0) {
          const transformedPolicyType = response?.data?.data?.department.map(
            (item) => ({
              value: item._id,
              label: item.name,
            })
          )
          setValue('department', transformedPolicyType || [])
        }
        setPolicyTypeStatus(response?.data?.data?.status)
      } else {
        addToast({ title: 'Some Error Occurred', type: 'error' })
      }
    } catch (error) {
      console.error('Error:', error)
    } finally {
      hideSpinner()
    }
  }

  /**
   * Fetches department options based on the provided input value and updates the department options state.
   *
   * @async
   * @function fetchDepartmentOptions
   * @param {string} inputValue - The keyword to filter department options by.
   * @returns {Promise<void>} Returns nothing.
   */
  const fetchDepartmentOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.GET_DEPARTMENT
      const payload = {
        keyword: inputValue,
        filter: {},
        page: 1,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setDepartmentOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Handles input changes for the department search input.
   * Triggers a fetch of department options when the input length is greater than zero.
   *
   * @function handleDepartmentInputChange
   * @param {string} inputValue - The current value of the department search input.
   */
  const handleDepartmentInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchDepartmentOptions(inputValue)
    }
  }

  /**
   * Handles changes in the selected department.
   * If no department is selected, it triggers a fetch of all department options.
   *
   * @function handleDepartmentSelectionChange
   * @param {Object|null} department - The currently selected department object or null if no selection.
   */
  const handleDepartmentSelectionChange = (department) => {
    if (!department) {
      fetchDepartmentOptions() // Call the function to perform API request
    }
  }

  useEffect(() => {
    if (id) getPolicyTypeDetails()
  }, [id])

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
      <div className="flex justify-between mt-[25px] ">
        <div>
          <p className="text-xl font-bold text-navy-700 dark:text-white">
            {id ? 'Edit' : 'Add'} Policy Type
          </p>
        </div>

        <div className="col-span-2 lg:col-span-1">
          <div className="flex w-full justify-end">
            <SwitchWithText
              textLeft="OFF"
              textRight="ON"
              checked={isPolicyTypeActive}
              onChange={changePolicyTypeStatus}
            />
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="mt-7 grid grid-cols-2 gap-3">
          <div className="col-span-2 grid lg:col-span-1">
            <InputField
              extra="w-full"
              label="Policy Type"
              id="PolicyType"
              type="text"
              placeholder="Enter Policy Type"
              registrationProps={register('PolicyType', {
                required: 'Policy Type is required',
              })}
              isFieldRequired={true}
              state={errors.PolicyType && 'error'}
            />
            <div className="error-message text-right">
              {errors.PolicyType?.message}
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold text-navy-700">
              Department
              <span className="text-red-500"> * </span>
            </label>
            <Controller
              name="department"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  id="department"
                  options={departmentOptions}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isMulti
                  isLoading={isLoading}
                  onFocus={() => fetchDepartmentOptions()}
                  onInputChange={handleDepartmentInputChange}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(department) => {
                    field.onChange(department)
                    handleDepartmentSelectionChange(department)
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      minHeight: '44px',
                      borderRadius: '0.75rem',
                      borderWidth: '1px',
                      marginBottom: '6px',
                      borderColor: error
                        ? 'red'
                        : state.isFocused
                          ? 'rgb(59, 130, 246)' // Tailwind blue-500 for focus
                          : 'rgba(218, 222, 236, 1)', // Default gray color

                      // Blue outline (ring) on focus, red if error
                      boxShadow: state.isFocused
                        ? `0 0 0 1px ${error ? 'red' : 'rgb(59, 130, 246)'}`
                        : '',

                      '&:hover': {
                        borderColor: error ? 'red' : 'rgb(59, 130, 246)', // Hover blue if no error
                      },
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      fontSize: '14px',
                      color: error ? 'red' : '#999', // Red placeholder on error
                    }),
                    menu: (base) => ({
                      ...base,
                      width: '100%', // Width of the dropdown menu
                      maxHeight: '250px', // Max height for the dropdown menu
                      borderRadius: '8px',
                      zIndex: 49, // Set a higher zIndex to ensure the menu appears above other elements
                    }),
                    menuList: (base) => ({
                      ...base,
                      maxHeight: '250px', // Max height of the list container for scrolling
                      padding: 0, // Optional padding for the menu list
                      zIndex: 49, // Set zIndex for the menu list as well if needed
                    }),
                    singleValue: (base) => ({
                      ...base,
                      color: '#333', // Color for selected text
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      color: state.isFocused
                        ? 'rgb(59, 130, 246)'
                        : 'rgba(156, 163, 175, 1)', // Blue dropdown indicator on focus
                      '&:hover': {
                        color: 'rgb(59, 130, 246)',
                      },
                    }),
                    indicatorSeparator: (base) => ({
                      ...base,
                      display: 'none', // Remove separator
                    }),
                  }}
                />
              )}
            />
            {errors.department && (
              <div className="error-message text-right text-red-500">
                {errors.department.message}
              </div>
            )}
          </div>
        </div>
        <div className="mt-4 flex w-full justify-end">
          <button
            className="mt-2 w-40 cursor-pointer rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 md:text-base"
            type="submit"
          >
            <span>{id ? 'Update' : 'Save'}</span>
          </button>
          <button
            className="ml-2 mt-2 w-40 cursor-pointer rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200 hover:bg-gray-600 active:bg-gray-700 md:text-base"
            type="button"
            onClick={() => navigate(`/insurance/policy-type-list`)}
          >
            <span>Cancel</span>
          </button>
        </div>
      </form>
    </Card>
  )
}

export default AddPolicyType
