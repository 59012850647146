import React, { useRef, useState, useEffect } from 'react'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import Card from 'components/card'
import { useForm, Controller } from 'react-hook-form'
import { useToaster } from 'common/Toaster'
import CrawfordConstants from 'common/config/crawfordConstants'
import { useSpinner } from 'common/SpinnerLoader'
import { useParams, useNavigate } from 'react-router-dom'
import InputField from 'components/fields/InputField'
import Select from 'react-select'
import noImg from 'assets/img/layers/no-image.png'
import moment from 'moment'
import SwitchWithText from 'components/switch-with-text'
import { readFileBasedOnId } from 'common/commonFunction'
const apiService = ApiCaller()
const { TOAST } = CrawfordConstants
const AddEmployee = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { showSpinner, hideSpinner } = useSpinner()
  const { addToast } = useToaster()
  // const [isPopUpOpen, setIsPopUpOpen] = useState(false)
  // const [isCreateOpen, setIsCreateOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  // const [setSearchQuery] = useState('')
  const [options, setOptions] = useState([])
  const [selectedOffices, setSelectedOffices] = useState([])
  const [selectedDepartment, setSelectedDepartment] = useState(null)
  const [branchOptions, setBranchOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [reportingToOptions, setReportingToOptions] = useState([])
  const [gradeOptions, setGradeOptions] = useState([])
  const [postedAtOptions, setPostedAtOptions] = useState([])
  const [functionOptions, setFunctionOptions] = useState([])
  const [departmentOptions, setDepartmentOptions] = useState([])
  const [isLicenseSurveyor, setLicenseSurveyorStatus] = useState(false)
  const [isSBC, setIsSBCStatus] = useState(false)
  const [isField, setIsFieldStatus] = useState(false)
  // const [setPersonalDocument] = useState(false)
  const [previewPhoto, setPhotoPreview] = useState(null)
  const [previewSignature, setSignaturePreview] = useState(null)
  const [photoFile, setPhotoFile] = useState(null)
  const [signatureFile, setSignatureFile] = useState(null)
  const [roles, setRoles] = useState([])
  const [selectedRoles, setSelectedRoles] = useState([])
  const filephotoRef = useRef(null)
  const fileSignatureRef = useRef(null)
  const [isEmployeeActive, setEmployeeStatus] = useState(true)

  // const [CTCDetails, setCTCDetails] = useState([
  //   { amount: '', from: '', to: '', image: null, grade: null },
  // ])
  // const [licenseDetails, setLicenseDetails] = useState([
  //   { image: null, from: '', to: '' },
  // ])
  const changeEmployeeStatus = () => {
    setEmployeeStatus(!isEmployeeActive)
  }
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      company: '',
      Salutation: '',
      firstName: '',
      lastName: '',
      postedAt: '',
      func: '',
      grade: '',
      houseColor: '',
      branch: '',
      employeeCode: '',
      role: [],
      reportingTo: '',
      offices: '',
      bank: '',
      branchName: '',
      accountNumber: '',
      neftCode: '',
      city: '',
      officeNumber: '',
      officeMobile: '',
      officeEmail: '',
      address: '',
      pincode: '',
      lat: '',
      lng: '',
      homeNumber: '',
      mobile: '',
      email: '',
      extension: '',
      gender: '',
      birthDate: null,
      joiningDate: null,
      marriageDate: '',
      leavingDate: '',
      licenseNumber: '',
      department: [],
      CTCDetails: [],
      isField: false,
      isSBC: false,
      isLicenseSurveyor: false,
      licenseDocument: [],
    },
  })

  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      // const CTCDetails =
      //   formData.CTCDetails.map((detail) => ({
      //     amount: detail.amount,
      //     from: detail.from,
      //     to: detail.to,
      //     image: detail.image || '',
      //     grade: detail.grade?.value || '',
      //   })) || []

      // const licenseDetails =
      //   formData.licenseDetails?.map((detail) => ({
      //     image: detail.image || '',
      //     from: detail.from,
      //     to: detail.to,
      //   })) || []

      const apiUrl = apiConfig.EMPLOYEE_CREATE
      const payload = {
        _id: id ? id : undefined,
        // CTCDetails: CTCDetails,
        // licenseDocument: licenseDetails,
        company: formData.company?.fullObject || 'undefined',
        salutation: formData.Salutation?.value || '',
        firstName: formData.FirstName || '',
        lastName: formData.LastName || '',
        name: formData.FirstName + ' ' + formData.LastName,
        postedAt:
          formData.postedAt && formData.postedAt._id
            ? formData.postedAt._id
            : undefined,
        func: {
          name: formData.func.label,
          _id: formData.func.value,
        },
        grade: formData.grade.value || '',
        branch: formData.branch.value || '',
        employeeCode: formData.employeeCode || '',
        photo: photoFile || undefined,
        signature: signatureFile || undefined,
        role: selectedRoles?.map((role) => role.fullObject) || [],
        reportingTo: formData.ReportingTo || '',
        offices: formData.offices || '',
        bank: formData.bank || '',
        branchName: formData.branchName || '',
        accountNumber: formData.accountNumber || '',
        neftCode: formData.neftCode || '',
        city: formData.city.value || '',
        officeNumber: formData.officeNumber || '',
        officeMobile: formData.officeMobile || '',
        officeEmail: formData.officeEmail || '',
        gender: formData.gender?.value || '',
        birthDate: formData.birthDate ? new Date(formData.birthDate) : null,
        joiningDate: formData.joiningDate
          ? new Date(formData.joiningDate)
          : null,
        marriageDate: formData.marriageDate
          ? new Date(formData.marriageDate)
          : null,
        leavingDate: formData.leavingDate
          ? new Date(formData.leavingDate)
          : null,
        licenseNumber: formData.licenseNumber || '',
        department: formData.department || [],
        houseColor: formData.houseColor.value || '',
        homeNumber: formData.homeNumber || '',
        mobile: formData.mobile || '',
        email: formData.email || '',
        extension: formData.extension || '',
        address: formData.address || '',
        pincode: formData.pincode || '',
        lat: formData.lat || 0,
        lng: formData.lng || 0,
        location: [formData.lng, formData.lat],
        isField: isField,
        isSBC: isSBC,
        isSurveyor: isLicenseSurveyor,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        addToast({
          title: `Employee "${formData.FirstName || ''} ${
            formData.LastName || ''
          }" ${id ? 'edited' : 'created'} successfully.`,
          type: 'success',
        })
        navigate('/employee/employee-list')
      } else {
        addToast({
          title: `Employee creation failed.`,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  const getEmployeeDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.EMPLOYEE_DETAIL.replace(':id', id)
      const payload = {
        _id: id,
        checkRoles: false,
        accessOf: ['employeeEdit'],
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data === 'Access Denied') {
        addToast({
          title: 'Access Denied',
          type: 'error',
        })
        navigate(`/employee/employee-list`)
      } else if (response?.data?.value) {
        const employeeData = response?.data?.data
        setValue(
          'company',
          {
            label: employeeData?.company || '',
            value: employeeData?.company || '',
          } || ''
        )
        setValue('isField', employeeData.isField || false)
        setIsFieldStatus(employeeData.isField || false)
        setValue('isSBC', employeeData.isSBC || false)
        setIsSBCStatus(employeeData.isSBC || false)
        setEmployeeStatus(employeeData?.status)
        setValue('isLicenseSurveyor', employeeData.isLicenseSurveyor || false)
        setLicenseSurveyorStatus(employeeData.isLicenseSurveyor || false)
        setValue(
          'Salutation',
          {
            label: employeeData?.salutation || '',
            value: employeeData?.salutation || '',
          } || ''
        )
        setValue(
          'houseColor',
          {
            label: employeeData?.houseColor || '',
            value: employeeData?.houseColor || '',
          } || ''
        )
        setValue(
          'gender',
          {
            label: employeeData?.gender || '',
            value: employeeData?.gender || '',
          } || ''
        )
        setValue('licenseNumber', employeeData?.licenseNumber || '')

        setValue('address', employeeData?.address || '')
        setValue('pincode', employeeData?.pincode || '')
        setValue('FirstName', employeeData?.firstName || '')
        setValue('LastName', employeeData?.lastName || '')
        setValue('grade', {
          label: employeeData?.grade?.name || '',
          value: employeeData?.grade?._id || '',
        })
        setValue('offices', {
          label: employeeData?.offices?.name || '',
          value: employeeData?.offices?._id || '',
        })
        setValue('company', {
          label: employeeData?.company?.name || '',
          value: employeeData?.company?._id || '',
        })
        setValue('isField', employeeData.isField || false)
        setValue('isSBC', employeeData.isSBC || false)
        setValue('isLicenseSurveyor', employeeData.isLicenseSurveyor || false)

        setValue('employeeCode', employeeData?.employeeCode || '')
        setValue('ReportingTo', {
          label: employeeData?.employee?.name || '',
          value: employeeData?.employee?._id || '',
        })
        setValue(
          'birthDate',
          employeeData?.birthDate
            ? moment(employeeData.birthDate).format('YYYY-MM-DD')
            : null
        )
        setValue(
          'joiningDate',
          employeeData?.joiningDate
            ? moment(employeeData.joiningDate).format('YYYY-MM-DD')
            : null
        )
        setValue(
          'marriageDate',
          employeeData?.marriageDate
            ? moment(employeeData.marriageDate).format('YYYY-MM-DD')
            : null
        )
        setValue(
          'leavingDate',
          employeeData?.leavingDate
            ? moment(employeeData.leavingDate).format('YYYY-MM-DD')
            : null
        )
        setValue('email', employeeData?.email || '')
        setValue('officeNumber', employeeData?.officeNumber || '')
        setValue('officeMobile', employeeData?.officeMobile || '')
        setValue('officeEmail', employeeData?.officeEmail || '')
        setValue('postedAt', {
          label: employeeData?.postedAt?.name || '',
          value: employeeData?.postedAt?._id || '',
        })
        setValue('city', {
          label: employeeData?.city?.name || '',
          value: employeeData?.city?._id || '',
        })

        if (employeeData.department && employeeData.department.length > 0) {
          const department = {
            label: employeeData.department[0].name || '',
            value: employeeData.department[0]._id || '',
          }
          setValue('department', department)
          setSelectedDepartment(department)
        } else {
          setValue('department', { label: '', value: '' })
          setSelectedDepartment(null)
        }

        setValue('func', {
          label: employeeData?.func?.name || '',
          value: employeeData?.func?._id || '',
        })
        setValue('branch', {
          label: employeeData?.branch?.name || '',
          value: employeeData?.branch?._id || undefined,
        })
        const rolesWithFullObject = employeeData.role.map((role) => ({
          label: role.name,
          value: role._id,
          fullObject: role,
        }))
        setSelectedRoles(rolesWithFullObject)
        setValue('branchName', employeeData?.branchName || '')
        setValue('lat', employeeData?.lat || '')
        setValue('lng', employeeData?.lng || '')
        setValue('homeNumber', employeeData?.homeNumber || '')
        setValue('extension', employeeData?.extension || '')
        setValue('mobile', employeeData?.mobile || '')
        setValue('neftCode', employeeData?.neftCode || '')
        setValue('bank', employeeData?.bank || '')
        setValue('accountNumber', employeeData?.accountNumber || '')
        setPhotoPreview(readFileBasedOnId(employeeData?.photo))
        setSignaturePreview(readFileBasedOnId(employeeData?.signature))
      } else {
        addToast({
          title: 'Some Error Occurred',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error fetching employee details:', error)
    } finally {
      hideSpinner()
    }
  }

  useEffect(() => {
    if (id) {
      getEmployeeDetails()
    } else {
      fetchOptions()
    }
  }, [])

  // const handleCTCFileChange = (e) => {
  //   const selectedFile = e.target.files[0]
  //   if (selectedFile) {
  //     setCTCDetails((prev) => {
  //       let uploadResponse = uploadFile(selectedFile, 'ctcImage')
  //       const newCTCDetails = [...prev]
  //       newCTCDetails[0].image = uploadResponse?.data?.data[0]?._id
  //       newCTCDetails[0].imagePreview = URL.createObjectURL(selectedFile)
  //       return newCTCDetails
  //     })
  //   }
  // }

  const handleCancel = () => {
    navigate(`/employee/employee-list`)
  }
  const changeSBCStatus = () => {
    setIsSBCStatus(!isSBC)
  }
  const changeFieldStatus = () => {
    setIsFieldStatus(!isField)
  }
  const changeLicenseSurveyorStatus = () => {
    setLicenseSurveyorStatus(!isLicenseSurveyor)
  }

  // const openPopup = () => {
  //   setIsPopUpOpen(true)
  // }

  // const closePopup = () => {
  //   setIsPopUpOpen(false)
  // }

  // const openCreate = () => {
  //   setIsCreateOpen(true)
  // }
  // const closeCreate = () => {
  //   setIsCreateOpen(false)
  // }
  const salutationOptions = [
    { value: 'Mr', label: 'Mr.' },
    { value: 'Ms', label: 'Ms.' },
    { value: 'Mrs', label: 'Mrs.' },
    { value: 'Dr', label: 'Dr.' },
  ]
  const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
  ]
  const houseColorOptions = [
    { value: 'Red', label: 'Red' },
    { value: 'Green', label: 'Green' },
    { value: 'Blue', label: 'Blue' },
    { value: 'Yellow', label: 'Yellow' },
    { value: 'White', label: 'White' },
  ]

  const uploadFile = async (file, fileFor) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.UPLOAD_IMAGE
      const data = new FormData()
      data.append('file', file)
      const response = await apiService.apiCall('post', apiUrl, data)
      if (response?.data?.value) {
        if (fileFor === 'photo') {
          setPhotoFile(response?.data?.data[0])
        } else {
          setSignatureFile(response?.data?.data[0])
        }
      }
      return response
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchFunctionOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.FUNCTION_LIST
      const payload = {
        keyword: inputValue,
        filter: {},
        page: 1,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setFunctionOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleFunctionInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchFunctionOptions(inputValue)
    }
  }
  const handleFunctionSelectionChange = (func) => {
    if (!func) {
      fetchFunctionOptions() // Call the function to perform API request
      setFunctionOptions([])
    }
  }

  const fetchPostedAtOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.OFFICE_LIST
      const payload = {
        keyword: inputValue,
        filter: {},
        page: 1,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setPostedAtOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }
  const handlePostedAtInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchCityOptions(inputValue)
    }
  }
  const handlePostedAtSelectionChange = (postedAt) => {
    if (!postedAt) {
      fetchPostedAtOptions() // Call the function to perform API request
      setPostedAtOptions([])
    }
  }

  const fetchCityOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.CITY_LIST
      const payload = {
        keyword: inputValue,
        filter: {},
        page: 1,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setCityOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleCityInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchCityOptions(inputValue)
    }
  }
  const handleCitySelectionChange = (city) => {
    if (!city) {
      fetchCityOptions() // Call the function to perform API request
      setCityOptions([])
    }
  }

  const fetchOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.COMPANY_LIST
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: {},
        page: inputValue ? 1 : 0,
      }

      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
          fullObject: item,
        }))
        setOptions(newOptions)
        if (!id && newOptions.length === 1) {
          setValue('company', newOptions[0])
        }
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchOptions(inputValue)
    }
  }

  const handleOfficesInputChange = (inputValue) => {
    if (inputValue.length > 2) {
      fetchBranchOptions(inputValue)
    }
  }

  const handleSelectionChange = (company) => {
    if (!company) {
      fetchOptions()
      setOptions([])
    }
  }

  const fetchBranchOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.BRANCH_LIST
      const payload = {
        keyword: inputValue,
        filter: {},
        page: 1,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setBranchOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleBranchInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchBranchOptions(inputValue)
    }
  }
  const handleBranchSelectionChange = (branch) => {
    if (!branch) {
      fetchBranchOptions() // Call the function to perform API request
      setBranchOptions([])
    }
  }

  const fetchGradeOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.GRADE_LIST
      const payload = {
        keyword: inputValue,
        filter: {},
        page: 1,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setGradeOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleGradeInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchGradeOptions(inputValue)
    }
  }
  const handleGradeSelectionChange = (grade) => {
    if (!grade) {
      fetchGradeOptions() // Call the function to perform API request
      setGradeOptions([])
    }
  }
  const fetchDepartmentOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.GET_DEPARTMENT
      const payload = {
        keyword: inputValue,
        filter: {},
        page: 1,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setDepartmentOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleDepartmentInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchDepartmentOptions(inputValue)
    }
  }
  const handleDepartmentSelectionChange = (grade) => {
    if (grade) {
      const selectedDepartment = [{ _id: grade.value, name: grade.label }]
      setValue('department', selectedDepartment)
      setSelectedDepartment({ label: grade.label, value: grade.value })
    } else {
      fetchDepartmentOptions() // Call the function to perform API request
      setDepartmentOptions([])
      setValue('department', [])
      setSelectedDepartment(null)
    }
  }
  const fetchRole = async () => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.ROLE_LIST
      const payload = {
        role: selectedRoles.map((role) => role.fullObject),
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const fetchedRoles = response?.data?.data?.results.map((role) => ({
          label: role.name,
          value: role._id,
          fullObject: role,
        }))
        setRoles(fetchedRoles)
      }
    } catch (error) {
      console.error('Error fetching roles:', error)
    } finally {
      setIsLoading(false)
    }
  }
  const fetchReportingToOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.GET_BACKEND_EMPLOYEE
      const payload = {
        keyword: inputValue,
        filter: {},
        page: 1,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setReportingToOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleReportingToInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchReportingToOptions(inputValue)
    }
  }
  const handleReportingToSelectionChange = (reportingTo) => {
    if (!reportingTo) {
      fetchReportingToOptions()
      setReportingToOptions([])
    }
  }
  const handlephotoFileChange = (e) => {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      setPhotoPreview(URL.createObjectURL(selectedFile))
      uploadFile(selectedFile, 'photo')
    }
  }
  const handleSignatureFileChange = (e) => {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      setSignaturePreview(URL.createObjectURL(selectedFile))
      uploadFile(selectedFile, 'signature')
    }
  }
  // const handleLicenseFileChange = (e) => {
  //   const selectedFile = e.target.files[0]
  //   if (selectedFile) {
  //     setLicenseDetails(selectedFile)

  //     uploadFile(selectedFile, 'license')
  //   }
  // }

  // const handlePersonalDocumentChange = (e) => {
  //   const selectedFile = e.target.files[0]
  //   if (selectedFile) {
  //     setPersonalDocument(selectedFile)
  //     uploadFile(selectedFile, 'personalDocument')
  //   }
  // }

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="mt-7 grid grid-cols-2 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <p className="text-xl font-bold text-navy-700">
              {id ? 'Edit' : 'Add'} Employee
            </p>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <div className="flex w-full justify-end">
              <SwitchWithText
                textLeft="OFF"
                textRight="ON"
                checked={isEmployeeActive}
                onChange={changeEmployeeStatus}
              />
            </div>
          </div>
        </div>
        <div className="mt-7 grid grid-cols-2 gap-3">
          <div className="col-span-2 lg:col-span-1 ">
            <label className="mb-2 block text-sm font-bold text-navy-700">
              Company Name
              <span className="text-red-500"> * </span>
            </label>
            <Controller
              name="company"
              control={control}
              rules={{ required: 'Company Name is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={options}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onFocus={() => fetchOptions()}
                  onInputChange={handleInputChange}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(company) => {
                    field.onChange(company)
                    handleSelectionChange(company)
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on error
                      minHeight: '44px',
                      borderRadius: '0.75rem',
                      boxShadow: state.isFocused
                        ? `0 0 0 1px ${
                            error ? 'red' : 'rgba(218, 222, 236, 1)'
                          }` // Red shadow on focus if there's an error
                        : '',
                      '&:hover': {
                        borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Maintain red on hover if error
                      },
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      fontSize: '14px',
                      color: '#999',
                    }),
                  }}
                />
              )}
            />
            {errors.company && (
              <div className="mt-1 text-right text-sm text-red-500">
                {errors.company.message}
              </div>
            )}
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold text-navy-700">
              Salutation
              <span className="text-red-500"> * </span>
            </label>
            <Controller
              name="Salutation"
              control={control}
              rules={{ required: 'Salutation is required' }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={salutationOptions}
                  placeholder="Select salutation"
                  isClearable
                  onInputChange={handleInputChange}
                  classNamePrefix="select"
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderColor: 'rgba(218, 222, 236, 1)',
                      minHeight: '44px',
                      borderRadius: '0.75rem',
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontSize: '14px',
                      color: '#999',
                    }),
                  }}
                />
              )}
            />
            {errors.salutation && (
              <div className="mt-1 text-sm text-red-500">
                {errors.salutation.message}
              </div>
            )}
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="First Name"
              id="FirstName"
              type="text"
              placeholder="Enter first name"
              registrationProps={register('FirstName', {
                required: 'First name is required',
              })}
              isFieldRequired={true}
              state={errors.FirstName && 'error'}
            />
            {errors.FirstName && (
              <div className="mt-1 text-right text-sm text-red-500">
                {errors.FirstName.message}
              </div>
            )}
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="Last Name"
              id="LastName"
              type="text"
              placeholder="Enter last name"
              registrationProps={register('LastName', {
                required: 'Last name is required',
              })}
              isFieldRequired={true}
              state={errors.LastName && 'error'}
            />
            {errors.LastName && (
              <div className="mt-1 text-right text-sm text-red-500">
                {errors.LastName.message}
              </div>
            )}
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold text-navy-700">
              Posted At
              <span className="text-red-500"> * </span>
            </label>

            <Controller
              name="postedAt"
              control={control}
              rules={{ required: 'Posted At is required' }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Select
                    {...field}
                    options={postedAtOptions}
                    placeholder="Search and select..."
                    classNamePrefix="select"
                    isClearable
                    isLoading={isLoading}
                    onFocus={() => fetchPostedAtOptions()}
                    onInputChange={handlePostedAtInputChange}
                    noOptionsMessage={() => 'Type to search...'}
                    onChange={(company) => {
                      field.onChange(company)
                      handlePostedAtSelectionChange(company)
                    }}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on error
                        minHeight: '44px',
                        borderRadius: '0.75rem',
                        boxShadow: state.isFocused
                          ? `0 0 0 1px ${
                              error ? 'red' : 'rgba(218, 222, 236, 1)'
                            }` // Red shadow on focus if there's an error
                          : '',
                        '&:hover': {
                          borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Maintain red on hover if error
                        },
                      }),
                      placeholder: (base) => ({
                        ...base,
                        fontWeight: 'normal',
                        fontSize: '14px',
                        color: '#999',
                      }),
                    }}
                  />

                  {error && (
                    <div className="mt-1 text-right text-sm text-red-500">
                      {error.message}
                    </div>
                  )}
                </>
              )}
            />
          </div>

          <div className="col-span-2 lg:col-span-1 ">
            <label className="mb-2 block text-sm font-bold text-navy-700">
              Function
              <span className="text-red-500"> * </span>
            </label>
            <Controller
              name="func"
              control={control}
              rules={{ required: 'Function is required' }} // Adding validation rule for required field
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={functionOptions}
                  placeholder="Search function"
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onFocus={() => fetchFunctionOptions()}
                  onInputChange={handleFunctionInputChange}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(func) => {
                    field.onChange(func)
                    handleFunctionSelectionChange(func)
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on error
                      minHeight: '44px',
                      borderRadius: '0.75rem',
                      boxShadow: state.isFocused
                        ? `0 0 0 1px ${
                            error ? 'red' : 'rgba(218, 222, 236, 1)'
                          }` // Red shadow on focus if there's an error
                        : '',
                      '&:hover': {
                        borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Maintain red on hover if error
                      },
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      fontSize: '14px',
                      color: '#999',
                    }),
                  }}
                />
              )}
            />
            {errors.func && (
              <div className="mt-1 text-right text-sm text-red-500">
                {errors.func.message}
              </div>
            )}
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold text-navy-700">
              Grade
              <span className="text-red-500"> * </span>
            </label>
            <Controller
              name="grade"
              control={control}
              rules={{ required: 'Grade is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={gradeOptions}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onFocus={() => fetchGradeOptions()}
                  onInputChange={handleGradeInputChange}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(company) => {
                    field.onChange(company)
                    handleGradeSelectionChange(company)
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on error
                      minHeight: '44px',
                      borderRadius: '0.75rem',
                      boxShadow: state.isFocused
                        ? `0 0 0 1px ${
                            error ? 'red' : 'rgba(218, 222, 236, 1)'
                          }` // Red shadow on focus if there's an error
                        : '',
                      '&:hover': {
                        borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Maintain red on hover if error
                      },
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      fontSize: '14px',
                      color: '#999',
                    }),
                  }}
                />
              )}
            />
            {errors.grade && (
              <div className="mt-1 text-right text-sm text-red-500">
                {errors.grade.message}
              </div>
            )}
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold text-navy-700">
              House Color
              <span className="text-red-500"> * </span>
            </label>
            <Controller
              name="houseColor"
              control={control}
              rules={{ required: 'House Color is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={houseColorOptions}
                  placeholder="Select House Color"
                  isClearable
                  onInputChange={handleInputChange}
                  classNamePrefix="select"
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on error
                      minHeight: '44px',
                      borderRadius: '0.75rem',
                      boxShadow: state.isFocused
                        ? `0 0 0 1px ${
                            error ? 'red' : 'rgba(218, 222, 236, 1)'
                          }` // Red shadow on focus if there's an error
                        : '',
                      '&:hover': {
                        borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Maintain red on hover if error
                      },
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      fontSize: '14px',
                      color: '#999',
                    }),
                  }}
                />
              )}
            />
            {errors.houseColor && (
              <div className="mt-1 text-right text-sm text-red-500">
                {errors.houseColor.message}
              </div>
            )}
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold">Branch</label>
            <Controller
              name="branch"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={branchOptions}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onFocus={() => fetchBranchOptions()}
                  onInputChange={handleBranchInputChange}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(branch) => {
                    field.onChange(branch)
                    handleBranchSelectionChange(branch)
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderColor: 'rgba(218, 222, 236, 1)',
                      minHeight: '44px', // Increase height
                      borderRadius: '0.75rem', // Border radius
                      boxShadow: state.isFocused
                        ? `0 0 0 1px rgba(218, 222, 236, 1)` // Red shadow on focus if error
                        : '',
                      '&:hover': {
                        borderColor: 'rgba(218, 222, 236, 1)',
                      },
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      fontSize: '14px',
                      color: '#999',
                    }),
                  }}
                />
              )}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="Employee Code"
              id="employeeCode"
              type="text"
              placeholder="Enter employee code"
              registrationProps={register('employeeCode', {
                required: 'Employee code is required',
              })}
              isFieldRequired={true}
              state={errors.employeeCode && 'error'}
            />
            {errors.employeeCode && (
              <div className="mt-1 text-right text-sm text-red-500">
                {errors.employeeCode.message}
              </div>
            )}
          </div>
          <div>
            <label
              className="mb-2 mt-4 block text-sm font-bold"
              htmlFor="file-upload-photo"
            >
              Upload Photo
            </label>
            <div className="flex items-center space-x-4">
              <div className="mb-4 mt-4">
                <div className="flex space-x-4">
                  <img
                    src={previewPhoto || noImg}
                    alt=" Preview"
                    className="rounded-lg border border-gray-300"
                    style={{ maxWidth: '150px', maxHeight: '150px' }}
                  />
                </div>
              </div>
              <input
                type="file"
                id="file-upload-photo"
                ref={filephotoRef}
                className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
                accept="image/*"
                onChange={handlephotoFileChange}
              />
            </div>
          </div>
          <div>
            <label
              className="mb-2 mt-4 block text-sm font-bold"
              htmlFor="file-upload-signature"
            >
              Upload Signature (1400*500px)
              <span className="text-red-500"> * </span>
            </label>
            <div className="flex items-center space-x-4">
              <div className="mb-4 mt-4">
                <div className="flex items-center space-x-4">
                  <img
                    src={previewSignature || noImg}
                    alt=" Preview"
                    className="rounded-lg border border-gray-300"
                    style={{ maxWidth: '150px', maxHeight: '150px' }}
                  />
                </div>
              </div>
              <input
                type="file"
                id="file-upload-signature"
                ref={fileSignatureRef}
                className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
                accept="image/*"
                onChange={handleSignatureFileChange}
                isFieldRequired={true}
              />
            </div>
            {errors.signature && (
              <div className="mt-1 text-sm text-red-500">
                {errors.signature.message}
              </div>
            )}
          </div>

          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold text-navy-700">
              Role
            </label>
            <Controller
              name="Role"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={roles}
                  placeholder="Select role(s)"
                  isLoading={isLoading}
                  isMulti
                  onFocus={() => fetchRole()}
                  onInputChange={handleInputChange}
                  value={selectedRoles}
                  onChange={(selected) => {
                    setSelectedRoles(selected || [])
                    field.onChange(selected)
                  }}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on error
                      minHeight: '44px',
                      borderRadius: '0.75rem',
                      boxShadow: state.isFocused
                        ? `0 0 0 1px ${
                            error ? 'red' : 'rgba(218, 222, 236, 1)'
                          }` // Red shadow on focus if there's an error
                        : '',
                      '&:hover': {
                        borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Maintain red on hover if error
                      },
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      fontSize: '14px',
                      color: '#999',
                    }),
                  }}
                />
              )}
            />
            {errors.role && (
              <div className="error-message text-right text-red-500">
                {errors.role.message}
              </div>
            )}
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold">
              Reporting To{' '}
            </label>
            <Controller
              name="ReportingTo"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={reportingToOptions}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onFocus={() => fetchReportingToOptions()}
                  onInputChange={handleReportingToInputChange}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(reportingTo) => {
                    field.onChange(reportingTo)
                    handleReportingToSelectionChange(reportingTo) // Call a function to handle API call
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderColor: 'rgba(218, 222, 236, 1)',
                      minHeight: '44px', // Increase height
                      borderRadius: '0.75rem', // Border radius
                      boxShadow: state.isFocused
                        ? `0 0 0 1px rgba(218, 222, 236, 1)` // Red shadow on focus if error
                        : '',
                      '&:hover': {
                        borderColor: 'rgba(218, 222, 236, 1)',
                      },
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      fontSize: '14px',
                      color: '#999',
                    }),
                  }}
                />
              )}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold text-navy-700">
              Offices
              <span className="text-red-500"> * </span>
            </label>
            <Controller
              name="offices"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={branchOptions}
                  placeholder="Select Offices"
                  isLoading={isLoading}
                  isMulti
                  onFocus={() => fetchBranchOptions()}
                  onInputChange={handleOfficesInputChange}
                  value={selectedOffices}
                  onChange={(selected) => {
                    setSelectedOffices(selected || [])
                    field.onChange(selected)
                  }}
                  isFieldRequired={true}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on error
                      minHeight: '44px',
                      borderRadius: '0.75rem',
                      boxShadow: state.isFocused
                        ? `0 0 0 1px ${
                            error ? 'red' : 'rgba(218, 222, 236, 1)'
                          }` // Red shadow on focus if there's an error
                        : '',
                      '&:hover': {
                        borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Maintain red on hover if error
                      },
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      fontSize: '14px',
                      color: '#999',
                    }),
                  }}
                />
              )}
            />
            {errors.offices && (
              <div className="error-message text-right text-red-500">
                {errors.offices.message}
              </div>
            )}
          </div>
        </div>
        <div>
          {/* <div className="flex flex-row justify-between items-center">
            <div>
              <h4 className="mt-7 text-md mb-4 font-bold text-gray-700">
                CTC Details
              </h4>
            </div>
            <div className="flex col-span-2 justify-end items-center lg:col-span-1">
              <button
                onClick={openCreate}
                type="button"
                className="px-4 py-2 bg-brand-500 text-white rounded-lg text-sm hover:bg-brand-400 focus:outline-none focus:ring focus:ring-indigo-200"
              >
                Upload Now
              </button>
            </div>
          </div> */}

          <h4 className="text-md mb-4 mt-7 font-bold text-gray-700">
            NEFT Details
          </h4>
          <div className="mt-7 grid grid-cols-2 gap-3">
            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="Bank Name"
                name="bank"
                id="bank"
                type="text"
                placeholder="Enter Bank Name"
                registrationProps={register('bank')}
                state={errors.bank && 'error'}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="Branch Name"
                id="branchName"
                type="text"
                placeholder="Enter Branch Name"
                registrationProps={register('branchName')}
                state={errors.branchName && 'error'}
              />
            </div>

            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="Account Number"
                id="accountNumber"
                type="numeric"
                placeholder="Enter Account Number"
                registrationProps={register('accountNumber')}
                state={errors.accountNumber && 'error'}
              />
            </div>

            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="NEFT Code"
                id="neftCode"
                type="text"
                placeholder="Enter NEFT Code"
                registrationProps={register('neftCode')}
                state={errors.neftCode && 'error'}
              />
            </div>

            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold text-navy-700">
                City
                <span className="text-red-500"> * </span>
              </label>
              <Controller
                name="city"
                control={control}
                onInputChange={handleInputChange}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    options={cityOptions}
                    placeholder="Search and select..."
                    classNamePrefix="select"
                    isClearable
                    isFieldRequired={true}
                    isLoading={isLoading}
                    onFocus={() => fetchCityOptions()}
                    onInputChange={handleCityInputChange}
                    noOptionsMessage={() => 'Type to search...'}
                    onChange={(city) => {
                      field.onChange(city)
                      handleCitySelectionChange(city)
                    }}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on error
                        minHeight: '44px',
                        borderRadius: '0.75rem',
                        boxShadow: state.isFocused
                          ? `0 0 0 1px ${
                              error ? 'red' : 'rgba(218, 222, 236, 1)'
                            }` // Red shadow on focus if there's an error
                          : '',
                        '&:hover': {
                          borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Maintain red on hover if error
                        },
                      }),
                      placeholder: (base) => ({
                        ...base,
                        fontWeight: 'normal',
                        fontSize: '14px',
                        color: '#999',
                      }),
                    }}
                  />
                )}
              />
              {errors.city && (
                <div className="error-message text-right text-red-500">
                  {errors.city.message}
                </div>
              )}
            </div>

            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold text-navy-700">
                Address
                <span className="text-red-500"> * </span>
              </label>{' '}
              <textarea
                className="mb-2 w-full rounded-md border border-gray-200 p-3 shadow-sm  sm:text-sm"
                id="address"
                placeholder="Enter address"
                {...register('address')}
              ></textarea>
            </div>

            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="Postal Code"
                id="pincode"
                type="text"
                placeholder="Enter postal code"
                registrationProps={register('pincode', {
                  required: 'Postal Code is required',
                })}
                isFieldRequired={true}
              />
              {errors.pincode && (
                <div className="mt-1 text-sm text-red-500">
                  {errors.pincode.message}
                </div>
              )}
            </div>

            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="Latitude"
                id="lat"
                type="number"
                step="any"
                placeholder="Enter latitude"
                registrationProps={register('lat', {
                  valueAsNumber: true,
                })}
              />
            </div>

            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="Longitude"
                id="lng"
                step="any"
                type="number"
                placeholder="Enter longitude"
                registrationProps={register('lng', {
                  valueAsNumber: true,
                })}
              />
            </div>

            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="Office Number"
                id="officeNumber"
                type="text"
                placeholder="Enter office number"
                registrationProps={register('officeNumber')}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="Office Mobile Number"
                id="officeMobile"
                type="number"
                placeholder="Enter mobile number"
                registrationProps={register('officeMobile')}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="Office Email ID"
                id="officeEmail"
                type="email"
                placeholder="Enter office email"
                registrationProps={register('officeEmail', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'Invalid email address',
                  },
                })}
                isFieldRequired={true}
                state={errors.officeEmail && 'error'}
              />
              {errors.officeEmail && (
                <div className="mt-1 text-sm text-red-500">
                  {errors.officeEmail.message}
                </div>
              )}
            </div>

            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="Home Number"
                id="homeNumber"
                type="numeric"
                placeholder="Enter Home number"
                registrationProps={register('homeNumber')}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="Mobile Number"
                id="mobile"
                type="numeric"
                placeholder="Enter mobile number"
                registrationProps={register('mobile')}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="Email ID"
                id="email"
                type="email"
                placeholder="Enter Email ID"
                registrationProps={register('email')}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="Extension Number"
                id="extension"
                type="numeric"
                placeholder="Enter Extension number"
                registrationProps={register('extension')}
              />
            </div>

            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold">
                Gender
                <span className="text-red-500"> * </span>
              </label>
              <Controller
                name="gender"
                control={control}
                rules={{ required: 'Gender is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    options={genderOptions}
                    placeholder="Select Gender"
                    classNamePrefix="select"
                    isClearable
                    onInputChange={handleInputChange}
                    isFieldRequired={true}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        borderColor: 'rgba(218, 222, 236, 1)',
                        minHeight: '44px', // Increase height
                        borderRadius: '0.75rem', // Border radius
                        boxShadow: state.isFocused
                          ? `0 0 0 1px rgba(218, 222, 236, 1)` // Red shadow on focus if error
                          : '',
                        '&:hover': {
                          borderColor: 'rgba(218, 222, 236, 1)',
                        },
                      }),
                      placeholder: (base) => ({
                        ...base,
                        fontWeight: 'normal',
                        fontSize: '14px',
                        color: '#999',
                      }),
                    }}
                  />
                )}
              />
              {errors.gender && (
                <div className="mt-1 text-right text-sm text-red-500">
                  {errors.gender.message}
                </div>
              )}
            </div>
            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold text-navy-700">
                Date of Birth
                <span className="text-red-500"> * </span>
              </label>
              <Controller
                name="birthDate"
                control={control}
                onInputChange={handleInputChange}
                rules={{ required: 'Date of Birth is required' }}
                render={({ field }) => (
                  <input
                    id="birthDate"
                    {...field}
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    type="date"
                    placeholder="DD/MM/YYYY"
                    className="w-full rounded-xl border p-2 uppercase"
                  />
                )}
              />
              {errors.birthDate && (
                <div className="mt-1 text-right text-sm text-red-500">
                  {errors.birthDate.message}
                </div>
              )}
            </div>
            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold text-navy-700">
                Joining Date
                <span className="text-red-500"> * </span>
              </label>
              <Controller
                name="joiningDate"
                onInputChange={handleInputChange}
                rules={{ required: 'Joining Date is required' }}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    type="date"
                    placeholder="DD/MM/YYYY"
                    className="w-full rounded-xl border p-2 uppercase"
                  />
                )}
              />
              {errors.joiningDate && (
                <div className="mt-1 text-right text-sm text-red-500">
                  {errors.joiningDate.message}
                </div>
              )}
            </div>
            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold text-navy-700">
                Marriage Date
              </label>
              <Controller
                name="marriageDate"
                control={control}
                onInputChange={handleInputChange}
                render={({ field }) => (
                  <input
                    {...field}
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    type="date"
                    placeholder="DD/MM/YYYY"
                    className="w-full rounded-xl border p-2 uppercase"
                  />
                )}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold text-navy-700">
                Leaving Date
              </label>
              <Controller
                name="leavingDate"
                control={control}
                onInputChange={handleInputChange}
                render={({ field }) => (
                  <input
                    {...field}
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    type="date"
                    placeholder="DD/MM/YYYY"
                    className="w-full rounded-xl border p-2 uppercase"
                    registrationProps={register('leavingDate')}
                  />
                )}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <span className="mb-2 flex justify-start">
                {' '}
                is SBC (service branch cell)
              </span>
              <SwitchWithText
                textLeft="NO"
                textRight="YES"
                checked={isSBC}
                onChange={changeSBCStatus}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <span className="mb-2 flex justify-start">
                {' '}
                is Field (on field employee)
              </span>
              <SwitchWithText
                textLeft="NO"
                textRight="YES"
                checked={isField}
                onChange={changeFieldStatus}
              />
            </div>
          </div>
          <div>
            <div className=" flex justify-between">
              <h4 className="text-md mb-4 mt-7  flex items-center font-bold text-gray-700">
                Personal Documents
              </h4>
              {/* 
              <div>
                <label
                  htmlFor="personalDocuments"
                  className="cursor-pointer rounded-lg bg-brand-500 px-4 py-2 text-sm text-white hover:bg-brand-400 focus:outline-none focus:ring focus:ring-indigo-200"
                >
                  Upload Document
                </label>
                <input
                  type="file"
                  id="personalDocuments"
                  className="hidden"
                  onChange={handlePersonalDocumentChange}
                />
              </div> */}
            </div>
            <div className="flex flex-row">
              <div className="col-span-2 lg:col-span-1">
                <span className="mb-2 flex justify-start">
                  {' '}
                  License Surveyor
                </span>
                <SwitchWithText
                  textLeft="NO"
                  textRight="YES"
                  checked={isLicenseSurveyor}
                  onChange={changeLicenseSurveyorStatus}
                />
              </div>
            </div>
          </div>
          {/* {isLicenseSurveyor && (
          <div className="flex col-span-2 justify-end items-center lg:col-span-1">
            <button
              type="button"
              onClick={openPopup}
              className="px-4 py-2 bg-brand-500 text-white rounded-lg text-sm hover:bg-brand-400 focus:outline-none focus:ring focus:ring-indigo-200"
            >
              Upload Now
            </button>
          </div>
        )} */}

          {isLicenseSurveyor && (
            <div className="mt-7 grid grid-cols-2 gap-3">
              <div className="col-span-2 flex lg:col-span-1">
                <InputField
                  extra="mb-2 w-full"
                  label="License Number"
                  id="licenseNumber"
                  type="text"
                  placeholder="Enter license number"
                  registrationProps={register('licenseNumber')}
                  isFieldRequired={true}
                  state={errors.licenseNumber && 'error'}
                />
                <div className="error-message text-right">
                  {errors.licenseNumber?.message}
                </div>
              </div>

              <div className="col-span-2 lg:col-span-1">
                <label className="mb-2 block text-sm font-bold text-navy-700">
                  Department
                  <span className="text-red-500"> * </span>
                </label>
                <Controller
                  name="department"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Select
                      {...field}
                      id="department"
                      options={departmentOptions}
                      placeholder="Search and select..."
                      classNamePrefix="select"
                      isClearable
                      isLoading={isLoading}
                      value={selectedDepartment}
                      onFocus={() => fetchDepartmentOptions()}
                      onInputChange={handleDepartmentInputChange}
                      noOptionsMessage={() => 'Type to search...'}
                      onChange={(department) => {
                        field.onChange(department)
                        handleDepartmentSelectionChange(department)
                      }}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderColor: 'rgba(218, 222, 236, 1)',
                          minHeight: '44px',
                          borderRadius: '0.75rem',
                          boxShadow: state.isFocused
                            ? `0 0 0 1px rgba(218, 222, 236, 1)`
                            : '',
                          '&:hover': {
                            borderColor: 'rgba(218, 222, 236, 1)',
                          },
                        }),
                        placeholder: (base) => ({
                          ...base,
                          fontWeight: 'normal',
                          fontSize: '14px',
                          color: '#999',
                        }),
                      }}
                    />
                  )}
                />
                {errors.department && (
                  <div className="error-message text-right text-red-500">
                    {errors.department.message}
                  </div>
                )}
              </div>
              <div className="col-span-2 lg:col-span-1">
                <label className="mb-2 block text-sm font-bold text-navy-700">
                  IIISLA Certificate
                </label>
                <input
                  type="file"
                  className="mb-2 w-full rounded-lg px-3 py-2 text-sm focus:outline-none focus:ring focus:ring-indigo-200"
                  id="certificate"
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <label className="mb-2 block text-sm font-bold text-navy-700">
                  IIISLA Receipt
                </label>
                <input
                  type="file"
                  className="mb-2 w-full rounded-lg px-3  py-2 text-sm focus:outline-none focus:ring focus:ring-indigo-200"
                  id="receipt"
                />
              </div>
            </div>
          )}
        </div>
        {/* {isPopUpOpen && (
          <div className="mt-7 grid grid-cols-2 gap-3">
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-lg font-semibold mb-4">Upload Document</h2>

                <label className="block text-sm font-bold mb-2 text-navy-700">
                  Upload License Document
                </label>
                <input
                  type="file"
                  className="mb-4 w-full px-3 py-2 border rounded-lg text-sm focus:outline-none focus:ring focus:ring-indigo-200"
                  id="image"
                  onChange={handleLicenseFileChange}
                />

                <div className="col-span-2 lg:col-span-1 mb-2">
                  <label className="block text-sm font-bold mb-2 text-navy-700">
                    From
                  </label>
                  <Controller
                    name="licenseDetails[0].from"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="date"
                        className="w-full border rounded-xl p-2"
                      />
                    )}
                  />
                </div>

                <div className="col-span-2 lg:col-span-1 mb-2">
                  <label className="block text-sm font-bold mb-2 text-navy-700">
                    To
                  </label>
                  <Controller
                    name="licenseDetails[0].to"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="date"
                        className="w-full border rounded-xl p-2"
                      />
                    )}
                  />
                </div>

                <div className="flex justify-end mt-3">
                  <button
                    onClick={closePopup}
                    className="mr-2 px-4 py-2 bg-gray-300 text-gray-700 rounded-lg text-sm hover:bg-gray-400"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={closePopup}
                    className="px-4 py-2 bg-brand-500 text-white rounded-lg text-sm hover:bg-brand-300 focus:outline-none focus:ring focus:ring-indigo-200"
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        )} */}

        {/* {isCreateOpen && (
          <div className="mt-7 grid grid-cols-2 gap-3">
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-lg font-semibold mb-4">Add CTC Details</h2>

                <InputField
                  extra="mb-2 w-full"
                  label="CTC Amount"
                  id="amount"
                  type="text"
                  placeholder="Enter CTC Amount"
                  registrationProps={register("CTCDetails[0].amount", {
                    required: "CTC Amount is required",
                  })}
                  state={errors.CTCDetails?.[0]?.amount && "error"}
                />

                <label className="block text-sm font-bold mb-2 text-navy-700"
                htmlFor="file-upload-document">
                  Upload Document
                </label>
                <input
                  type="file"
                  id="file-upload-document"
                  ref={filedocumentRef}
                  
                  className="mb-4 w-full px-3 py-2 rounded-lg text-sm focus:outline-none focus:ring focus:ring-indigo-200"
                  onChange={handleCTCFileChange}
                />

                <div className="col-span-2 lg:col-span-1 mb-2">
                  <label className="block text-sm font-bold mb-2 text-navy-700">
                    From
                  </label>
                  <Controller
                    name="CTCDetails[0].from"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="date"
                        placeholder="DD/MM/YYYY"
                        className="w-full uppercase border rounded-xl p-2"
                      />
                    )}
                  />
                </div>

                <div className="col-span-2 lg:col-span-1 mb-2">
                  <label className="block text-sm font-bold mb-2 text-navy-700">
                    To
                  </label>
                  <Controller
                    name="CTCDetails[0].to"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="date"
                        placeholder="DD/MM/YYYY"
                        className="w-full uppercase border rounded-xl p-2"
                      />
                    )}
                  />
                </div>

                <div className="col-span-2 lg:col-span-1 mb-2">
                  <label className="block text-sm font-bold mb-2 text-navy-700">
                    Grade
                    <span className="text-red-500"> * </span>
                  </label>
                  <Controller
                    name="CTCDetails[0].grade"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Select
                        {...field}
                        options={gradeOptions}
                        placeholder="Search and select..."
                        classNamePrefix="select"
                        isClearable
                        isLoading={isLoading}
                        onInputChange={handleGradeInputChange}
                        noOptionsMessage={() => "Type to search..."}
                        onChange={(selected) => {
                          field.onChange(selected);
                        }}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            borderColor: "rgba(218, 222, 236, 1)",
                            minHeight: "44px",
                            borderRadius: "0.75rem",
                            boxShadow: state.isFocused
                              ? `0 0 0 1px rgba(218, 222, 236, 1)`
                              : "",
                            "&:hover": {
                              borderColor: "rgba(218, 222, 236, 1)",
                            },
                          }),
                          placeholder: (base) => ({
                            ...base,
                            fontWeight: "normal",
                            fontSize: "14px",
                            color: "#999",
                          }),
                        }}
                      />
                    )}
                  />
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="bg-gray-200 text-gray-700 rounded-lg px-4 py-2 mr-2"
                    onClick={() => setIsCreateOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={closeCreate}
                    className="bg-[#4880ff] text-white rounded-lg px-4 py-2"
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        )} */}
        <div className="mt-4 flex w-full justify-end">
          <button
            className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
          hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base`}
            type="submit"
          >
            <span>{id ? 'Update' : 'Save'}</span>
          </button>
          <button
            className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
          hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base`}
            type="button"
            onClick={handleCancel}
          >
            <span>Cancel</span>
          </button>
        </div>
      </form>
    </Card>
  )
}
export default AddEmployee
