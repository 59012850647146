export const officerColumnsDataOrders = [
  {
    Header: 'S.No',
    accessor: 'sNo',
    disableSortBy: true,
  },
  {
    Header: 'Officer Name',
    accessor: (row) => `${row.firstName} ${row.lastName}`,
    disableSortBy: true,
  },
  {
    Header: 'Company Name',
    accessor: (row) => `${row.customer.name}`,
    disableSortBy: true,
  },
  {
    Header: 'ACTIONS',
    accessor: 'COMPANY ACTIONS',
    disableSortBy: true,
  },
]
