import { departmentColumnsDataOrders } from './variables/ColumnsDataOrders'
import DepartmentListTable from './components/List'
import Card from 'components/card'
import apiConfig from 'common/config/apiConfig'
import { useState, useEffect } from 'react'
import { useSpinner } from 'common/SpinnerLoader'
import { useToaster } from 'common/Toaster'
import ApiCaller from 'common/services/apiServices'
import CrawfordConstants from 'common/config/crawfordConstants'
/**
 * Renders the Department List component with the fetched Department data.
 *
 * @return {JSX.Element} The rendered Department List component
 */
const DepartmentList = () => {
  const [allDepartmentData, setDepartmentData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const itemsPerPage = 10 // You can change this to your desired number
  const [isDepartmentLoading, setDepartmentLoading] = useState(false)
  const apiService = ApiCaller()
  const { TOAST } = CrawfordConstants

  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()
  const fetchData = async () => {
    try {
      setDepartmentLoading(true)
      const apiUrl = apiConfig.DEPARTMENT_LIST
      const payload = {
        page: currentPage,
        keyword: searchValue,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        setDepartmentData(response?.data?.data?.results)
        setTotalCount(response?.data?.data?.total || 0)
      } else {
        setDepartmentData([])
        setTotalCount(0)
      }
    } catch (error) {
      console.error('Error during API call:', error)
    } finally {
      setDepartmentLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchValue])

  const toggleDepartmentStatus = async (id, formData, newStatus) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.DEPARTMENT_CREATE
      const payload = {
        status: newStatus,
        _id: id ? id : undefined,
        name: formData.name,
        code: formData.number,
        getAllTaskStatus: formData.getAllTaskStatus,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        fetchData()
        addToast({ title: 'Department update Successfully.', type: 'success' })
      } else {
        addToast({ title: 'Department update failed.', type: 'error' })
      }
    } catch (error) {
      addToast({ title: TOAST.MESSAGES.ERROR.swwError, type: 'error' })
    } finally {
      hideSpinner()
    }
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const setGlobalFilter = (value) => {
    try {
      setCurrentPage(1)
      setSearchValue(value)
    } catch (err) {
      console.log('err', err)
    } finally {
      setDepartmentLoading(false)
    }
  }

  return (
    <>
      <Card extra={'w-full h-full bg-white mt-3'}>
        <DepartmentListTable
          tableData={allDepartmentData}
          columnsData={departmentColumnsDataOrders}
          totalCount={totalCount}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          isDepartmentLoading={isDepartmentLoading}
          setDepartmentLoading={setDepartmentLoading}
          setGlobalFilter={setGlobalFilter}
          toggleDepartmentStatus={toggleDepartmentStatus}
        />
      </Card>
    </>
  )
}

export default DepartmentList
