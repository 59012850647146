import React from 'react'
import CountryList from 'views/Location/Country'
import CountryAdd from 'views/Location/Country/components/AddCountry'
import ZoneList from 'views/Location/Zone'
import ZoneAdd from 'views/Location/Zone/components/AddZone'
import StateList from 'views/Location/State'
import StateAdd from 'views/Location/State/components/AddState'
import DistrictList from 'views/Location/District'
import DistrictAdd from 'views/Location/District/components/AddDistrict'
import CityList from 'views/Location/City'
import CityAdd from 'views/Location/City/components/AddCity'
import { IoEarthSharp } from 'react-icons/io5'
import { FaLocationArrow, FaLocationDot } from 'react-icons/fa6'

const LocationRoutes = [
  {
    name: 'Country',
    layout: '/afterLogin',
    path: '/locations/country-list',
    icon: <IoEarthSharp className="text-inherit h-6 w-6" />,
    component: <CountryList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Locations',
    breadcrum: [
      { label: 'Locations', link: '', breadCrumDisabled: true },
      { label: 'Country', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Country',
    layout: '/afterLogin',
    path: '/locations/country-create',
    icon: <IoEarthSharp className="text-inherit h-6 w-6" />,
    component: <CountryAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Locations',
    breadcrum: [
      { label: 'Locations', link: '', breadCrumDisabled: true },
      { label: 'Country', link: '/locations/country-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Country',
    layout: '/afterLogin',
    path: '/locations/country-edit/:id',
    icon: <IoEarthSharp className="text-inherit h-6 w-6" />,
    component: <CountryAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Locations',
    breadcrum: [
      { label: 'Locations', link: '', breadCrumDisabled: true },
      { label: 'Country', link: '/locations/country-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Zone',
    layout: '/afterLogin',
    path: '/locations/zone-list',
    icon: <FaLocationArrow className="text-inherit h-6 w-6" />,
    component: <ZoneList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Locations',
    breadcrum: [
      { label: 'Locations', link: '', breadCrumDisabled: true },
      { label: 'Zone', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Zone',
    layout: '/afterLogin',
    path: '/locations/zone-create',
    icon: <FaLocationArrow className="text-inherit h-6 w-6" />,
    component: <ZoneAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Locations',
    breadcrum: [
      { label: 'Locations', link: '', breadCrumDisabled: true },
      { label: 'Zone', link: '/locations/zone-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Zone',
    layout: '/afterLogin',
    path: '/locations/zone-edit/:id',
    icon: <FaLocationArrow className="text-inherit h-6 w-6" />,
    component: <ZoneAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Locations',
    breadcrum: [
      { label: 'Locations', link: '', breadCrumDisabled: true },
      { label: 'Zone', link: '/locations/zone-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'State',
    layout: '/afterLogin',
    icon: <FaLocationDot className="text-inherit h-6 w-6" />,
    path: '/locations/state-list',
    component: <StateList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Locations',
    breadcrum: [
      { label: 'Locations', link: '', breadCrumDisabled: true },
      { label: 'State', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'State',
    layout: '/afterLogin',
    icon: <FaLocationDot className="text-inherit h-6 w-6" />,
    path: '/locations/state-create',
    component: <StateAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Locations',
    breadcrum: [
      { label: 'Locations', link: '', breadCrumDisabled: true },
      { label: 'State', link: '/locations/state-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'State',
    layout: '/afterLogin',
    icon: <FaLocationDot className="text-inherit h-6 w-6" />,
    path: '/locations/state-edit/:id',
    component: <StateAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Locations',
    breadcrum: [
      { label: 'Locations', link: '', breadCrumDisabled: true },
      { label: 'State', link: '/locations/state-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'District',
    layout: '/afterLogin',
    icon: <FaLocationDot className="text-inherit h-6 w-6" />,
    path: '/locations/district-list',
    component: <DistrictList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Locations',
    breadcrum: [
      { label: 'Locations', link: '', breadCrumDisabled: true },
      { label: 'District', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'District',
    layout: '/afterLogin',
    icon: <FaLocationDot className="text-inherit h-6 w-6" />,
    path: '/locations/district-create',
    component: <DistrictAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Locations',
    breadcrum: [
      { label: 'Locations', link: '', breadCrumDisabled: true },
      { label: 'District', link: '/locations/district-list' },
      { label: 'Add', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'District',
    layout: '/afterLogin',
    icon: <FaLocationDot className="text-inherit h-6 w-6" />,
    path: '/locations/district-edit/:id',
    component: <DistrictAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Locations',
    breadcrum: [
      { label: 'Locations', link: '', breadCrumDisabled: true },
      { label: 'District', link: '/locations/district-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'City',
    layout: '/afterLogin',
    icon: <FaLocationDot className="text-inherit h-6 w-6" />,
    path: '/locations/city-list',
    component: <CityList />,
    isComing: false,
    isVisible: true,
    breadcrum: [
      { label: 'Locations', link: '', breadCrumDisabled: true },
      { label: 'City', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'City',
    layout: '/afterLogin',
    icon: <FaLocationDot className="text-inherit h-6 w-6" />,
    path: '/locations/city-create',
    component: <CityAdd />,
    isComing: false,
    isVisible: false,
    breadcrum: [
      { label: 'Locations', link: '', breadCrumDisabled: true },
      { label: 'City', link: '/locations/city-list' },
      { label: 'Add', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'City',
    layout: '/afterLogin',
    icon: <FaLocationDot className="text-inherit h-6 w-6" />,
    path: '/locations/city-edit/:id',
    component: <CityAdd />,
    isComing: false,
    isVisible: false,
    breadcrum: [
      { label: 'Locations', link: '', breadCrumDisabled: true },
      { label: 'City', link: '/locations/city-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
]

export default LocationRoutes
