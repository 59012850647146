export const tocColumnsDataOrders = [
  {
    Header: 'S.No',
    accessor: 'index',
    disableSortBy: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
    disableSortBy: true,
  },

  {
    Header: 'Is Insured',
    accessor: 'isInsured',
    disableSortBy: true,
  },

  {
    Header: 'ACTIONS',
    accessor: 'COMPANY ACTIONS',
    disableSortBy: true,
  },
]
