import React, { useRef, useState, useEffect } from 'react'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import Card from 'components/card'
import { pdfjs } from 'react-pdf'
import {
  getEmailPdf,
  pdfGoogleViewer,
  readFileBasedOnId,
} from 'common/commonFunction'
import { useForm, Controller } from 'react-hook-form'
import { useToaster } from 'common/Toaster'
import CrawfordConstants from 'common/config/crawfordConstants'
import { useSpinner } from 'common/SpinnerLoader'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import InputField from 'components/fields/InputField'
import Select from 'react-select'
import SwitchWithText from 'components/switch-with-text'
import noImg from 'assets/img/layers/no-image.png'
import moment from 'moment'
import noDataAvailable from 'assets/img/layers/no-data-available-image.png'
import AddressComponent from 'components/addressComponent/addressComponent'
import Radio from 'components/radio'

const apiService = ApiCaller()
const { TOAST } = CrawfordConstants
// Set the workerSrc to the PDF.js worker file
pdfjs.GlobalWorkerOptions.workerSrc =
  'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.15.349/build/pdf.worker.min.js'

/**
 * Renders a form to add or edit a district.
 *
 * @return {JSX.Element} The rendered form component.
 */
const AddAssignment = () => {
  const navigate = useNavigate()
  let location = useLocation()
  const { id } = useParams()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {},
  })
  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()

  const [pdfUrl, setPdfUrl] = useState('')
  const readUrl = process.env.REACT_APP_API_READ_URL

  const [appointmentFile, setAppointmentFile] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [isAssignment, setAssignment] = useState(true)
  // const [isTypeOfClaim, setTypeOfClaim] = useState(true)
  const [isSiteDetails, setSiteDetails] = useState(false)

  const [isInsured, setIsInsured] = useState(false)
  const [postLoss, setPostLoss] = useState(false)
  const [previewFile, setFilePreview] = useState(null)
  const fileLogoRef = useRef(null) // Create a ref for the file input

  const [companyNameOptions, setCompanyNameOptions] = useState([])
  const [activeBranchOptions, setActiveBranchOptions] = useState([])
  const [natureOfSurveyOptions, setNatureOfSurveyOptions] = useState([])
  const [ownerOptions, setOwnerOptions] = useState([])
  const [surveyorOptions, setSurveyorOptions] = useState([])
  const [customerSegmentOptions, setCustomerSegmentOptions] = useState([])
  const [customerOfficeOptions, setCustomerOfficeOptions] = useState([])
  const [insuredOfficeOptions, setInsuredOfficeOptions] = useState([])
  const [causeOfLossOptions, setCauseOfLossOptions] = useState([])
  const [insurerOfficeOptions, setInsurerOfficeOptions] = useState([])
  const [brokerOfficeOptions, setBrokerOfficeOptions] = useState([])
  const [natureOfLossOptions, setNatureOfLossOptions] = useState([])
  const [natureOfLossIds, setNatureOfLossIds] = useState([])
  const [selectedTypeOfClaim, setSelectedTypeOfClaim] = useState('') // State to hold the selected category
  const [policyTypeOptions, setPolicyTypeOptions] = useState([]) // State to hold the policy type
  const [isPolicyTypeSelected, setIsPolicyTypeSelected] = useState(false) // State to hold if policy type is selected
  const [policyDocOptions, setPolicyDocOptions] = useState([]) // State to hold the policy documents

  const comesFromEmail = location.pathname.includes(
    '/assignments/assignment-create-from-email'
  )

  const handleTypeOfClaimChange = (category) => {
    setSelectedTypeOfClaim(category)
  }

  const changeAssignment = () => {
    setAssignment(!isAssignment)
  }

  const changeIsInsured = () => {
    setIsInsured(!isInsured)
  }

  const changePostLoss = () => {
    setPostLoss(!postLoss)
  }

  const changeIsSiteDetails = () => {
    setSiteDetails(!isSiteDetails)
  }

  const dropDownConfig = (error) => {
    return {
      control: (base, state) => ({
        ...base,
        minHeight: '44px',
        borderRadius: '0.75rem',
        borderWidth: '1px',
        borderColor: error
          ? 'red'
          : state.isFocused
            ? 'rgb(59, 130, 246)' // Tailwind blue-500 for focus
            : 'rgba(218, 222, 236, 1)', // Default gray color

        // Blue outline (ring) on focus, red if error
        boxShadow: state.isFocused
          ? `0 0 0 1px ${error ? 'red' : 'rgb(59, 130, 246)'}`
          : '',

        '&:hover': {
          borderColor: error ? 'red' : 'rgb(59, 130, 246)', // Hover blue if no error
        },
      }),
      placeholder: (base) => ({
        ...base,
        fontWeight: 'normal',
        fontSize: '14px',
        color: error ? 'red' : '#999', // Red placeholder on error
      }),
      menu: (base) => ({
        ...base,
        width: '100%', // Width of the dropdown menu
        maxHeight: '250px', // Max height for the dropdown menu
        borderRadius: '8px',
        zIndex: 49, // Set a higher zIndex to ensure the menu appears above other elements
      }),
      menuList: (base) => ({
        ...base,
        maxHeight: '250px', // Max height of the list container for scrolling
        padding: 0, // Optional padding for the menu list
        zIndex: 49, // Set zIndex for the menu list as well if needed
      }),
      singleValue: (base) => ({
        ...base,
        color: '#333', // Color for selected text
      }),
      dropdownIndicator: (base, state) => ({
        ...base,
        color: state.isFocused ? 'rgb(59, 130, 246)' : 'rgba(156, 163, 175, 1)', // Blue dropdown indicator on focus
        '&:hover': {
          color: 'rgb(59, 130, 246)',
        },
      }),
      indicatorSeparator: (base) => ({
        ...base,
        display: 'none', // Remove separator
      }),
    }
  }

  /**
   * Fetches company name options from the API based on user input.
   * The function is triggered when the user types in the company name input field.
   * It sends a request to the server to fetch matching company names.
   *
   * @async
   * @function fetchOptions
   * @param {string} inputValue - The user-typed value for filtering company options. Defaults to an empty string if not provided.
   * @returns {Promise<void>} A promise that resolves when the company name options are fetched and set.
   */
  const fetchCompanyOptions = async (inputValue) => {
    setIsLoading(true)
    try {
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: {},
        fields: [],
        page: 1,
      }
      const apiUrl = apiConfig.COMPANY_LIST
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setCompanyNameOptions(newOptions)
        if (!id && newOptions.length === 1) {
          setValue('CompanyName', newOptions[0])
        }
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Handles the input change event for the company name field.
   * Calls the API to fetch company name options when the input value is longer than 2 characters.
   *
   * @function handleInputChange
   * @param {string} inputValue - The current input value from the company name input field.
   */
  const handleCompanyInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchCompanyOptions(inputValue)
    }
  }

  /**
   * Handles the selection change event for the company name field.
   * If no company name is selected, it fetches the initial list of company names.
   *
   * @function handleCompanySelectionChange
   * @param {Object|null} CompanyName - The selected company name object or null if nothing is selected.
   */
  const handleCompanySelectionChange = (CompanyName) => {
    if (!CompanyName) {
      fetchCompanyOptions() // Call the function to perform API request
    }
  }

  /**
   * Fetches active branch name options from the API based on user input.
   * The function is triggered when the user types in the active branch input field.
   * It sends a request to the server to fetch matching active branchs.
   *
   * @async
   * @function fetchActiveBranchOptions
   * @param {string} inputValue - The user-typed value for filtering branch options. Defaults to an empty string if not provided.
   * @returns {Promise<void>} A promise that resolves when the active branch options are fetched and set.
   */
  const fetchActiveBranchOptions = async (inputValue) => {
    setIsLoading(true)
    try {
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: {},
        fields: ['name', 'office'],
        page: 1,
      }
      const apiUrl = apiConfig.GET_ACTIVE_BRANCH
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setActiveBranchOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Handles the input change event for the branch office field.
   * Calls the API to fetch branch office options when the input value is longer than 2 characters.
   *
   * @function handleActiveBranchInputChange
   * @param {string} inputValue - The current input value from the branch office input field.
   */
  const handleActiveBranchInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchActiveBranchOptions(inputValue)
    }
  }

  /**
   * Handles the selection change event for the branch office field.
   * If no branch office is selected, it fetches the initial list of branch offices.
   *
   * @function handleActiveBranchSelectionChange
   * @param {Object|null} ActiveBranch - The selected branch office object or null if nothing is selected.
   */
  const handleActiveBranchSelectionChange = (ActiveBranch) => {
    if (!ActiveBranch) {
      fetchActiveBranchOptions() // Call the function to perform API request
    }
  }

  /**
   * Fetches active branch name options from the API based on user input.
   * The function is triggered when the user types in the active branch input field.
   * It sends a request to the server to fetch matching active branchs.
   *
   * @async
   * @function fetchNatureOfSurveyOptions
   * @param {string} inputValue - The user-typed value for filtering branch options. Defaults to an empty string if not provided.
   * @returns {Promise<void>} A promise that resolves when the active branch options are fetched and set.
   */
  const fetchNatureOfSurveyOptions = async (inputValue) => {
    setIsLoading(true)
    try {
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: {},
        fields: ['name'],
        page: 1,
      }
      const apiUrl = apiConfig.GET_DEPARTMENT
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setNatureOfSurveyOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Handles the input change event for the branch office field.
   * Calls the API to fetch branch office options when the input value is longer than 2 characters.
   *
   * @function handleNatureOfSurveyInputChange
   * @param {string} inputValue - The current input value from the branch office input field.
   */
  const handleNatureOfSurveyInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchNatureOfSurveyOptions(inputValue)
    }
  }

  /**
   * Handles the selection change event for the branch office field.
   * If no branch office is selected, it fetches the initial list of branch offices.
   *
   * @function handleNatureOfSurveySelectionChange
   * @param {Object|null} NatureOfSurvey - The selected branch office object or null if nothing is selected.
   */
  const handleNatureOfSurveySelectionChange = (NatureOfSurvey) => {
    if (!NatureOfSurvey) {
      fetchNatureOfSurveyOptions() // Call the function to perform API request
    }
  }

  /**
   * Fetches active Owner name options from the API based on user input.
   * The function is triggered when the user types in the active Owner input field.
   * It sends a request to the server to fetch matching active Owners.
   *
   * @async
   * @function fetchOwnerOptions
   * @param {string} inputValue - The user-typed value for filtering Owner options. Defaults to an empty string if not provided.
   * @returns {Promise<void>} A promise that resolves when the active Owner options are fetched and set.
   */
  const fetchOwnerOptions = async (inputValue) => {
    setIsLoading(true)
    try {
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: {},
        fields: ['name'],
        page: 1,
      }
      const apiUrl = apiConfig.GET_BACKEND_EMPLOYEE
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setOwnerOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Handles the input change event for the owner office field.
   * Calls the API to fetch owner office options when the input value is longer than 2 characters.
   *
   * @function handleOwnerInputChange
   * @param {string} inputValue - The current input value from the owner office input field.
   */
  const handleOwnerInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchOwnerOptions(inputValue)
    }
  }

  /**
   * Handles the selection change event for the owner office field.
   * If no owner office is selected, it fetches the initial list of owner offices.
   *
   * @function handleOwnerSelectionChange
   * @param {Object|null} Owner - The selected owner office object or null if nothing is selected.
   */
  const handleOwnerSelectionChange = (Owner) => {
    if (!Owner) {
      fetchOwnerOptions() // Call the function to perform API request
    }
  }

  /**
   * Fetches active Surveyor name options from the API based on user input.
   * The function is triggered when the user types in the active Surveyor input field.
   * It sends a request to the server to fetch matching active Surveyors.
   *
   * @async
   * @function fetchSurveyorOptions
   * @param {string} inputValue - The user-typed value for filtering Surveyor options. Defaults to an empty string if not provided.
   * @returns {Promise<void>} A promise that resolves when the active Surveyor options are fetched and set.
   */
  const fetchSurveyorOptions = async (inputValue) => {
    setIsLoading(true)
    try {
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: {},
        fields: ['name'],
        page: 1,
      }
      const apiUrl = apiConfig.GET_BACKEND_EMPLOYEE
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setSurveyorOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Handles the input change event for the Surveyor office field.
   * Calls the API to fetch Surveyor office options when the input value is longer than 2 characters.
   *
   * @function handleInputChange
   * @param {string} inputValue - The current input value from the Surveyor office input field.
   */
  const handleSurveyorInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchSurveyorOptions(inputValue)
    }
  }

  /**
   * Handles the selection change event for the Surveyor office field.
   * If no Surveyor office is selected, it fetches the initial list of Surveyor offices.
   *
   * @function handleSurveyorSelectionChange
   * @param {Object|null} Surveyor - The selected Surveyor office object or null if nothing is selected.
   */
  const handleSurveyorSelectionChange = (Surveyor) => {
    if (!Surveyor) {
      fetchSurveyorOptions() // Call the function to perform API request
    }
  }

  /**
   * Fetches active CustomerSegment name options from the API based on user input.
   * The function is triggered when the user types in the active CustomerSegment input field.
   * It sends a request to the server to fetch matching active CustomerSegments.
   *
   * @async
   * @function fetchCustomerSegmentOptions
   * @param {string} inputValue - The user-typed value for filtering CustomerSegment options. Defaults to an empty string if not provided.
   * @returns {Promise<void>} A promise that resolves when the active CustomerSegment options are fetched and set.
   */
  const fetchCustomerSegmentOptions = async (inputValue) => {
    setIsLoading(true)
    try {
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: {},
        fields: ['name'],
        page: 1,
      }
      const apiUrl = apiConfig.SEGMENT_LIST
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setCustomerSegmentOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Handles the input change event for the CustomerSegment office field.
   * Calls the API to fetch CustomerSegment office options when the input value is longer than 2 characters.
   *
   * @function handleInputChange
   * @param {string} inputValue - The current input value from the CustomerSegment office input field.
   */
  const handleCustomerSegmentInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchCustomerSegmentOptions(inputValue)
    }
  }

  /**
   * Handles the selection change event for the CustomerSegment office field.
   * If no CustomerSegment office is selected, it fetches the initial list of CustomerSegment offices.
   *
   * @function handleCustomerSegmentSelectionChange
   * @param {Object|null} CustomerSegment - The selected CustomerSegment office object or null if nothing is selected.
   */
  const handleCustomerSegmentSelectionChange = (CustomerSegment) => {
    if (!CustomerSegment) {
      fetchCustomerSegmentOptions() // Call the function to perform API request
    }
  }

  /**
   * Fetches active CustomerOffice name options from the API based on user input.
   * The function is triggered when the user types in the active CustomerOffice input field.
   * It sends a request to the server to fetch matching active CustomerOffices.
   *
   * @async
   * @function fetchCustomerOfficeOptions
   * @param {string} inputValue - The user-typed value for filtering CustomerOffice options. Defaults to an empty string if not provided.
   * @returns {Promise<void>} A promise that resolves when the active CustomerOffice options are fetched and set.
   */
  const fetchCustomerOfficeOptions = async (inputValue) => {
    if (getValues('CustomerSegment')?.value) {
      try {
        setIsLoading(true)
        const payload = {
          keyword: inputValue ? inputValue : '',
          filter: { customerSegment: getValues('CustomerSegment')?.value },
          fields: ['name', 'customerCompany'],
          page: 1,
        }
        const apiUrl = apiConfig.CUSTOMER_LIST
        const response = await apiService.apiCall('post', apiUrl, payload)
        if (response?.data?.value) {
          const newOptions = response?.data?.data?.results.map((item) => ({
            label: item.name,
            value: item._id,
          }))
          setCustomerOfficeOptions(newOptions)
        }
      } catch (error) {
        console.error('Error fetching options:', error)
      } finally {
        setIsLoading(false)
      }
    } else {
      addToast({
        title: 'Please select customer segment.',
        type: 'warning',
      })
    }
  }

  /**
   * Handles the input change event for the CustomerOffice office field.
   * Calls the API to fetch CustomerOffice office options when the input value is longer than 2 characters.
   *
   * @function handleInputChange
   * @param {string} inputValue - The current input value from the CustomerOffice office input field.
   */
  const handleCustomerOfficeInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchCustomerOfficeOptions(inputValue)
    }
  }

  /**
   * Handles the selection change event for the CustomerOffice office field.
   * If no CustomerOffice office is selected, it fetches the initial list of CustomerOffice offices.
   *
   * @function handleCustomerOfficeSelectionChange
   * @param {Object|null} CustomerOffice - The selected CustomerOffice office object or null if nothing is selected.
   */
  const handleCustomerOfficeSelectionChange = (CustomerOffice) => {
    if (!CustomerOffice) {
      fetchCustomerOfficeOptions() // Call the function to perform API request
    }
  }

  /**
   * Fetches active InsuredOffice name options from the API based on user input.
   * The function is triggered when the user types in the active InsuredOffice input field.
   * It sends a request to the server to fetch matching active InsuredOffices.
   *
   * @async
   * @function fetchInsuredOfficeOptions
   * @param {string} inputValue - The user-typed value for filtering InsuredOffice options. Defaults to an empty string if not provided.
   * @returns {Promise<void>} A promise that resolves when the active InsuredOffice options are fetched and set.
   */
  const fetchInsuredOfficeOptions = async (inputValue) => {
    if (getValues('CustomerSegment')?.value) {
      setIsLoading(true)
      try {
        const payload = {
          keyword: inputValue ? inputValue : '',
          filter: { customerSegment: '6718aa67fa7deb262aa9bffc' },
          fields: ['name', 'customerCompany'],
          page: 1,
        }
        const apiUrl = apiConfig.CUSTOMER_LIST
        const response = await apiService.apiCall('post', apiUrl, payload)
        if (response?.data?.value) {
          const newOptions = response?.data?.data?.results.map((item) => ({
            label: item.name,
            value: item._id,
          }))
          setInsuredOfficeOptions(newOptions)
        }
      } catch (error) {
        console.error('Error fetching options:', error)
      } finally {
        setIsLoading(false)
      }
    } else {
      addToast({
        title: 'Please select customer segment.',
        type: 'warning',
      })
    }
  }

  /**
   * Handles the input change event for the InsuredOffice office field.
   * Calls the API to fetch InsuredOffice office options when the input value is longer than 2 characters.
   *
   * @function handleInputChange
   * @param {string} inputValue - The current input value from the InsuredOffice office input field.
   */
  const handleInsuredOfficeInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchInsuredOfficeOptions(inputValue)
    }
  }

  /**
   * Handles the selection change event for the InsuredOffice office field.
   * If no InsuredOffice office is selected, it fetches the initial list of InsuredOffice offices.
   *
   * @function handleInsuredOfficeSelectionChange
   * @param {Object|null} InsuredOffice - The selected InsuredOffice office object or null if nothing is selected.
   */
  const handleInsuredOfficeSelectionChange = (InsuredOffice) => {
    if (!InsuredOffice) {
      fetchInsuredOfficeOptions() // Call the function to perform API request
    }
  }

  /**
   * Fetches active CauseOfLoss name options from the API based on user input.
   * The function is triggered when the user types in the active CauseOfLoss input field.
   * It sends a request to the server to fetch matching active CauseOfLosss.
   *
   * @async
   * @function fetchCauseOfLossOptions
   * @param {string} inputValue - The user-typed value for filtering CauseOfLoss options. Defaults to an empty string if not provided.
   * @returns {Promise<void>} A promise that resolves when the active CauseOfLoss options are fetched and set.
   */
  const fetchCauseOfLossOptions = async (inputValue) => {
    if (getValues('NatureOfSurvey')?.value) {
      setIsLoading(true)
      try {
        const payload = {
          keyword: inputValue ? inputValue : '',
          filter: { department: getValues('NatureOfSurvey')?.value },
          fields: ['name', 'customerCompany'],
          page: 1,
        }
        const apiUrl = apiConfig.GET_CAUSE_LOSS
        const response = await apiService.apiCall('post', apiUrl, payload)
        if (response?.data?.value) {
          const newOptions = response?.data?.data?.results.map((item) => ({
            label: item.name,
            value: item._id,
          }))
          setCauseOfLossOptions(newOptions)
        }
      } catch (error) {
        console.error('Error fetching options:', error)
      } finally {
        setIsLoading(false)
      }
    } else {
      addToast({
        title: 'Please select nature of survey',
        type: 'warning',
      })
    }
  }

  /**
   * Handles the input change event for the CauseOfLoss office field.
   * Calls the API to fetch CauseOfLoss office options when the input value is longer than 2 characters.
   *
   * @function handleInputChange
   * @param {string} inputValue - The current input value from the CauseOfLoss office input field.
   */
  const handleCauseOfLossInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchCauseOfLossOptions(inputValue)
    }
  }

  /**
   * Handles the selection change event for the CauseOfLoss office field.
   * If no CauseOfLoss office is selected, it fetches the initial list of CauseOfLoss offices.
   *
   * @function handleCauseOfLossSelectionChange
   * @param {Object|null} CauseOfLoss - The selected CauseOfLoss office object or null if nothing is selected.
   */
  const handleCauseOfLossSelectionChange = (CauseOfLoss) => {
    if (!CauseOfLoss) {
      fetchCauseOfLossOptions() // Call the function to perform API request
    } else {
      setValue('NatureOfLoss', null)
    }
  }

  /**
   * Fetches active InsurerOffice name options from the API based on user input.
   * The function is triggered when the user types in the active InsurerOffice input field.
   * It sends a request to the server to fetch matching active InsurerOffices.
   *
   * @async
   * @function fetchInsurerOfficeOptions
   * @param {string} inputValue - The user-typed value for filtering InsurerOffice options. Defaults to an empty string if not provided.
   * @returns {Promise<void>} A promise that resolves when the active InsurerOffice options are fetched and set.
   */
  const fetchInsurerOfficeOptions = async (inputValue) => {
    setIsLoading(true)
    try {
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: { customerSegment: '6718aa60fa7deb262aa9bfe9' },
        fields: ['name', 'customerCompany'],
        page: 1,
      }
      const apiUrl = apiConfig.CUSTOMER_LIST
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setInsurerOfficeOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Handles the input change event for the InsurerOffice field.
   * Calls the API to fetch InsurerOffice options when the input value is longer than 2 characters.
   *
   * @function handleInputChange
   * @param {string} inputValue - The current input value from the InsurerOffice input field.
   */
  const handleInsurerOfficeInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchInsurerOfficeOptions(inputValue)
    }
  }

  /**
   * Handles the selection change event for the InsurerOffice field.
   * If no InsurerOffice is selected, it fetches the initial list of InsurerOffice.
   *
   * @function handleInsurerOfficeSelectionChange
   * @param {Object|null} InsurerOffice - The selected InsurerOffice object or null if nothing is selected.
   */
  const handleInsurerOfficeSelectionChange = (InsurerOffice) => {
    if (!InsurerOffice) {
      fetchInsurerOfficeOptions() // Call the function to perform API request
    }
  }

  /**
   * Fetches active BrokerOffice name options from the API based on user input.
   * The function is triggered when the user types in the active BrokerOffice input field.
   * It sends a request to the server to fetch matching active BrokerOffices.
   *
   * @async
   * @function fetchBrokerOfficeOptions
   * @param {string} inputValue - The user-typed value for filtering BrokerOffice options. Defaults to an empty string if not provided.
   * @returns {Promise<void>} A promise that resolves when the active BrokerOffice options are fetched and set.
   */
  const fetchBrokerOfficeOptions = async (inputValue) => {
    setIsLoading(true)
    try {
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: { customerSegment: '67208924fc6b1f5030157cf2' },
        fields: ['name', 'customerCompany'],
        page: 1,
      }
      const apiUrl = apiConfig.CUSTOMER_LIST
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setBrokerOfficeOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Handles the input change event for the BrokerOffice field.
   * Calls the API to fetch BrokerOffice options when the input value is longer than 2 characters.
   *
   * @function handleInputChange
   * @param {string} inputValue - The current input value from the BrokerOffice input field.
   */
  const handleBrokerOfficeInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchBrokerOfficeOptions(inputValue)
    }
  }

  /**
   * Handles the selection change event for the BrokerOffice field.
   * If no BrokerOffice is selected, it fetches the initial list of BrokerOffice.
   *
   * @function handleBrokerOfficeSelectionChange
   * @param {Object|null} BrokerOffice - The selected BrokerOffice object or null if nothing is selected.
   */
  const handleBrokerOfficeSelectionChange = (BrokerOffice) => {
    if (!BrokerOffice) {
      fetchBrokerOfficeOptions() // Call the function to perform API request
    }
  }

  /**
   * Fetches active NatureOfLoss name options from the API based on user input.
   * The function is triggered when the user types in the active NatureOfLoss input field.
   * It sends a request to the server to fetch matching active NatureOfLosss.
   *
   * @async
   * @function fetchNatureOfLossOptions
   * @param {string} inputValue - The user-typed value for filtering NatureOfLoss options. Defaults to an empty string if not provided.
   * @returns {Promise<void>} A promise that resolves when the active NatureOfLoss options are fetched and set.
   */
  const fetchNatureOfLossOptions = async (inputValue) => {
    if (getValues('CauseOfLoss')?.value) {
      setIsLoading(true)
      try {
        const payload = {
          keyword: inputValue ? inputValue : '',
          filter: { _id: getValues('CauseOfLoss')?.value },
          fields: [],
          page: 1,
        }
        const apiUrl = apiConfig.GET_NATURE_LOSS
        const response = await apiService.apiCall('post', apiUrl, payload)
        if (response?.data?.value) {
          const newOptions = response?.data?.data?.results.map((item) => ({
            label: item.name,
            value: item._id,
          }))
          setNatureOfLossOptions(newOptions)
        }
      } catch (error) {
        console.error('Error fetching options:', error)
      } finally {
        setIsLoading(false)
      }
    } else {
      addToast({
        title: 'Please select cause of loss',
        type: 'warning',
      })
    }
  }

  /**
   * Handles the input change event for the NatureOfLoss field.
   * Calls the API to fetch NatureOfLoss options when the input value is longer than 2 characters.
   *
   * @function handleInputChange
   * @param {string} inputValue - The current input value from the NatureOfLoss input field.
   */
  const handleNatureOfLossInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchNatureOfLossOptions(inputValue)
    }
  }

  /**
   * Handles the selection change event for the NatureOfLoss field.
   * If no NatureOfLoss is selected, it fetches the initial list of NatureOfLoss.
   *
   * @function handleNatureOfLossSelectionChange
   * @param {Object|null} NatureOfLoss - The selected NatureOfLoss object or null if nothing is selected.
   */
  const handleNatureOfLossSelectionChange = (NatureOfLoss) => {
    if (!NatureOfLoss) {
      fetchNatureOfLossOptions() // Call the function to perform API request
    } else {
      const values = NatureOfLoss.map((item) => item.value)
      setNatureOfLossIds(values)
    }
  }

  /**
   * Fetches PolicyType options from the API based on the input value.
   * If Department is selected, filters the options accordingly.
   *
   * @async
   * @function fetchPolicyTypeOptions
   * @param {string} inputValue - The current input value for PolicyType search.
   */
  const fetchPolicyTypeOptions = async (inputValue) => {
    if (getValues('NatureOfSurvey')?.value) {
      setIsLoading(true)
      try {
        const payload = {
          keyword: inputValue || '',
          filter: {
            department: getValues('NatureOfSurvey')?.value,
            insurer: getValues('InsurerOffice')?.value,
          },
          fields: ['name'],
          page: 1,
          accessToken: process.env.REACT_APP_GOOGLE_KEY,
        }
        console.log('Fetching policy', payload)
        const apiUrl = apiConfig.POLICY_TYPE_LIST // Define the endpoint for fetching PolicyType options
        const response = await apiService.apiCall('post', apiUrl, payload)
        console.log('Response from API', response.data.data)
        if (response?.data?.value) {
          const newOptions = response?.data?.data?.results.map((item) => ({
            label: item.name,
            value: item._id,
          }))
          setPolicyTypeOptions(newOptions)
        }
      } catch (error) {
        console.error('Error fetching PolicyType :', error)
      } finally {
        setIsLoading(false)
      }
    } else {
      addToast({
        title: 'Please select Nature Of Survey',
        type: 'warning',
      })
    }
  }

  /**
   * Handles the input change event for the PolicyType field.
   * Calls the API to fetch PolicyType options when the input value is longer than 0 characters.
   *
   * @function handlePolicyTypeInputChange
   * @param {string} inputValue - The current input value from the PolicyType input field.
   */
  const handlePolicyTypeInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchPolicyTypeOptions(inputValue)
    }
  }

  /**
   * Handles the selection change event for the PolicyType field.
   * If no Department is selected, it fetches the initial list of PolicyType options.
   *
   * @function handlePolicyTypeSelectionChange
   * @param {Object|null} policyType - The selected PolicyType object or null if nothing is selected.
   */
  const handlePolicyTypeSelectionChange = (policyType) => {
    if (policyType) {
      setIsPolicyTypeSelected(true) // Set to true when a PolicyType is selected
      fetchPolicyTypeOptions(policyType.name) // Fetch options with selected PolicyType
    } else {
      setIsPolicyTypeSelected(false) // Set to false if selection is cleared
      fetchPolicyTypeOptions() // Fetch initial PolicyType options if none is selected
    }
  }

  /**
   * Fetches PolicyDoc options from the API based on the input value.
   * If PolicyType is selected, filters the options accordingly.
   *
   * @async
   * @function fetchPolicyDocOptions
   * @param {string} inputValue - The current input value for PolicyType search.
   */
  const fetchPolicyDocOptions = async (inputValue) => {
    if (getValues('PolicyType')?.value) {
      setIsLoading(true)
      try {
        const payload = {
          keyword: inputValue || '',
          filter: {
            policyType: getValues('PolicyType')?.value,
            insurerOffice: getValues('InsurerOffice')?.value,
            insuredOffice: getValues('InsuredOffice')?.value,
          },
          fields: ['name'],
          page: 1,
          accessToken: process.env.REACT_APP_GOOGLE_KEY,
        }
        console.log('Fetching policydoc', payload)
        const apiUrl = apiConfig.POLICY_DOCUMENT_LIST // Define the endpoint for fetching PolicyType options
        const response = await apiService.apiCall('post', apiUrl, payload)
        console.log('Response from API', response.data.data)
        if (response?.data?.value) {
          const newOptions = response?.data?.data?.results.map((item) => ({
            label: item.name,
            value: item._id,
          }))
          setPolicyDocOptions(newOptions)
        }
      } catch (error) {
        console.error('Error fetching PolicyType :', error)
      } finally {
        setIsLoading(false)
      }
    } else {
      addToast({
        title: 'Please select Department',
        type: 'warning',
      })
    }
  }

  /**
   * Handles the input change event for the PolicyType field.
   * Calls the API to fetch PolicyDoc options when the input value is longer than 0 characters.
   *
   * @function handlePolicyDocInputChange
   * @param {string} inputValue - The current input value from the PolicyType input field.
   */
  const handlePolicyDocInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchPolicyTypeOptions(inputValue)
    }
  }

  /**
   * Handles the selection change event for the PolicyType field.
   * If no PolicyType is selected, it fetches the initial list of PolicyType options.
   *
   * @function handlePolicyTypeSelectionChange
   * @param {Object|null} policyType - The selected PolicyType object or null if nothing is selected.
   */
  const handlePolicyDocSelectionChange = (policyDoc) => {
    if (policyDoc) {
      fetchPolicyDocOptions(policyDoc.name) // Fetch options with selected PolicyType
    } else {
      fetchPolicyDocOptions() // Fetch initial PolicyType options if none is selected
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const payload = { messageId: id }
        const getEmailPdfDetail = await getEmailPdf(payload)

        if (getEmailPdfDetail?.data?.value) {
          const pdfName = getEmailPdfDetail.data.data.name
          const response = await pdfGoogleViewer(readUrl, pdfName)

          if (response?.data?.s3Url) {
            setPdfUrl(
              `https://docs.google.com/viewer?url=${response.data.s3Url}&embedded=true`
            )
          } else {
            console.error('Failed to retrieve PDF URL:', response)
          }
        }
      } catch (error) {
        console.error('Error fetching PDF:', error)
      } finally {
        setIsLoading(false)
      }
    }
    if (comesFromEmail) {
      fetchData()
    }
  }, [id, readUrl])

  const uploadFile = async (file) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.UPLOAD_IMAGE
      const data = new FormData()
      data.append('file', file)
      const response = await apiService.apiCall('post', apiUrl, data)
      if (response?.data?.value) {
        setAppointmentFile(response?.data?.data[0])
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleAppointmentFileChange = (e) => {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      setFilePreview(URL.createObjectURL(selectedFile)) // Create a preview URL
      uploadFile(selectedFile)
    }
  }

  const getFormattedCityName = (data) => {
    const {
      city: {
        name: cityName,
        _id: cityId,
        district: {
          name: districtName,
          state: {
            name: stateName,
            zone: { name: zoneName, country: { name: countryName } = {} } = {},
          } = {},
        } = {},
      } = {},
    } = data
    return {
      label: `${cityName}, ${districtName}, ${stateName}, ${zoneName}, ${countryName}`,
      value: cityId,
    }
  }

  /**
   * Fetches the details of a specific company and sets the form values.
   *
   * @async
   * @function getCompanyDetails
   * @returns {Promise<void>} A promise that resolves when the company details are fetched and the form values are set.
   */
  const getAssignmentDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.ASSIGNMENT_DETAIL
      const payload = {
        _id: id,
        checkRoles: false,
        accessOf: ['assignmentEdit'],
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data === 'Access Denied') {
        addToast({
          title: 'Access Denied',
          type: 'error',
        })
        navigate(`/assignments/assignment-list`)
      } else if (response?.data?.value) {
        const assignmentDetail = response?.data?.data
        if (assignmentDetail?.appointment) {
          const appointmentURL = await readFileBasedOnId(
            assignmentDetail?.appointment
          )
          setFilePreview(appointmentURL)
        }
        setAssignment(assignmentDetail?.status)
        setValue(
          'CompanyName',
          {
            label: assignmentDetail?.company?.name,
            value: assignmentDetail?.company?._id,
          } || {}
        )
        setValue(
          'BranchOffice',
          {
            label: assignmentDetail?.branch?.name,
            value: assignmentDetail?.branch?._id,
          } || {}
        )
        setValue(
          'Owner',
          {
            label: assignmentDetail?.owner?.name,
            value: assignmentDetail?.owner?._id,
          } || {}
        )
        setValue(
          'NatureOfSurvey',
          {
            label: assignmentDetail?.department?.name,
            value: assignmentDetail?.department?._id,
          } || {}
        )
        setSelectedTypeOfClaim(assignmentDetail?.typeOfClaim)
        setIsInsured(assignmentDetail?.isInsured)
        setPostLoss(assignmentDetail?.postLoss)
        setValue(
          'CustomerSegment',
          {
            label: assignmentDetail?.segment?.name,
            value: assignmentDetail?.segment?._id,
          } || {}
        )

        setValue(
          'CustomerOffice',
          {
            label: assignmentDetail?.customer?.name,
            value: assignmentDetail?.customer?._id,
          } || {}
        )
        setValue(
          'InsuredOffice',
          {
            label: assignmentDetail?.insuredOffice?.name,
            value: assignmentDetail?.insuredOffice?._id,
          } || {}
        )
        setValue(
          'CauseOfLoss',
          {
            label: assignmentDetail?.causeOfLoss?.name,
            value: assignmentDetail?.causeOfLoss?._id,
          } || {}
        )
        setValue(
          'Surveyor',
          {
            label: assignmentDetail?.surveyor?.name,
            value: assignmentDetail?.surveyor?._id,
          } || {}
        )
        if (assignmentDetail?.natureOfLoss.length > 0) {
          const transformedNatureOfLoss = assignmentDetail?.natureOfLoss.map(
            (item) => ({
              value: item._id,
              label: item.name,
            })
          )
          setValue('NatureOfLoss', transformedNatureOfLoss || [])
        }
        setValue('intimatedLoss', assignmentDetail?.intimatedLoss)
        setValue('insuredClaimNo', assignmentDetail?.insuredClaimId)
        setValue(
          'InsurerOffice',
          {
            label: assignmentDetail?.insurerOffice?.name,
            value: assignmentDetail?.insurerOffice?._id,
          } || {}
        )
        setValue('insurerClaimNo', assignmentDetail?.insurerClaimId)
        setValue(
          'BrokerOffice',
          {
            label: assignmentDetail?.brokerOffice?.name,
            value: assignmentDetail?.brokerOffice?._id,
          } || {}
        )
        setValue('brokerClaimNo', assignmentDetail?.brokerClaimId)
        setValue(
          'PolicyType',
          {
            label: assignmentDetail?.policyType?.name,
            value: assignmentDetail?.policyType?._id,
          } || {}
        )
        setValue(
          'PolicyDocument',
          {
            label: assignmentDetail?.policyDoc?.name,
            value: assignmentDetail?.policyDoc?._id,
          } || {}
        )
        setValue('City', getFormattedCityName(assignmentDetail))
        setValue('Address', assignmentDetail?.address)
        setValue('PostalCode', assignmentDetail?.pincode)
        setValue('Latitude', assignmentDetail?.lat)
        setValue('Longitude', assignmentDetail?.lng)
        setValue(
          'OfficeCity',
          getFormattedCityName(assignmentDetail?.officeCity)
        )
        setValue('OfficeAddress', assignmentDetail?.officeAddress)
        setValue('OfficePostalCode', assignmentDetail?.officePincode)
        setValue('OfficeLatitude', assignmentDetail?.officeLat)
        setValue('OfficeLongitude', assignmentDetail?.officeLng)

        setValue(
          'HomeCity',
          getFormattedCityName(
            assignmentDetail?.homeCity || assignmentDetail?.officeCity
          )
        )
        setValue(
          'HomeAddress',
          assignmentDetail?.homeAddress || assignmentDetail?.officeAddress
        )
        setValue(
          'HomePostalCode',
          assignmentDetail?.homePincode || assignmentDetail?.officePincode
        )
        setValue(
          'HomeLatitude',
          assignmentDetail?.homeLat || assignmentDetail?.officeLat
        )
        setValue(
          'HomeLongitude',
          assignmentDetail?.homeLng || assignmentDetail?.officeLng
        )
        setValue('sitePerson', assignmentDetail?.siteNumber)
        setValue('sitePhone', assignmentDetail?.siteMobile)
        setValue('SiteEmail', assignmentDetail?.siteEmail)
        setValue(
          'dateOfAppointment',
          assignmentDetail?.dateOfAppointment
            ? moment(new Date(assignmentDetail?.dateOfAppointment)).format(
                'YYYY-MM-DD'
              )
            : null
        )
        setValue(
          'dateOfIntimation',
          assignmentDetail?.dateOfIntimation
            ? moment(new Date(assignmentDetail?.dateOfIntimation)).format(
                'YYYY-MM-DD'
              )
            : null
        )
        setValue(
          'dateOfLoss',
          assignmentDetail?.dateOfLoss
            ? moment(new Date(assignmentDetail?.dateOfLoss)).format(
                'YYYY-MM-DD'
              )
            : null
        )
        setSiteDetails(assignmentDetail?.showAddressForDesktop)
      } else {
        addToast({
          title: 'Some Error Occured',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error verifying token:', error)
    } finally {
      hideSpinner()
    }
  }

  /**
   * Handles form submission for saving or editing assignments.
   * This function collects data from the form, prepares a payload,
   * sends it via an API call, and handles success or error responses.
   *
   * @param {Object} formData - The data from the form.
   * @param {Object} formData.CompanyName - The company information from the form.
   * @param {Object} formData.CustomerOffice - The customer office information from the form.
   * @param {Object} formData.BranchOffice - The branch office information from the form.
   * @param {Object} formData.NatureOfSurvey - The department or nature of the survey.
   * @param {Object} formData.Owner - The owner of the assignment.
   * @param {string} formData.intimatedLoss - The intimated loss value from the form.
   * @param {string} formData.dateOfAppointment - The date of appointment for the assignment.
   * @param {string} formData.dateOfIntimation - The date of intimation of the loss.
   * @param {Object} formData.InsuredOffice - The insured office information from the form.
   * @param {string} formData.insuredClaimNo - The insured claim ID.
   * @param {Object} formData.CauseOfLoss - The cause of loss information from the form.
   * @param {Object[]} natureOfLossIds - The array of IDs representing the nature of the loss.
   * @param {Object} formData.InsurerOffice - The insurer office information from the form.
   * @param {string} formData.brokerClaimNo - The broker claim number from the form.
   * @param {Object} formData.BrokerOffice - The broker office information from the form.
   * @param {Object} formData.Department - The policy department information from the form.
   * @param {string} formData.dateOfLoss - The date of the loss event.
   * @param {Object} formData.City - The city information from the form.
   * @param {string} formData.Address - The address of the insured.
   * @param {string} formData.PostalCode - The postal code (pincode).
   * @param {string} formData.Latitude - The latitude of the insured site.
   * @param {string} formData.Longitude - The longitude of the insured site.
   * @param {string} formData.SitePerson - The contact person on-site.
   * @param {string} formData.sitePhone - The contact phone number of the site person.
   * @param {string} formData.SiteEmail - The contact email of the site person.
   * @param {string} id - The unique ID for the assignment (if available).
   * @param {string} selectedTypeOfClaim - A string indicating if the assignment is a specific type of claim.
   * @param {boolean} isInsured - A boolean indicating if the customer is insured.
   * @param {boolean} postLoss - A boolean indicating if it's a post-loss event.
   *
   * @returns {Promise<void>} - A promise that resolves when the form submission is complete.
   */
  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.ASSIGNMENT_SAVE
      const payload = {
        _id: id ? id : undefined,
        status: isAssignment,
        company: formData?.CompanyName?.value,
        branch: formData?.BranchOffice?.value,
        department: formData?.NatureOfSurvey?.value,
        owner: formData?.Owner?.value,
        surveyor: formData?.Surveyor?.value || {},
        typeOfClaim: selectedTypeOfClaim,
        isInsured: isInsured,
        postLoss: postLoss,
        segment: formData?.CustomerSegment?.value,
        customer: formData?.CustomerOffice?.value,
        intimatedLoss: formData?.intimatedLoss,
        dateOfAppointment: formData?.dateOfAppointment,
        dateOfIntimation: formData?.dateOfIntimation,
        insuredOffice: formData?.InsuredOffice?.value,
        insuredClaimId: formData?.insuredClaimNo,
        causeOfLoss: formData?.CauseOfLoss?.value,
        natureOfLoss: id
          ? getValues('NatureOfLoss').map((item) => item.value)
          : natureOfLossIds,
        insurerOffice: formData?.InsurerOffice?.value,
        insurerClaimId: formData?.insuredClaimNo,
        brokerOffice: formData?.BrokerOffice?.value,
        brokerClaimId: formData?.brokerClaimNo,
        dateOfLoss: formData?.dateOfLoss,
        city: formData?.City?.value,
        address: formData?.Address,
        pincode: formData?.PostalCode,
        lat: formData?.Latitude,
        lng: formData?.Longitude,
        siteNumber: formData?.sitePerson,
        siteMobile: formData?.sitePhone,
        siteEmail: formData?.SiteEmail,
        appointment: appointmentFile ? appointmentFile : undefined,
        showAddressForDesktop: isSiteDetails,
        policyType: formData?.PolicyType?.value,
        policyDoc: formData?.PolicyDocument?.value,
        homeCity: formData?.HomeCity?.value, // Assuming HomeCity is in the form data
        homeAddress: formData?.HomeAddress, // Assuming HomeAddress is in the form data
        homePincode: formData?.HomePostalCode, // Assuming HomePostalCode is in the form data
        homeLat: formData?.HomeLatitude, // Assuming HomeLatitude is in the form data
        homeLng: formData?.HomeLongitude, // Assuming HomeLongitude is in the form data
        officeCity: formData?.OfficeCity?.value, // Assuming OfficeCity is in the form data
        officeAddress: formData?.OfficeAddress, // Assuming OfficeAddress is in the form data
        officePincode: formData?.OfficePostalCode, // Assuming OfficePostalCode is in the form data
        officeLat: formData?.OfficeLatitude, // Assuming OfficeLatitude is in the form data
        officeLng: formData?.OfficeLongitude, // Assuming OfficeLongitude is in the form data
        getAllTaskStatus: {
          ila: 'Not Done',
          lor: 'Not Done',
          survey: 'Not Done',
          consent: 'Not Done',
          assessment: 'Not Done',
          fsr: 'Not Done',
          invoice: 'Not Done',
          surveyAssigned: 'Not Done',
          docs: 'Not Done',
          dispatched: 'Not Done',
          delivered: 'Not Done',
          collected: 'Not Done',
          forceClosed: 'Not Done',
          OnHold: 'Not Done',
          ReOpened: 'Not Done',
        },
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        addToast({
          title: `Assignment "${formData.CompanyName.label}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        navigate(`/assignments/assignment-list`)
      } else {
        addToast({
          title: `Assignment edit failed.`,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  const handleCancel = () => {
    navigate(`/assignments/assignment-list`)
  }

  useEffect(() => {
    if (id) {
      getAssignmentDetails()
    } else {
      fetchCompanyOptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
      <div className="mt-7 grid grid-cols-2 gap-3">
        <div className="col-span-2 lg:col-span-1">
          <p className="text-xl font-bold text-navy-700">
            {id ? 'Edit' : 'Add'} Assignment
          </p>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <div className="flex w-full justify-end">
            <label className="mb-2 mr-2 mt-2 block text-sm font-bold">
              Status
            </label>
            <SwitchWithText
              textLeft="OFF"
              textRight="ON"
              checked={isAssignment}
              onChange={changeAssignment}
            />
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="grid grid-cols-1 gap-3">
          <div className="mt-2 grid grid-cols-1 gap-3">
            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold">
                Company Name <span className="text-red-500">*</span>
              </label>
              <Controller
                name="CompanyName"
                control={control}
                rules={{ required: 'Company name is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    options={companyNameOptions}
                    placeholder="Search and select..."
                    classNamePrefix="select"
                    isClearable
                    isLoading={isLoading}
                    onInputChange={handleCompanyInputChange}
                    onFocus={() => fetchCompanyOptions()}
                    noOptionsMessage={() => 'Type to search...'}
                    onChange={(CompanyName) => {
                      field.onChange(CompanyName)
                      handleCompanySelectionChange(CompanyName) // Call a function to handle API call
                    }}
                    styles={dropDownConfig(error)}
                  />
                )}
              />
              {errors.CompanyName && (
                <div className="error-message text-right text-red-500">
                  {errors.CompanyName.message}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-4 grid grid-cols-2 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold">
              Branch Office <span className="text-red-500">*</span>
            </label>
            <Controller
              name="BranchOffice"
              control={control}
              rules={{ required: 'Branch office is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={activeBranchOptions}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handleActiveBranchInputChange}
                  onFocus={() => fetchActiveBranchOptions()}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(BranchOffice) => {
                    field.onChange(BranchOffice)
                    handleActiveBranchSelectionChange(BranchOffice) // Call a function to handle API call
                  }}
                  styles={dropDownConfig(error)}
                />
              )}
            />
            {errors.BranchOffice && (
              <div className="error-message text-right text-red-500">
                {errors.BranchOffice.message}
              </div>
            )}
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold">
              Nature of Survey <span className="text-red-500">*</span>
            </label>
            <Controller
              name="NatureOfSurvey"
              control={control}
              rules={{ required: 'Nature of survey is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={natureOfSurveyOptions}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handleNatureOfSurveyInputChange}
                  onFocus={() => fetchNatureOfSurveyOptions()}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(NatureOfSurvey) => {
                    field.onChange(NatureOfSurvey)
                    handleNatureOfSurveySelectionChange(NatureOfSurvey) // Call a function to handle API call
                    if (!NatureOfSurvey) {
                      setValue('CauseOfLoss', null)
                      setValue('PolicyType', null)
                    }
                  }}
                  styles={dropDownConfig(error)}
                />
              )}
            />
            {errors.NatureOfSurvey && (
              <div className="error-message text-right text-red-500">
                {errors.NatureOfSurvey.message}
              </div>
            )}
          </div>

          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold">
              Owner <span className="text-red-500">*</span>
            </label>
            <Controller
              name="Owner"
              control={control}
              rules={{ required: 'Owner is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={ownerOptions}
                  placeholder="Search and select..."
                  disabled={true}
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handleOwnerInputChange}
                  onFocus={() => fetchOwnerOptions()}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(Owner) => {
                    field.onChange(Owner)
                    handleOwnerSelectionChange(Owner) // Call a function to handle API call
                  }}
                  styles={dropDownConfig(error)}
                />
              )}
            />
            {errors.Owner && (
              <div className="error-message text-right text-red-500">
                {errors.Owner.message}
              </div>
            )}
          </div>

          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold">
              Surveyor
              {/* <span className="text-red-500">*</span> */}
            </label>
            <Controller
              name="Surveyor"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={surveyorOptions}
                  placeholder="Search and select..."
                  disabled={true}
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handleSurveyorInputChange}
                  onFocus={() => fetchSurveyorOptions()}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(Surveyor) => {
                    field.onChange(Surveyor)
                    handleSurveyorSelectionChange(Surveyor) // Call a function to handle API call
                  }}
                  styles={dropDownConfig(error)}
                />
              )}
            />
          </div>

          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold">
              Type of Claim<span className="text-red-500">*</span>
            </label>

            {/* <SwitchWithText
              textLeft="Desktop"
              textRight="Survey"
              checked={isTypeOfClaim}
              onChange={changeTypeOfClaim}
              afterWidth={'after:w-[75px]'}
            /> */}
            <div className="mt-4">
              {/* <label className="w-1/3 text-sm font-bold">
                    Category <span className="text-red-500">*</span>
                  </label> */}
              <div className="mt-4 flex">
                <div className="flex w-full items-center">
                  <Radio
                    name="typeOfClaim" // Same name for grouping radio buttons
                    value="Survey"
                    id="survey"
                    checked={selectedTypeOfClaim === 'Survey'}
                    {...register('typeofclaim', {
                      required: !selectedTypeOfClaim
                        ? 'Please select a Type Of Claim'
                        : false,
                    })}
                    onChange={() => {
                      setSelectedTypeOfClaim('Survey')
                      handleTypeOfClaimChange('Survey')
                    }}
                  />
                  <label htmlFor="survey" className="ml-2">
                    Survey
                  </label>
                </div>

                <div className="flex w-full items-center">
                  <Radio
                    name="typeOfClaim" // Same name for grouping radio buttons
                    value="Desktop"
                    id="desktop"
                    checked={selectedTypeOfClaim === 'Desktop'}
                    {...register('typeofclaim', {
                      required: !selectedTypeOfClaim
                        ? 'Please select a Type Of Claim'
                        : false,
                    })}
                    onChange={() => {
                      setSelectedTypeOfClaim('Desktop')
                      handleTypeOfClaimChange('Desktop')
                    }}
                  />
                  <label htmlFor="desktop" className="ml-2">
                    Desktop
                  </label>
                </div>

                <div className="flex w-full items-center">
                  <Radio
                    name="typeOfClaim" // Same name for grouping radio buttons
                    value="Investigation"
                    id="investigation"
                    checked={selectedTypeOfClaim === 'Investigation'}
                    {...register('typeofclaim', {
                      required: !selectedTypeOfClaim
                        ? 'Please select a Type Of Claim'
                        : false,
                    })}
                    onChange={() => {
                      setSelectedTypeOfClaim('Investigation')
                      handleTypeOfClaimChange('Investigation')
                    }}
                  />
                  <label htmlFor="investigation" className="ml-2">
                    Investigation
                  </label>
                </div>
              </div>

              {/* Display error message if validation fails */}
              {errors.typeofclaim && !selectedTypeOfClaim && (
                <div className="mt-2 text-sm text-red-500">
                  {errors.typeofclaim.message}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-2 grid grid-cols-2 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold">
              Is Insured<span className="text-red-500">*</span>
            </label>

            <SwitchWithText
              textLeft="NO"
              textRight="YES"
              checked={isInsured}
              onChange={changeIsInsured}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold">
              Is Inspection<span className="text-red-500">*</span>
            </label>

            <SwitchWithText
              textLeft="NO"
              textRight="YES"
              checked={postLoss}
              onChange={changePostLoss}
            />
          </div>
        </div>

        <div className="mt-7 ">
          <h4 className="text-md mb-2 font-bold text-gray-700">Appointed By</h4>
          <div className="grid grid-cols-2 gap-3">
            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold">
                Segment <span className="text-red-500">*</span>
              </label>
              <Controller
                name="CustomerSegment"
                control={control}
                rules={{ required: 'CustomerSegment is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    options={customerSegmentOptions}
                    placeholder="Search and select..."
                    disabled={true}
                    classNamePrefix="select"
                    isClearable
                    isLoading={isLoading}
                    onInputChange={handleCustomerSegmentInputChange}
                    onFocus={() => fetchCustomerSegmentOptions()}
                    noOptionsMessage={() => 'Type to search...'}
                    onChange={(CustomerSegment) => {
                      field.onChange(CustomerSegment)
                      handleCustomerSegmentSelectionChange(CustomerSegment) // Call a function to handle API call
                      if (!CustomerSegment) {
                        setValue('CustomerOffice', null)
                      }
                    }}
                    styles={dropDownConfig(error)}
                  />
                )}
              />
              {errors.CustomerSegment && (
                <div className="error-message text-right text-red-500">
                  {errors.CustomerSegment.message}
                </div>
              )}
            </div>

            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold">
                Customer Office <span className="text-red-500">*</span>
              </label>
              <Controller
                name="CustomerOffice"
                control={control}
                rules={{ required: 'CustomerOffice is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    options={customerOfficeOptions}
                    placeholder="Search and select..."
                    disabled={true}
                    classNamePrefix="select"
                    isClearable
                    isLoading={isLoading}
                    onInputChange={handleCustomerOfficeInputChange}
                    onFocus={() => fetchCustomerOfficeOptions()}
                    noOptionsMessage={() => 'Type to search...'}
                    onChange={(CustomerOffice) => {
                      field.onChange(CustomerOffice)
                      handleCustomerOfficeSelectionChange(CustomerOffice) // Call a function to handle API call
                    }}
                    styles={dropDownConfig(error)}
                  />
                )}
              />
              {errors.CustomerOffice && (
                <div className="error-message text-right text-red-500">
                  {errors.CustomerOffice.message}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <label
              className="mb-2 mt-4 block text-sm font-bold"
              htmlFor="file-upload-logo"
            >
              Appointment {comesFromEmail && 'Mail'}{' '}
              <span className="text-red-500">*</span>
            </label>
            {!comesFromEmail && (
              <div className="flex items-center space-x-4">
                <div className="mb-4 mt-4">
                  <div className="flex space-x-4">
                    <img
                      src={previewFile || noImg}
                      alt="Logo Preview"
                      className="rounded-lg border border-gray-300"
                      style={{ maxWidth: '150px', maxHeight: '150px' }}
                    />
                  </div>
                </div>
                <input
                  type="file"
                  id="file-upload-logo"
                  ref={fileLogoRef} // Attach ref to the input
                  className="block w-full cursor-pointer text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
                  accept="image/*" // Only accept image files
                  onChange={handleAppointmentFileChange}
                />
              </div>
            )}
            {comesFromEmail && (
              <div className="rounded-lg bg-white p-6 shadow">
                {isLoading ? (
                  <p>Loading PDF...</p>
                ) : pdfUrl ? (
                  <iframe
                    src={pdfUrl}
                    width="100%"
                    title="PDF Viewer - Appointment Mail"
                    height="600px"
                  />
                ) : (
                  <img
                    src={noDataAvailable}
                    alt="Preview"
                    width="100px"
                    height="200px"
                  />
                )}
              </div>
            )}
          </div>
          <div className="col-span-2 lg:col-span-1">
            {isInsured && (
              <>
                <InputField
                  extra="mt-2 w-full"
                  label="Intimated Loss"
                  id="intimatedLoss"
                  type="text"
                  placeholder="Enter Intimated Loss"
                  registrationProps={register('intimatedLoss', {
                    required: 'Intimated Loss is required',
                  })}
                  isFieldRequired={true}
                  state={errors.intimatedLoss && 'error'}
                />
                <div className="error-message text-right">
                  {errors.intimatedLoss?.message}
                </div>
              </>
            )}
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Date Of Appointment"
              id="dateOfAppointment"
              type="date"
              placeholder="Enter Date Of Appointment"
              registrationProps={register('dateOfAppointment', {
                required: 'Date Of Appointment is required',
              })}
              isFieldRequired={true}
              state={errors.dateOfAppointment && 'error'}
            />
            <div className="error-message text-right">
              {errors.dateOfAppointment?.message}
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Date Of Intimation"
              id="dateOfIntimation"
              type="date"
              placeholder="Enter Date Of Intimation"
              registrationProps={register('dateOfIntimation', {
                required: 'Date Of Intimation is required',
              })}
              isFieldRequired={true}
              state={errors.dateOfIntimation && 'error'}
            />
            <div className="error-message text-right">
              {errors.dateOfIntimation?.message}
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 mt-1 block text-sm font-bold">
              Insured's Office <span className="text-red-500">*</span>
            </label>
            <Controller
              name="InsuredOffice"
              control={control}
              rules={{ required: 'Insured office is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={insuredOfficeOptions}
                  placeholder="Search and select..."
                  disabled={true}
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handleInsuredOfficeInputChange}
                  onFocus={() => fetchInsuredOfficeOptions()}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(InsuredOffice) => {
                    field.onChange(InsuredOffice)
                    handleInsuredOfficeSelectionChange(InsuredOffice) // Call a function to handle API call
                  }}
                  styles={dropDownConfig(error)}
                />
              )}
            />
            {errors.InsuredOffice && (
              <div className="error-message text-right text-red-500">
                {errors.InsuredOffice.message}
              </div>
            )}
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Insured Claim-No"
              id="insuredClaimNo"
              type="text"
              placeholder="Enter Insured Claim-No"
              registrationProps={register('insuredClaimNo', {
                required: 'Insured Claim-No is required',
              })}
              isFieldRequired={true}
              state={errors.insuredClaimNo && 'error'}
            />
            <div className="error-message text-right">
              {errors.insuredClaimNo?.message}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 mt-4 block text-sm font-bold">
              Cause Of Loss <span className="text-red-500">*</span>
            </label>
            <Controller
              name="CauseOfLoss"
              control={control}
              rules={{ required: 'Cause Of Loss is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={causeOfLossOptions}
                  placeholder="Search and select..."
                  disabled={true}
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handleCauseOfLossInputChange}
                  onFocus={() => fetchCauseOfLossOptions()}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(CauseOfLoss) => {
                    field.onChange(CauseOfLoss)
                    handleCauseOfLossSelectionChange(CauseOfLoss) // Call a function to handle API call
                  }}
                  styles={dropDownConfig(error)}
                />
              )}
            />
            {errors.CauseOfLoss && (
              <div className="error-message text-right text-red-500">
                {errors.CauseOfLoss.message}
              </div>
            )}
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 mt-4 block text-sm font-bold">
              Nature Of Loss <span className="text-red-500">*</span>
            </label>
            <Controller
              name="NatureOfLoss"
              control={control}
              rules={{ required: 'Nature Of Loss is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={natureOfLossOptions}
                  placeholder="Search and select..."
                  disabled={true}
                  classNamePrefix="select"
                  isClearable
                  isMulti
                  isLoading={isLoading}
                  onInputChange={handleNatureOfLossInputChange}
                  onFocus={() => fetchNatureOfLossOptions()}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(NatureOfLoss) => {
                    field.onChange(NatureOfLoss)
                    handleNatureOfLossSelectionChange(NatureOfLoss) // Call a function to handle API call
                  }}
                  styles={dropDownConfig(error)}
                />
              )}
            />
            {errors.NatureOfLoss && (
              <div className="error-message text-right text-red-500">
                {errors.NatureOfLoss.message}
              </div>
            )}
          </div>

          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 mt-2 block text-sm font-bold">
              Insurer Office <span className="text-red-500">*</span>
            </label>
            <Controller
              name="InsurerOffice"
              control={control}
              rules={{ required: 'Insurer office is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={insurerOfficeOptions}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handleInsurerOfficeInputChange}
                  onFocus={() => fetchInsurerOfficeOptions()}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(InsurerOffice) => {
                    field.onChange(InsurerOffice)
                    handleInsurerOfficeSelectionChange(InsurerOffice) // Call a function to handle API call
                  }}
                  styles={dropDownConfig(error)}
                />
              )}
            />
            {errors.InsurerOffice && (
              <div className="error-message text-right text-red-500">
                {errors.InsurerOffice.message}
              </div>
            )}
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Insurer Claim-No"
              id="insurerClaimNo"
              type="text"
              placeholder="Enter Insurer Claim-No"
              registrationProps={register('insurerClaimNo', {
                required: 'Insurer Claim-No is required',
              })}
              isFieldRequired={true}
              state={errors.insurerClaimNo && 'error'}
            />
            <div className="error-message text-right">
              {errors.insurerClaimNo?.message}
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 mt-1 block text-sm font-bold">
              Broker Office <span className="text-red-500">*</span>
            </label>
            <Controller
              name="BrokerOffice"
              control={control}
              rules={{ required: 'Broker office is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={brokerOfficeOptions}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handleBrokerOfficeInputChange}
                  onFocus={() => fetchBrokerOfficeOptions()}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(BrokerOffice) => {
                    field.onChange(BrokerOffice)
                    handleBrokerOfficeSelectionChange(BrokerOffice) // Call a function to handle API call
                  }}
                  styles={dropDownConfig(error)}
                />
              )}
            />
            {errors.BrokerOffice && (
              <div className="error-message text-right text-red-500">
                {errors.BrokerOffice.message}
              </div>
            )}
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Broker Claim-No"
              id="brokerClaimNo"
              type="text"
              placeholder="Enter Broker Claim-No"
              registrationProps={register('brokerClaimNo', {
                required: 'Broker Claim-No is required',
              })}
              isFieldRequired={true}
              state={errors.brokerClaimNo && 'error'}
            />
            <div className="error-message text-right">
              {errors.brokerClaimNo?.message}
            </div>
          </div>

          {/* <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold">
              Department<span className="text-red-500">*</span>
            </label>
            <Controller
              name="policyDepartment"
              control={control}
              rules={{ required: 'Department is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={natureOfSurveyOptions}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handleNatureOfSurveyInputChange}
                  onFocus={() => fetchNatureOfSurveyOptions()}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(policyDepartment) => {
                    field.onChange(policyDepartment)
                    handleNatureOfSurveySelectionChange(policyDepartment) // Call a function to handle API call
                    if (!policyDepartment) {
                      setValue('PolicyType', null)
                    }
                  }}
                  styles={dropDownConfig(error)}
                />
              )}
            />
            {errors.policyDepartment && (
              <div className="error-message text-right text-red-500">
                {errors.policyDepartment.message}
              </div>
            )}
          </div> */}
          {/* Policy Type */}
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold">
              Policy Type <span className="text-red-500">*</span>
            </label>
            <Controller
              name="PolicyType"
              control={control}
              rules={{ required: 'Policy Type is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={policyTypeOptions}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handlePolicyTypeInputChange}
                  noOptionsMessage={() => 'Type to search...'}
                  onFocus={() => fetchPolicyTypeOptions()}
                  onChange={(PolicyType) => {
                    field.onChange(PolicyType)
                    handlePolicyTypeSelectionChange(PolicyType) // Call a function to handle API call
                    if (!PolicyType) {
                      setValue('PolicyDocument', null)
                    }
                  }}
                  styles={dropDownConfig(error)}
                />
              )}
            />
            {errors.PolicyType && (
              <div className="error-message text-right text-red-500">
                {errors.PolicyType.message}
              </div>
            )}
          </div>

          {/* Policy Document */}
          {isPolicyTypeSelected && (
            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold">
                Policy Document <span className="text-red-500">*</span>
              </label>
              <Controller
                name="PolicyDocument"
                control={control}
                rules={{ required: 'Policy Document is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    options={policyDocOptions}
                    placeholder="Search and select..."
                    classNamePrefix="select"
                    isClearable
                    isLoading={isLoading}
                    onInputChange={handlePolicyDocInputChange}
                    noOptionsMessage={() => 'Type to search...'}
                    onFocus={() => fetchPolicyDocOptions()}
                    onChange={(PolicyDocument) => {
                      field.onChange(PolicyDocument)
                      handlePolicyDocSelectionChange(PolicyDocument) // Call a function to handle API call
                    }}
                    styles={dropDownConfig(error)}
                  />
                )}
              />
              {errors.PolicyDocument && (
                <div className="error-message text-right text-red-500">
                  {errors.PolicyDocument.message}
                </div>
              )}
            </div>
          )}

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Date Of Loss"
              id="dateOfLoss"
              type="date"
              placeholder="Enter date of loss"
              registrationProps={register('dateOfLoss', {
                required: 'Date of loss is required',
              })}
              isFieldRequired={true}
              state={errors.dateOfLoss && 'error'}
            />
            <div className="error-message text-right">
              {errors.dateOfLoss?.message}
            </div>
          </div>
        </div>

        {selectedTypeOfClaim === 'Desktop' && (
          <div className="mt-7 grid grid-cols-1 gap-3">
            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold">
                Site Details<span className="text-red-500">*</span>
              </label>

              <SwitchWithText
                textLeft="NO"
                textRight="YES"
                checked={isSiteDetails}
                onChange={changeIsSiteDetails}
              />
            </div>
          </div>
        )}
        {((selectedTypeOfClaim === 'Desktop' && isSiteDetails) ||
          !selectedTypeOfClaim ||
          selectedTypeOfClaim !== 'Desktop') && (
          <div className="mt-7 grid grid-cols-2 gap-3">
            <AddressComponent
              control={control}
              register={register}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
            />

            <div className="col-span-2 lg:col-span-1">
              <h4 className="text-md mb-2 font-bold text-gray-700">
                Site Person Details
              </h4>
              <InputField
                extra="mb-2 w-full"
                label="Site Person"
                id="SitePerson"
                type="text"
                placeholder="Enter site person"
                registrationProps={register('sitePerson', {
                  required: 'Site person is required',
                })}
                isFieldRequired={true}
                state={errors.sitePerson && 'error'}
              />
              <div className="error-message text-right">
                {errors.sitePerson?.message}
              </div>

              <InputField
                extra="mb-2 w-full"
                label="Site Phone"
                id="SitePhone"
                type="text"
                placeholder="Enter site phone"
                registrationProps={register('sitePhone', {
                  required: 'Site phone is required',
                })}
                isFieldRequired={true}
                state={errors.sitePhone && 'error'}
              />
              <div className="error-message text-right">
                {errors.sitePhone?.message}
              </div>

              <InputField
                extra="mb-2 w-full"
                label="Site Email"
                id="SiteEmail"
                type="text"
                placeholder="Enter email id"
                registrationProps={register('SiteEmail')}
              />
            </div>
          </div>
        )}
        {/* Home and Office Address */}
        {id && (
          <div className="mt-7 grid grid-cols-2 gap-3">
            <AddressComponent
              control={control}
              register={register}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              addressType="Home"
            />

            <AddressComponent
              control={control}
              register={register}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              addressType="Office"
            />
          </div>
        )}

        {/* Button aligned to the right */}
        <div className="mt-4 flex w-full justify-end">
          <button
            className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
          hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base`}
            type="submit"
          >
            <span>{id ? 'Update' : 'Save'}</span>
          </button>

          <button
            className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
          hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base`}
            type="button"
            onClick={handleCancel}
          >
            <span>Cancel</span>
          </button>
        </div>
      </form>
    </Card>
  )
}

export default AddAssignment
