import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import Card from 'components/card'
import { useSpinner } from 'common/SpinnerLoader'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import { useToaster } from 'common/Toaster'
import SwitchWithText from 'components/switch-with-text'
import InputField from 'components/fields/InputField'
import CrawfordConstants from 'common/config/crawfordConstants'

const AddDepartment = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const apiService = ApiCaller()
  const { TOAST } = CrawfordConstants

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      departmentName: '',
      departmentNumber: '',
      survey: false,
      ila: false,
      lor: false,
      docs: false,
      assessment: false,
      consent: false,
      fsr: false,
      invoice: false,
    },
  })
  const [isDepartmentActive, setDepartmentStatus] = useState(false)

  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()
  const changeDepartmentStatus = () => {
    setDepartmentStatus(!isDepartmentActive)
  }
  const otherStatusOptions = [
    'survey',
    'ila',
    'lor',
    'docs',
    'assessment',
    'consent',
    'fsr',
    'invoice',
  ]
  const [statusStates, setStatusStates] = useState({
    survey: false,
    ila: false,
    lor: false,
    docs: false,
    assessment: false,
    consent: false,
    fsr: false,
    invoice: false,
  })

  const toggleStatus = (key) => {
    setStatusStates((prevStates) => {
      const newState = !prevStates[key]
      setValue(key, newState)
      return {
        ...prevStates,
        [key]: newState,
      }
    })
  }

  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.DEPARTMENT_CREATE
      const payload = {
        _id: id ? id : undefined,
        name: formData.departmentName,
        code: formData.departmentNumber,
        getAllTaskStatus: { ...statusStates },
        status: isDepartmentActive,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        addToast({
          title: `Department "${formData.departmentName}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        navigate(`/insurance/CauseLoss-list`)
      } else {
        addToast({ title: 'Department creation failed.', type: 'error' })
      }
    } catch (error) {
      addToast({ title: TOAST.MESSAGES.ERROR.swwError, type: 'error' })
    } finally {
      hideSpinner()
    }
  }

  const getDepartmentDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.DEPARTMENT_DETAIL.replace(':id', id)
      const response = await apiService.apiCall('post', apiUrl, { _id: id })
      if (response?.data?.value) {
        setValue('departmentName', response?.data?.data?.name || '')
        setValue('departmentNumber', response?.data?.data?.code || '')
        setDepartmentStatus(response?.data?.data?.status)
        setStatusStates({
          survey: response?.data?.data?.getAllTaskStatus?.survey === 'Not Done',
          ila: response?.data?.data?.getAllTaskStatus?.ila === 'Not Done',
          lor: response?.data?.data?.getAllTaskStatus?.lor === 'Not Done',
          docs: response?.data?.data?.getAllTaskStatus?.docs === 'Not Done',
          assessment:
            response?.data?.data?.getAllTaskStatus?.assessment === 'Not Done',
          consent:
            response?.data?.data?.getAllTaskStatus?.consent === 'Not Done',
          fsr: response?.data?.data?.getAllTaskStatus?.fsr === 'Not Done',
          invoice:
            response?.data?.data?.getAllTaskStatus?.invoice === 'Not Done',
        })
        otherStatusOptions.forEach((key) => {
          setValue(key, response?.data?.data.results?.[key] === 'Not Done')
        })
      } else {
        addToast({ title: 'Some Error Occurred', type: 'error' })
      }
    } catch (error) {
      console.error('Error:', error)
    } finally {
      hideSpinner()
    }
  }

  useEffect(() => {
    if (id) getDepartmentDetails()
  }, [])

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
      <div className="mt-[25px]">
        <p className="text-xl font-bold text-navy-700 dark:text-white">
          {id ? 'Edit' : 'Add'} Department
        </p>
      </div>
      <div className="col-span-2 lg:col-span-1">
        <div className="flex w-full justify-end">
          <SwitchWithText
            textLeft="OFF"
            textRight="ON"
            checked={isDepartmentActive}
            onChange={changeDepartmentStatus}
          />
        </div>
      </div>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <h4 className="text-md mb-1 mt-2 font-bold text-gray-700">
          Department
        </h4>
        <div className="mt-7 grid grid-cols-2 gap-3">
          <div className="col-span-2 grid lg:col-span-1">
            <InputField
              extra="w-full"
              label="Department Name"
              id="DepartmentName"
              type="text"
              placeholder="Enter department name"
              registrationProps={register('departmentName', {
                required: 'Department name is required',
              })}
              isFieldRequired={true}
              state={errors.departmentName && 'error'}
            />
            <div className="error-message text-right">
              {errors.departmentName?.message}
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Department Code"
              id="DepartmentNumber"
              type="text"
              placeholder="Enter department code"
              registrationProps={register('departmentNumber', {
                required: 'Department number is required',
              })}
              isFieldRequired={true}
              state={errors.departmentNumber && 'error'}
            />
            <div className="error-message text-right">
              {errors.departmentNumber?.message}
            </div>
          </div>

          {otherStatusOptions.map((key) => (
            <div key={key} className="col-span-2 lg:col-span-1">
              <div className="flex w-full items-center justify-between">
                <span>{key.charAt(0).toUpperCase() + key.slice(1)}</span>
                <SwitchWithText
                  textLeft="OFF"
                  textRight="ON"
                  checked={statusStates[key]}
                  onChange={() => toggleStatus(key)}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="mt-4 flex w-full justify-end">
          <button
            className={`mt-2 w-40 cursor-pointer rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 md:text-base`}
            type="submit"
          >
            <span>{id ? 'Update' : 'Save'}</span>
          </button>

          <button
            className={`ml-2 mt-2 w-40 cursor-pointer rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200 hover:bg-gray-600 active:bg-gray-700 md:text-base`}
            type="button"
            onClick={() => navigate(`/insurance/CauseLoss-list`)}
          >
            <span>Cancel</span>
          </button>
        </div>
      </form>
    </Card>
  )
}

export default AddDepartment
