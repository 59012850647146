export const investigatorColumnsDataOrders = [
  {
    Header: 'S.No',
    accessor: 'index',
    disableSortBy: true,
  },
  {
    Header: 'Investigator Name',
    accessor: 'name',
    disableSortBy: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
    disableSortBy: true,
  },
  {
    Header: 'Contact Number',
    accessor: 'phone1',
    disableSortBy: true,
  },
  {
    Header: 'Address',
    accessor: 'address',
    disableSortBy: true,
  },
  {
    Header: 'City',
    accessor: 'city.name',
    disableSortBy: true,
  },
  {
    Header: 'Pin Code',
    accessor: 'pincode',
    disableSortBy: true,
  },
  {
    Header: 'ACTIONS',
    accessor: 'EMPLOYEE ACTIONS',
    disableSortBy: true,
  },
]
