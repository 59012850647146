const baseUrl = process.env.REACT_APP_API_BASE_URL

const uploadUrl = process.env.REACT_APP_API_UPLOAD_URL
const readUrl = process.env.REACT_APP_API_READ_URL
const config = {
  READ_FILE_BASED_ON_ID: readUrl + 'api/upload/readFile?file=',
  NAVIGATION_COUNT_FOR_MENU: baseUrl + 'api/Employee/getNavigationCounts',

  // USER INFO
  GET_USER_INFO: baseUrl + 'api/user/profile',

  // LOCATION MODULE
  // Country
  COUNTRY_LIST: baseUrl + 'api/country/search',
  COUNTRY_CREATE: baseUrl + 'api/country/save',
  COUNTRY_DETAIL: baseUrl + 'api/country/getOne',

  // Zone
  ZONE_LIST: baseUrl + 'api/zone/search',
  ZONE_CREATE: baseUrl + 'api/zone/save',
  ZONE_DETAIL: baseUrl + 'api/zone/getOne',

  // Role
  ROLE_LIST: baseUrl + 'api/Role/search',

  // State
  STATE_LIST: baseUrl + 'api/state/search',
  STATE_CREATE: baseUrl + 'api/state/save',
  STATE_DETAIL: baseUrl + 'api/state/getOne',

  // District
  DISTRICT_LIST: baseUrl + 'api/district/search',
  DISTRICT_CREATE: baseUrl + 'api/district/save',
  DISTRICT_DETAIL: baseUrl + 'api/district/getOne',

  // City
  CITY_LIST: baseUrl + 'api/city/search',
  CITY_CREATE: baseUrl + 'api/city/save',
  CITY_DETAIL: baseUrl + 'api/city/getOne',

  // EMAIL
  EMAIL_LIST: baseUrl + 'api/User/listOutlookEmail',
  EMAIL_PDF: baseUrl + 'api/User/generateEmailPdf',
  EMAIL_ATTACHMENTS: baseUrl + 'api/User/getEmailAttachments',

  // COMPANY SETUP MODULE
  // Company
  COMPANY_LIST: baseUrl + 'api/company/search',
  COMPANY_CITY_LIST: baseUrl + 'api/city/populateCityDetails',
  CURRENCY_LIST: baseUrl + 'api/currencies/search',
  BANK_LIST: baseUrl + 'api/bank/search',
  COMPANY_GST_LIST: baseUrl + 'api/customerCompany/getGSTINDetails',
  COMPANY_DETAIL: baseUrl + 'api/company/getOne',
  COMPANY_CREATE: baseUrl + 'api/company/save',
  UPLOAD_IMAGE: uploadUrl + 'api/upload',

  // Type Of Office
  TYPE_OF_OFFICE_LIST: baseUrl + 'api/typeOfOffice/search',
  TYPE_OF_OFFICE_CREATE: baseUrl + 'api/typeOfOffice/save',
  TYPE_OF_OFFICE_DETAIL: baseUrl + 'api/typeOfOffice/getOne',

  // Office
  OFFICE_LIST: baseUrl + 'api/office/search',
  OFFICE_CREATE: baseUrl + 'api/office/save',
  OFFICE_DETAIL: baseUrl + 'api/office/getOne',

  // Branch
  BRANCH_LIST: baseUrl + 'api/branch/search',
  BRANCH_CREATE: baseUrl + 'api/branch/save',
  BRANCH_DETAIL: baseUrl + 'api/branch/getOneBranch',

  // CUSTOMERS
  // Segment
  SEGMENT_LIST: baseUrl + 'api/CustomerSegment/search',
  SEGMENT_DETAIL: baseUrl + 'api/CustomerSegment/getOne',
  SEGMENT_CREATE: baseUrl + 'api/CustomerSegment/save',

  // Company
  CUSTOMER_COMPANY_LIST: baseUrl + 'api/CustomerCompany/search',
  CUSTOMER_COMPANY_CREATE: baseUrl + 'api/CustomerCompany/save',
  CUSTOMER_COMPANY_DETAIL: baseUrl + 'api/CustomerCompany/getOne',

  // Customer
  CUSTOMER_LIST: baseUrl + 'api/customer/search',
  CUSTOMER_CREATE: baseUrl + 'api/Customer/save1',
  CUSTOMER_DETAIL: baseUrl + 'api/Customer/getOne',

  // Officer
  OFFICER_LIST: baseUrl + 'api/officer/search',
  OFFICER_CREATE: baseUrl + 'api/officer/save',
  OFFICER_DETAIL: baseUrl + 'api/officer/findOfficer',

  // EMPLOYEE
  // Employee
  EMPLOYEE_LIST: baseUrl + 'api/Employee/search',
  EMPLOYEE_CREATE: baseUrl + 'api/Employee/save',
  EMPLOYEE_DETAIL: baseUrl + 'api/Employee/getOneEmployee',

  // Function
  FUNCTION_LIST: baseUrl + 'api/Func/search',
  FUNCTION_DETAIL: baseUrl + 'api/Func/getOne',
  FUNCTION_CREATE: baseUrl + 'api/Func/save',

  // Grade
  GRADE_LIST: baseUrl + 'api/Grade/search',
  GRADE_DETAIL: baseUrl + 'api/Grade/getOne',
  GRADE_CREATE: baseUrl + 'api/Grade/save',

  // Investigator
  INVESTIGATOR_LIST: baseUrl + 'api/Investigator/search',
  NEAREST_INVESTIGATOR_LIST: baseUrl + 'api/Office/getNearestInvestigator',
  INVESTIGATOR_DETAIL: baseUrl + 'api/Investigator/getOne',
  INVESTIGATOR_CREATE: baseUrl + 'api/Investigator/save',

  GET_ACTIVE_BRANCH: baseUrl + 'api/branch/getActiveBranch',
  GET_DEPARTMENT: baseUrl + 'api/department/search',
  GET_BACKEND_EMPLOYEE: baseUrl + 'api/Employee/getBackendEmployeeOnly',
  GET_CAUSE_LOSS: baseUrl + 'api/CauseLoss/search2',
  GET_NATURE_LOSS: baseUrl + 'api/CauseLoss/getNatureLoss',

  //Insurance
  //department
  DEPARTMENT_DETAIL: baseUrl + 'api/Department/getOne',
  DEPARTMENT_LIST: baseUrl + 'api/Department/search',
  DEPARTMENT_CREATE: baseUrl + 'api/Department/save',

  //Policy Type
  POLICY_TYPE_DETAIL: baseUrl + 'api/PolicyType/getOne',
  POLICY_TYPE_LIST: baseUrl + 'api/PolicyType/search',
  POLICY_TYPE_CREATE: baseUrl + 'api/PolicyType/save',
  GET_COMPANY_BASED_ON_POLICY_TYPE: baseUrl + 'api/PolicyType/getCompany',

  //Policy Document
  POLICY_DOCUMENT_DETAIL: baseUrl + 'api/policyDoc/getOne',
  POLICY_DOCUMENT_LIST: baseUrl + 'api/policyDoc/getAll',
  POLICY_DOCUMENT_CREATE: baseUrl + 'api/policyDoc/save',
  POLICY_DOCUMENT_STATUS: baseUrl + 'api/policyDoc/changeStatus',

  //nature of loss
  NOL_DETAIL: baseUrl + 'api/NatureLoss/getOne',
  NOL_LIST: baseUrl + 'api/NatureLoss/search',
  NOL_CREATE: baseUrl + 'api/NatureLoss/save',

  //cause of loss
  COL_DETAIL: baseUrl + 'api/CauseLoss/getOne',
  COL_LIST: baseUrl + 'api/CauseLoss/search',
  COL_CREATE: baseUrl + 'api/CauseLoss/save',

  // Assignment
  //Assignment
  ASSIGNMENT_SAVE: baseUrl + 'api/Assignment/AssignmentSave',
  ASSIGNMENT_LIST: baseUrl + 'api/Assignment/getAll',
  ASSIGNMENT_DETAIL: baseUrl + 'api/Assignment/getOneAssignment',

  //TypeOfClaims
  TOC_DETAIL: baseUrl + 'api/Claims/getOne',
  TOC_LIST: baseUrl + 'api/Claims/search',
  TOC_CREATE: baseUrl + 'api/Claims/save',
}
export default config
