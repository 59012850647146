import React from 'react'
import SegmentList from 'views/Customers/Segment'
import SegmentAdd from 'views/Customers/Segment/components/AddSegment'
import CompanyList from 'views/Customers/Company'
import CustomerCompanyAdd from 'views/Customers/Company/components/AddCustomerCompany'
import CustomerList from 'views/Customers/Customer'
import CustomerAdd from 'views/Customers/Customer/components/AddCustomer'
import OfficerList from 'views/Customers/Officer'
import AddOfficer from 'views/Customers/Officer/components/AddOfficer'
import TypeOfOfficeList from 'views/Customers/TypeOfOffice'
import OfficeTypeAdd from 'views/Customers/TypeOfOffice/components/AddTypeOfOffice'
import { FaBuilding, FaUser } from 'react-icons/fa'
import { FaLocationArrow, FaLocationDot } from 'react-icons/fa6'

const customersRoutes = [
  {
    name: 'Type Of Office',
    layout: '/afterLogin',
    path: '/customers/typeOfOffice-list',
    icon: <FaBuilding className="text-inherit h-6 w-6" />,
    component: <TypeOfOfficeList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Type Of Office', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Type Of Office',
    layout: '/afterLogin',
    path: '/customers/typeOfOffice-create',
    icon: <FaLocationArrow className="text-inherit h-6 w-6" />,
    component: <OfficeTypeAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Type Of Office', link: '/customers/typeOfOffice-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Type Of Office',
    layout: '/afterLogin',
    path: '/customers/typeOfOffice-edit/:id',
    icon: <FaLocationArrow className="text-inherit h-6 w-6" />,
    component: <OfficeTypeAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Type Of Office', link: '/customers/typeOfOffice-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Segment',
    layout: '/afterLogin',
    path: '/customers/segment-list',
    icon: <FaUser className="text-inherit h-6 w-4" />,
    component: <SegmentList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Segment', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Segment',
    layout: '/afterLogin',
    path: '/customers/segment-create',
    icon: <FaUser className="text-inherit h-6 w-6" />,
    component: <SegmentAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Segment', link: '/customers/segment-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Segment',
    layout: '/afterLogin',
    path: '/customers/segment-edit/:id',
    icon: <FaUser className="text-inherit h-6 w-6" />,
    component: <SegmentAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Segment', link: '/customers/segment-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Customer Company',
    layout: '/afterLogin',
    path: '/customers/company-list',
    icon: <FaBuilding className="text-inherit h-6 w-4" />,
    component: <CompanyList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Company', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Customer Company',
    layout: '/afterLogin',
    path: '/customers/company-create',
    icon: <FaUser className="text-inherit h-6 w-6" />,
    component: <CustomerCompanyAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Customer Company', link: '/customers/company-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Customer Company',
    layout: '/afterLogin',
    path: '/customers/company-edit/:id',
    icon: <FaUser className="text-inherit h-6 w-6" />,
    component: <CustomerCompanyAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Customer Company', link: '/customers/company-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Customer',
    layout: '/afterLogin',
    icon: <FaUser className="text-inherit h-6 w-4" />,
    path: '/customers/customer-list',
    component: <CustomerList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Customer', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Customer',
    layout: '/afterLogin',
    path: '/customers/customer-create',
    icon: <FaUser className="text-inherit h-6 w-6" />,
    component: <CustomerAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Customer', link: '/customers/customer-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Customer',
    layout: '/afterLogin',
    path: '/customers/customer-edit/:id',
    icon: <FaUser className="text-inherit h-6 w-6" />,
    component: <CustomerAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Customer', link: '/customers/customer-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Officer',
    layout: '/afterLogin',
    icon: <FaUser className="text-inherit h-6 w-4" />,
    path: '/customers/officer-list',
    component: <OfficerList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Officer', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Officer',
    layout: '/afterLogin',
    path: '/customers/officer-create',
    icon: <FaUser className="text-inherit h-6 w-6" />,
    component: <AddOfficer />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Officer', link: '', breadCrumDisabled: true },
    ],
  },

  {
    name: 'Officer',
    layout: '/afterLogin',
    path: '/customers/officer-edit/:id',
    icon: <FaUser className="text-inherit h-6 w-6" />,
    component: <AddOfficer />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Locations',
    breadcrum: [
      { label: 'Officer', link: '/customers/officer-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
]

export default customersRoutes
