import { customerColumnsDataOrders } from './variables/ColumnDataOrders'
import CustomerListTable from './components/List'
import Card from 'components/card'
import { useState, useEffect } from 'react'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
const apiService = ApiCaller()
/**
 * Renders the Customer List component with the fetched customer data.
 *
 * @return {JSX.Element} The rendered Customer List component
 */
const CustomerList = () => {
  const [allAppData, setCustomerData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const itemsPerPage = 10 // You can change this to your desired number
  const [isCustomerLoading, setCustomerLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setCustomerLoading(true)
        const apiUrl = apiConfig.CUSTOMER_LIST
        const payload = {
          page: currentPage,
          keyword: searchValue,
          filter: {},
        }
        const response = await apiService.apiCall('post', apiUrl, payload)
        if (response?.data?.value) {
          setCustomerData(response?.data?.data?.results)
          setTotalCount(response?.data?.data?.total || 0)
        } else {
          setCustomerData([])
          setTotalCount(0)
        }
      } catch (error) {
        console.error('Error during API call:', error)
      } finally {
        setCustomerLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchValue]) // Fetch data whenever currentPage changes

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const setGlobalFilter = (value) => {
    try {
      setCurrentPage(1)
      setSearchValue(value)
    } catch (err) {
      console.log('err', err)
    } finally {
      setCustomerLoading(false)
    }
  }

  return (
    <>
      <Card extra={'w-full h-full bg-white mt-3'}>
        <CustomerListTable
          tableData={allAppData}
          columnsData={customerColumnsDataOrders}
          totalCount={totalCount}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          isCustomerLoading={isCustomerLoading}
          setCustomerLoading={setCustomerLoading}
          setGlobalFilter={setGlobalFilter}
        />
      </Card>
    </>
  )
}

export default CustomerList
