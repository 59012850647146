export const policyTypeColumnsDataOrders = [
  {
    Header: 'S.No',
    accessor: 'index',
    disableSortBy: true,
  },
  {
    Header: 'Policy Type',
    accessor: 'name',
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: true,
  },

  {
    Header: 'ACTIONS',
    accessor: 'COMPANY ACTIONS',
    disableSortBy: true,
  },
]
