import React, { useState, useRef, useEffect } from 'react'
import Investigator from 'views/Investigator'
import DocumentsComponent from 'views/Documents'
const EditAssignment = () => {
  const steps = [
    { label: 'Unassigned', value: 'unassigned' },
    { label: 'Investigation Pending', value: 'investigation_pending' },
    { label: 'Documents', value: 'documents' },
    { label: 'Invoice Pending', value: 'invoice_pending' },
    { label: 'Dispatch Pending', value: 'dispatch_pending' },
    { label: 'Dispatched', value: 'dispatched' },
    { label: 'Fee Pending', value: 'fee_pending' },
    { label: 'Fee Received', value: 'fee_received' },
    { label: 'Closed Loss', value: 'closed_loss' },
    { label: 'Dispatch Pending', value: 'dispatch_pending_2' }, // Unique value keys for duplicates
    { label: 'Dispatched', value: 'dispatched_2' },
    { label: 'Fee Pending', value: 'fee_pending_2' },
    { label: 'Fee Received', value: 'fee_received_2' },
    { label: 'Closed Loss', value: 'closed_loss_2' },
  ]

  const [currentStep, setCurrentStep] = useState(0)
  const [currentStepName, setCurrentStepName] = useState(steps[0].value)
  const [showLeftButton, setShowLeftButton] = useState(false)
  const [showRightButton, setShowRightButton] = useState(false)

  const stepperRef = useRef(null)

  // const goToPreviousStep = () => setCurrentStep((prev) => Math.max(prev - 1, 0))
  // const goToNextStep = () =>
  //   setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1))

  const scrollStepper = (direction) => {
    if (stepperRef.current) {
      stepperRef.current.scrollBy({
        left: direction === 'left' ? -150 : 150,
        behavior: 'smooth',
      })
    }
  }

  const checkScrollButtons = () => {
    if (stepperRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = stepperRef.current
      setShowLeftButton(scrollLeft > 0)
      setShowRightButton(scrollLeft + clientWidth < scrollWidth)
    }
  }

  useEffect(() => {
    const stepperEl = stepperRef.current
    checkScrollButtons()
    if (stepperEl) {
      stepperEl.addEventListener('scroll', checkScrollButtons)
      window.addEventListener('resize', checkScrollButtons)
    }
    return () => {
      if (stepperEl) {
        stepperEl.removeEventListener('scroll', checkScrollButtons)
        window.removeEventListener('resize', checkScrollButtons)
      }
    }
  }, [])

  return (
    <div className="EditAssignment">
      {/* Stepper with Scroll Buttons on Hover */}
      <div className="group relative flex items-center justify-center">
        {/* Left Scroll Button (visible on hover) */}
        {showLeftButton && (
          <button
            onClick={() => scrollStepper('left')}
            className="absolute left-0 z-[9999] h-full items-center justify-center rounded-md bg-blue-500 px-2 py-2 text-white shadow-md group-hover:flex"
            aria-label="Scroll Left"
          >
            &#9664;
          </button>
        )}

        <div
          ref={stepperRef}
          className="scrollbar-hide mx-12 flex w-full overflow-x-auto scroll-smooth whitespace-nowrap"
          style={{
            msOverflowStyle: 'none', // For IE and Edge
            scrollbarWidth: 'none', // For Firefox
          }}
        >
          <style>
            {`
              .scrollbar-hide::-webkit-scrollbar {
                display: none;
              }
            `}
          </style>
          {steps.map(({ label, value }, index) => (
            <li
              key={index}
              className={`relative inline-flex min-w-[150px] cursor-pointer items-center justify-center ${
                index < steps.length - 1
                  ? "after:absolute after:left-16 after:top-3 after:inline-block after:h-0.5 after:w-full after:bg-blue-500 after:content-[''] lg:after:top-6"
                  : ''
              } ${index < currentStep ? 'after:h-1' : ''}`}
              onClick={() => {
                setCurrentStep(index)
                setCurrentStepName(value)
              }}
            >
              <div className="z-10 block">
                <span
                  className={`mx-auto mb-3 flex h-6 w-6 items-center justify-center rounded-full text-sm lg:h-12 lg:w-12 ${
                    index < currentStep
                      ? 'bg-blue-500 text-white'
                      : index === currentStep
                        ? 'border-2 border-dashed border-blue-500'
                        : 'border-2 border-blue-500 bg-white'
                  }`}
                >
                  {index === currentStep && (
                    <span className="h-10 w-10 rounded-full bg-blue-500"></span>
                  )}
                </span>
                <div className="h-[30px] max-w-[150px] px-2 text-center">
                  {label}
                </div>
              </div>
            </li>
          ))}
        </div>

        {/* Right Scroll Button (visible on hover) */}
        {showRightButton && (
          <button
            onClick={() => scrollStepper('right')}
            className="absolute right-0 z-[9999] h-full items-center justify-center rounded-md bg-blue-500 px-2 py-2 text-white shadow-md group-hover:flex"
            aria-label="Scroll Right"
          >
            &#9654;
          </button>
        )}
      </div>

      {/* Navigation Buttons */}
      {/* <div className="mt-8 flex justify-center space-x-4">
        <button
          onClick={goToPreviousStep}
          className={`rounded px-4 py-2 text-white ${
            currentStep === 0 ? 'cursor-not-allowed bg-gray-400' : 'bg-blue-500'
          }`}
          disabled={currentStep === 0}
        >
          Previous
        </button>
        <button
          onClick={goToNextStep}
          className={`rounded px-4 py-2 text-white ${
            currentStep === steps.length - 1
              ? 'cursor-not-allowed bg-gray-400'
              : 'bg-blue-500'
          }`}
          disabled={currentStep === steps.length - 1}
        >
          Next
        </button>
      </div> */}
      {currentStepName === 'investigation_pending' && <Investigator />}
      {currentStepName === 'documents' && <DocumentsComponent />}
    </div>
  )
}

export default EditAssignment
