export const nolColumnsDataOrders = [
  {
    Header: 'S.No',
    accessor: 'index',
    disableSortBy: true,
  },
  {
    Header: 'Nature Loss',
    accessor: 'name',
    disableSortBy: true,
  },
  {
    Header: 'Code',
    accessor: 'code',
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: true,
  },

  {
    Header: 'ACTIONS',
    accessor: 'COMPANY ACTIONS',
    disableSortBy: true,
  },
]
