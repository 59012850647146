export const companyColumnsDataOrders = [
  {
    Header: 'S.No',
    accessor: 'index',
    disableSortBy: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
    disableSortBy: true,
  },
  {
    Header: 'Segment',
    accessor: 'customerSegment.name',
    disableSortBy: true,
  },
  {
    Header: 'Short Name',
    accessor: 'shortName',
    disableSortBy: true,
  },
  {
    Header: 'ACTIONS',
    accessor: 'COMPANY ACTIONS',
    disableSortBy: true,
  },
]
